import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { Copy, EllipsisVertical, Pencil, Trash2 } from 'lucide-react';

import { type TypeDataset } from '../../common/dataTypes';
import { toastPromise } from '../../common/utils';
import { useGetDatasetById, useGetDatasets } from '../../hooks';
import { useDuplicateDataset } from '../../hooks/useDatasetsHook';
import { isOrgAdmin, useGetRouteParams } from '../../hooks/useUtilsHook';
import { useCurrentOrganization } from '../../utils/helpers';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import MenuDropdown from '../MenuDropdown';
import TanstackTable from '../TanstackTable';
import WarningDialog from '../WarningDialog';

import CreateDatasetDialog from './CreateDatasetDialog';

export type DatasetRow = {
  id: number;
  name: string;
  description: string | undefined;
  modified: string | undefined;
  createdBy: string;
  updatedBy: string;
};

const defaultData: DatasetRow[] = [];

const columnHelper = createColumnHelper<DatasetRow>();

const getCopyDatasetName = ({
  name,
  datasets,
  dataset,
}: {
  datasets: TypeDataset[];
  dataset: TypeDataset;
  name: string;
}) => {
  const getCopyVersion = (str: string) => {
    const match = str.match(/\((\d+)\)$/);
    return match ? match[1] : null;
  };

  const updateCopyVersion = (str: string, newNumber: number) =>
    str.replace(/\((\d+)\)$/, `(${newNumber})`);

  const maxCopyVersion = datasets
    ? Math.max(...datasets.map((d) => Number(getCopyVersion(d.name))))
    : 0;

  const currentVersion = Number(getCopyVersion(name));

  const latestVersionDataset =
    datasets?.find((d) =>
      maxCopyVersion === 0
        ? d.name === name.concat(`(${maxCopyVersion})`)
        : d.name === name
    ) ?? dataset;

  return maxCopyVersion !== 0 && currentVersion !== 0
    ? updateCopyVersion(latestVersionDataset?.name ?? '', maxCopyVersion + 1)
    : name.concat(`(${maxCopyVersion + 1})`);
};

const columns = ({
  handleSelect,
  handleSetIsLoading,
}: {
  handleSelect: (id: number) => void;
  handleSetIsLoading: (isLoading: boolean) => void;
}) => [
  columnHelper.accessor('name', {
    cell: (info) => (
      <div className="group flex items-center gap-2 py-3 text-start">
        <p
          onClick={() => handleSelect(info.row.original.id)}
          className="w-full"
        >
          {info.getValue()}
        </p>
      </div>
    ),
    header: () => (
      <div className="flex items-center gap-2">
        <p>Name</p>
      </div>
    ),
  }),
  columnHelper.accessor('description', {
    id: 'description',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div
          onClick={() => handleSelect(id)}
          className="line-clamp-5 max-w-[250px] 2xl:max-w-[350px]"
        >
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="text-gray-400 max-w-[250px] text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Description</div>,
  }),
  columnHelper.accessor('modified', {
    id: 'modified',
    cell: (info) => {
      const id = info.row.original.id;
      const value = info.getValue();
      const updatedBy = info.row.original.updatedBy;

      return (
        <div onClick={() => handleSelect(id)}>
          {value ? value + (updatedBy ? ' by ' + updatedBy : '') : '--'}
        </div>
      );
    },
    header: () => <div>Last Modified</div>,
  }),
  columnHelper.accessor('createdBy', {
    id: 'createdBy',
    cell: (info) => {
      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

      const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
        useState(false);

      const [openEditDatasetDialog, setOpenEditDatasetDialog] = useState(false);

      const [openWarningDialog, setOpenWarningDialog] = useState(false);

      const { orgId, projectId } = useGetRouteParams();

      const open = Boolean(anchorEl);

      const datasetId = info.row.original.id;

      const { data: dataset } = useGetDatasetById(
        {
          datasetId,
          orgId,
          projectId,
        },
        {
          enabled: !!orgId && !!datasetId && !!projectId,
        }
      );

      const { data: datasets } = useGetDatasets(
        {
          projectId,
          orgId,
        },
        {
          enabled: !!projectId && !!orgId,
        }
      );

      const { mutateAsync: duplicateDataset } = useDuplicateDataset();

      const currentOrgMember = useCurrentOrganization();

      const isAdmin = currentOrgMember && isOrgAdmin(currentOrgMember.role);

      const handleCloseDropdown = () => setAnchorEl(null);

      const handleOpenConfirmDeleteDialog = () => {
        setOpenConfirmDeleteDialog(true);
      };

      const handleCloseConfirmDeleteDialog = () => {
        setOpenConfirmDeleteDialog(false);
      };

      const handleOpenEditDatasetDialog = () => {
        setOpenEditDatasetDialog(true);
      };

      const handleCloseEditDatasetDialog = () => {
        setOpenEditDatasetDialog(false);
      };

      const handleOpenWarningDialog = () => {
        setOpenWarningDialog(true);
      };

      const handleCloseWarningDialog = () => {
        setOpenWarningDialog(false);
      };

      const handleDuplicateDataset = useCallback(() => {
        if (datasets && dataset) {
          const { project, name } = dataset;

          handleSetIsLoading(true);

          const res = duplicateDataset({
            orgId,
            projectId: project,
            datasetId,
            data: {
              name: getCopyDatasetName({
                name,
                datasets,
                dataset,
              }),
            },
          });

          toastPromise({
            promise: res,
            content: 'Dataset duplicated successfully',
          });

          handleSetIsLoading(false);
        }
      }, [dataset, orgId]);

      return info.getValue() ? (
        <div className="flex min-w-[200px] justify-between">
          <p
            className="max-w-[180px] grow truncate"
            onClick={() => handleSelect(projectId)}
          >
            {info.getValue()}
          </p>
          {isAdmin && (
            <MenuDropdown
              trigger={
                <button>
                  <EllipsisVertical color="#666666" width={20} height={20} />
                </button>
              }
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              style={{
                marginTop: '5px',
              }}
              open={open}
            >
              <MenuItem
                onClick={() => {
                  handleCloseDropdown();
                  handleDuplicateDataset();
                }}
              >
                <div className="flex items-center space-x-2">
                  <Copy size={15} />
                  <p>Duplicate</p>
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseDropdown();
                  handleOpenEditDatasetDialog();
                }}
              >
                <div className="flex items-center space-x-2">
                  <Pencil size={15} />
                  <p>Edit</p>
                </div>
              </MenuItem>
              <div className="!border-t !border-[#E4E7EC]" />
              <MenuItem
                onClick={() => {
                  handleCloseDropdown();

                  if (dataset && dataset.attached_models?.length > 0) {
                    handleOpenWarningDialog();
                    return;
                  }

                  handleOpenConfirmDeleteDialog();
                }}
              >
                <div className="flex items-center space-x-2 text-primary-red">
                  <Trash2 size={15} />
                  <p>Delete</p>
                </div>
              </MenuItem>
            </MenuDropdown>
          )}
          {dataset && (
            <CreateDatasetDialog
              open={openEditDatasetDialog}
              defaultValues={{
                id: dataset.id,
                name: dataset.name,
                description: dataset.description,
              }}
              handleCloseDialog={handleCloseEditDatasetDialog}
              handleSubmitDialog={handleCloseEditDatasetDialog}
            />
          )}
          {dataset && (
            <ConfirmDeleteDialog
              type="dataset"
              row={{
                id: dataset.id,
                name: dataset.name,
                description: dataset.description,
                modified: dataset.updated_at,
              }}
              open={openConfirmDeleteDialog}
              handleClose={handleCloseConfirmDeleteDialog}
            />
          )}
          {dataset && (
            <WarningDialog
              open={openWarningDialog}
              primaryActionText="Ok"
              handleSecondaryAction={handleCloseWarningDialog}
              handlePrimaryAction={handleCloseWarningDialog}
              content="You can’t delete this dataset because it’s being used in some models. Please revoke its usage before deleting."
            />
          )}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(projectId)}
          className="text-gray-400 text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Created by</div>,
  }),
];

const DatasetsTable = ({
  defaultDatasets,
  isLoading: isDefaultLoading,
}: {
  isLoading: boolean;
  defaultDatasets: TypeDataset[] | undefined;
}) => {
  const [data, setData] = useState<DatasetRow[]>(defaultData);
  const [isLoading, setIsLoading] = useState(isDefaultLoading);

  const navigate = useNavigate();

  const handleSelect = (id: number) => {
    navigate(id.toString());
  };

  const handleSetIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };

  useEffect(() => {
    setIsLoading(isDefaultLoading);
  }, [isDefaultLoading]);

  useEffect(() => {
    if (defaultDatasets) {
      setData(
        defaultDatasets.map((dataset) => ({
          id: dataset.id,
          name: dataset.name,
          description: dataset.description,
          modified: format(dataset.updated_at ?? '', 'Y/MM/dd hh:mm a'),
          createdBy: dataset.created_by?.full_name,
          updatedBy: dataset.updated_by?.full_name,
        }))
      );
    }
  }, [defaultDatasets]);

  const table = useReactTable({
    data,
    columns: columns({
      handleSelect,
      handleSetIsLoading,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TanstackTable
      table={table}
      isLoading={isLoading}
      emptyText={'No datasets available.'}
    />
  );
};

export default DatasetsTable;
