import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import { createModel, deleteModelById, updateModel } from '../api';
import {
  cloneModel,
  type CloneModelInput,
  type CreateModelInput,
  type DeleteModelInput,
  getModelById,
  type GetModelByIdInput,
  getModelOutput,
  type GetModelOutputInput,
  getModels,
  type GetModelsInput,
  getReceivedModelById,
  getReceivedModelOutput,
  type GetReceivedModelOutputInput,
  getReceivedModels,
  type GetReceivedModelsInput,
  getSharedModelById,
  type GetSharedModelByIdInput,
  getSharedModelOutput,
  type GetSharedModelOutputInput,
  getSharedModels,
  getSharedModelSharingInvitations,
  type GetSharedModelSharingInvitationsInput,
  type GetSharedModelsInput,
  importReceivedModel,
  type ImportReceivedModelInput,
  importSharedModel,
  type ImportSharedModelInput,
  runModelOutput,
  type RunModelOutputInput,
  shareModel,
  type ShareModelInput,
  shareModelToHost,
  type ShareModelToHostInput,
  type UpdateModelInput,
} from '../api/models';
import {
  type TypeModel,
  type TypeSharedModel,
  type TypeSharedModelSharingInvitation,
} from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

const createModelFn = async (data: CreateModelInput) => {
  const { data: model }: AxiosResponse<TypeModel> = await createModel(data);

  return model;
};

const updateModelFn = async (data: UpdateModelInput) => {
  const { data: model }: AxiosResponse<TypeModel> = await updateModel(data);

  return model;
};

const deleteModelFn = async (data: DeleteModelInput) => {
  const { data: model }: AxiosResponse = await deleteModelById(data);

  return model;
};

export const useDeleteModel = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof deleteModelFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteModelFn'],
    mutationFn: (input: DeleteModelInput) => deleteModelFn(input),
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getModels'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUpdateModel = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof updateModelFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateModelFn'],
    mutationFn: (input: UpdateModelInput) => updateModelFn(input),
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getModels'],
      });
      queryClient.refetchQueries({
        queryKey: ['getModelById'],
      });
      queryClient.refetchQueries({
        queryKey: ['getScenariosByModelId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useGetModelOutput = (
  input: GetModelOutputInput,
  opts?: Partial<
    UseQueryOptions<
      Awaited<Record<string, Record<string, number[]>>>,
      DefaultQueryError
    >
  >
) =>
  useQuery({
    queryKey: ['getModelOutput', input],
    queryFn: async () => {
      const {
        data: model,
      }: AxiosResponse<Record<string, Record<string, number[]>>> =
        await getModelOutput(input);

      return model;
    },
    ...opts,
  });

export const useGetSharedModelOutput = (
  input: GetSharedModelOutputInput,
  opts?: Partial<
    UseQueryOptions<
      Awaited<Record<string, Record<string, number[]>>>,
      DefaultQueryError
    >
  >
) =>
  useQuery({
    queryKey: ['getSharedModelOutput', input],
    queryFn: async () => {
      const {
        data: model,
      }: AxiosResponse<Record<string, Record<string, number[]>>> =
        await getSharedModelOutput(input);

      return model;
    },
    ...opts,
  });

export const useGetReceivedModelOutput = (
  input: GetReceivedModelOutputInput,
  opts?: Partial<
    UseQueryOptions<
      Awaited<Record<string, Record<string, number[]>>>,
      DefaultQueryError
    >
  >
) =>
  useQuery({
    queryKey: ['getReceivedModelOutput', input],
    queryFn: async () => {
      const {
        data: model,
      }: AxiosResponse<Record<string, Record<string, number[]>>> =
        await getReceivedModelOutput(input);

      return model;
    },
    ...opts,
  });

export const useRunModelOutput = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<Record<string, Record<string, number[]>>>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['runModelOutput'],
    mutationFn: async (input: RunModelOutputInput) => {
      const { data }: AxiosResponse<Record<string, Record<string, number[]>>> =
        await runModelOutput(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getModelOutput'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useCreateModel = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof createModelFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createModelFn'],
    mutationFn: (input: CreateModelInput) => createModelFn(input),
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getModels'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useShareModel = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof createModelFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['shareModel'],
    mutationFn: async (input: ShareModelInput) => {
      const { data } = await shareModel(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getSharedModels'],
      });
      queryClient.refetchQueries({
        queryKey: ['getSharedRequests'],
      });
      queryClient.refetchQueries({
        queryKey: ['getSentInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useShareModelToHost = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof createModelFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['shareModelToHost'],
    mutationFn: async (input: ShareModelToHostInput) => {
      const { data } = await shareModelToHost(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getModelsByDatasetId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCloneModel = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof createModelFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['cloneModel'],
    mutationFn: async (input: CloneModelInput) => {
      const { data } = await cloneModel(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getModelsByDatasetId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useImportReceivedModel = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['importReceivedModel'],
    mutationFn: async (input: ImportReceivedModelInput) => {
      const { data }: AxiosResponse = await importReceivedModel(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: [
          'getModels',
          {
            orgId: variables.orgId,
            projectId: variables.projectId,
          },
        ],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useImportSharedModel = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['importSharedModel'],
    mutationFn: async (input: ImportSharedModelInput) => {
      const { data }: AxiosResponse = await importSharedModel(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: [
          'getModels',
          {
            orgId: variables.orgId,
            projectId: variables.projectId,
          },
        ],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetModels = (
  input: GetModelsInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeModel[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getModels', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeModel[]> = await getModels(input);

      return data;
    },
    ...opts,
  });

export const useGetSharedModelSharingInvitations = (
  input: GetSharedModelSharingInvitationsInput,
  opts?: Partial<
    UseQueryOptions<
      Awaited<TypeSharedModelSharingInvitation[]>,
      DefaultQueryError
    >
  >
) =>
  useQuery({
    queryKey: ['getSharedModelSharingInvitations', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeSharedModelSharingInvitation[]> =
        await getSharedModelSharingInvitations(input);

      return data;
    },
    ...opts,
  });

export const useGetSharedModels = (
  input: GetSharedModelsInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeSharedModel[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getSharedModels', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeSharedModel[]> =
        await getSharedModels(input);

      return data.sort(
        (a, b) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    },
    ...opts,
  });

export const useGetReceivedModels = (
  input: GetReceivedModelsInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeSharedModel[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getReceivedModels', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeSharedModel[]> =
        await getReceivedModels(input);

      return data;
    },
    ...opts,
  });

export const useGetModelById = (
  input: GetModelByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeModel>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getModelById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeModel> = await getModelById(input);

      return data;
    },
    ...opts,
  });

export const useGetSharedModelById = (
  input: GetSharedModelByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeSharedModel>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getSharedModelById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeSharedModel> =
        await getSharedModelById(input);

      return data;
    },
    ...opts,
  });

export const useGetReceivedModelById = (
  input: GetSharedModelByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeSharedModel>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getReceivedModelById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeSharedModel> =
        await getReceivedModelById(input);

      return data;
    },
    ...opts,
  });
