import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import _ from 'lodash';

import {
  type TypeIFSConfiguration,
  type TypeProject,
} from '../../common/dataTypes';
import { formatIfsConfig } from '../../common/utils';
import { useGetProjectById } from '../../hooks';
import { useGetDatasets, useGetIFSConfig } from '../../hooks/useDatasetsHook';
import {
  useCustomNavigate,
  useGetRoles,
  useGetRouteParams,
} from '../../hooks/useUtilsHook';
import routes from '../../routes';
import cn from '../../utils/cn';
import CreateIFSConfigForm from '../dataset/CreateIFSConfigForm';

import CompleteOnboarding from './CompleteOnboarding';
import CreateProjectForm from './CreateProjectForm';
import ProjectCreateDatasetForm from './ProjectCreateDatasetForm';
import ProjectReviewConfirm from './ProjectReviewConfirm';

export const handleGoBackPreviousStep = (step: string) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const previousStep = Number(step) - 1;

  searchParams.set('step', previousStep.toString());
  setSearchParams(searchParams);
};

const ProjectStep = ({
  step,
  title,
  status,
}: {
  title: string;
  step: string;
  status?: 'pending' | 'completed';
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { orgId, projectId } = useGetRouteParams();

  const currentStep = searchParams.get('step');

  const { data: project } = useGetProjectById(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const handleSelect = (step: string) => {
    searchParams.set('step', step);

    if (!project) {
      return;
    }

    const status = project.onboarding_status;

    if (
      Number(step) === 3 &&
      status !== 'uploading_data' &&
      status !== 'reviewing'
    ) {
      return;
    } else if (Number(step) === 4 && status !== 'reviewing') {
      return;
    }

    setSearchParams(searchParams);
  };

  return (
    <div
      onClick={() => {
        handleSelect(step);
      }}
      className={cn('flex transition cursor-pointer flex-col gap-1 px-6 py-3', {
        'bg-[#E6E6E6]': _.isEqual(step, currentStep),
      })}
    >
      <div className="flex gap-2">
        <p className="text-sm font-bold text-[#666666]">Step {step}</p>
        {status === 'completed' && (
          <p className="text-white flex items-center rounded-full bg-[#0B9919] px-2 text-xs capitalize">
            {status}
          </p>
        )}
        {status === 'pending' && (
          <p className="text-white flex items-center rounded-full bg-[#ECA000] px-2 text-xs capitalize">
            In progress
          </p>
        )}
      </div>
      <p>{title}</p>
    </div>
  );
};

const ProjectStepBody = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { orgId } = useGetRouteParams();

  const navigate = useNavigate();
  const projectId = Number(searchParams.get('project_id'));

  const step = searchParams.get('step');

  const { data: project } = useGetProjectById(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const { data: ifsConfig } = useGetIFSConfig(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const { data: datasets } = useGetDatasets(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const handleOnCancelCreateProject = () => {
    navigate(`/${orgId}/projects`);
  };

  const handleSetStep = (step: string) => {
    searchParams.set('step', step);
    setSearchParams(searchParams);
  };

  switch (step) {
    case '1':
      const handleOnCreateProject = (
        data:
          | TypeProject
          | {
              id: number;
            }
      ) => {
        if (data) {
          searchParams.set('project_id', data.id.toString());
          setSearchParams(searchParams);

          handleSetStep('2');
        }
      };

      return (
        <div className="flex flex-col py-6 gap-6">
          <div className="px-6">
            <h1 className="text-2xl font-bold">Create new Project</h1>
          </div>
          <CreateProjectForm
            handleOnSubmit={handleOnCreateProject}
            handleOnCancel={handleOnCancelCreateProject}
            defaultValues={
              project
                ? {
                    id: project.id,
                    name: project.name,
                    description: project.description,
                  }
                : undefined
            }
          />
        </div>
      );

    case '2':
      const handleOnCreateIfsConfig = (data: TypeIFSConfiguration) => {
        if (data) {
          handleSetStep('3');
        }
      };

      const ifsDefaultData = ifsConfig ? formatIfsConfig(ifsConfig) : undefined;

      return (
        <div className="flex flex-col gap-6 p-6">
          <h1 className="text-2xl font-bold">Create IFS Configuration</h1>
          <CreateIFSConfigForm
            defaultValues={ifsDefaultData}
            handleOnCancel={handleOnCancelCreateProject}
            handleOnSubmit={handleOnCreateIfsConfig}
          />
        </div>
      );

    case '3':
      const defaultValues = datasets?.[0];

      return (
        <div className="flex flex-col gap-6 p-6">
          <h1 className="text-2xl font-bold">Create Dataset</h1>
          <ProjectCreateDatasetForm
            defaultValues={defaultValues}
            handleOnSubmit={() => handleSetStep('4')}
            handleOnCancel={handleOnCancelCreateProject}
          />
        </div>
      );

    case '4':
      const handleOnSubmit = () => {
        handleSetStep('success');
      };

      return (
        <div className="flex flex-col gap-6 p-6">
          <h1 className="text-2xl font-bold">Review and Confirm</h1>
          <ProjectReviewConfirm handleOnSubmit={handleOnSubmit} />
        </div>
      );

    default:
      return <></>;
  }
};

const ProjectCreateView = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { orgId, projectId } = useGetRouteParams();

  const step = searchParams.get('step');

  const navigate = useCustomNavigate();

  const { projectRole } = useGetRoles();

  const { data: project, isSuccess } = useGetProjectById(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  useEffect(() => {
    if (projectRole && projectRole !== 'project_owner') {
      navigate(routes.projects.index, {
        org_id: orgId.toString(),
      });
    }
  }, [projectRole]);

  useEffect(() => {
    if (!step) {
      searchParams.set('step', '1');
      setSearchParams(searchParams);
    }
  }, [step]);

  useEffect(() => {
    if (!project && isSuccess) {
      searchParams.set('step', '1');
      setSearchParams(searchParams);
    }
  }, [project]);

  if (step === 'success') {
    return <CompleteOnboarding />;
  }

  const status = project?.onboarding_status;

  return (
    <div className="flex gap-6">
      <div className="min-h-[95vh] w-[45%] lg:w-[20%] 3xl:w-[15%] top-[60px] bg-[#F8F8F8]">
        <div className="border-b border-[#E4E7EC] px-6 py-4">
          <p className="text-lg font-bold text-[#01285F]">
            {project?.name ?? 'Untitled'}
          </p>
        </div>
        <ProjectStep
          title="Create A New Project"
          step="1"
          status={project ? 'completed' : 'pending'}
        />
        <ProjectStep
          title="Create IFS Configuration"
          step="2"
          status={
            status === 'configuring'
              ? 'pending'
              : status === 'completed' ||
                  status === 'uploading_data' ||
                  status === 'reviewing'
                ? 'completed'
                : undefined
          }
        />
        <ProjectStep
          title="Create Dataset"
          step="3"
          status={
            status === 'uploading_data'
              ? 'pending'
              : status === 'completed' || status === 'reviewing'
                ? 'completed'
                : undefined
          }
        />
        <ProjectStep title="Review and Confirm" step={'4'} />
      </div>
      <div className="w-full grow">
        <ProjectStepBody />
      </div>
    </div>
  );
};

export default ProjectCreateView;
