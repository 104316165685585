import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import {
  type ColumnsStylesInterface,
  type GridExcelExportOptions,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ArrowDownToLine, ChevronLeft } from 'lucide-react';
import { Pencil } from 'lucide-react';

import { useGetDataTableByName } from '../../hooks/useDataTablesHook';
import {
  useActivateBaseScenario,
  useActivateScenario,
  useGetBaseScenario,
  useGetBaseScenarioData,
  useGetScenarioById,
  useGetScenarioData,
} from '../../hooks/useScenariosHook';
import {
  useGetDistinguishModel,
  useGetRouteParams,
} from '../../hooks/useUtilsHook';
import { useCurrentProject } from '../../utils/helpers';
import DataGridPremiumTable from '../DataGridPremiumTable';
import CreateScenarioFormDialog from '../scenario/CreateScenarioFormDialog';
import VectorsSection from '../scenario/VectorsSection';
import CreateVectorFormDialog from '../vector/CreateVectorFormDialog';

const ModelScenarioDetailView = () => {
  const currentProject = useCurrentProject();

  const { orgId, projectId, inputTableName, modelId, scenarioId } =
    useGetRouteParams();

  const apiRef = useGridApiRef();

  const navigate = useNavigate();

  const [openDialogCreateVector, setOpenDialogCreateVector] = useState(false);
  const [openDialogEditScenario, setOpenDialogEditScenario] = useState(false);

  const form = useForm({
    defaultValues: {
      aggregateColumn: '',
    },
  });

  const { data: dataTable } = useGetDataTableByName(
    {
      dataTableName: inputTableName ?? '',
      orgId,
      projectId,
      modelId,
    },
    {
      enabled: !!inputTableName && !!orgId && !!projectId && !!modelId,
    }
  );

  const { data: scenario, refetch: refetchScenario } = useGetScenarioById(
    {
      scenarioId,
      orgId,
      projectId,
      modelId,
    },
    {
      enabled:
        !!scenarioId && !!orgId && !!projectId && !!modelId && scenarioId !== 0,
    }
  );

  const { data: baseScenario } = useGetBaseScenario(
    {
      orgId,
      modelId,
      projectId,
      dataTableName: inputTableName ?? '',
    },
    {
      enabled:
        !!orgId &&
        !!modelId &&
        !!projectId &&
        !!inputTableName &&
        scenarioId === 0,
    }
  );

  const { model, type } = useGetDistinguishModel();

  const {
    data: scenarioData,
    isPending: isGenerateScenarioData,
    refetch: refetchGenerateScenarioData,
    isRefetching: isRefetchingScenarioData,
  } = useGetScenarioData(
    {
      scenarioId,
      orgId,
      modelId,
      projectId,
    },
    {
      enabled: !!scenarioId && !!orgId && !!projectId && !!modelId,
    }
  );

  const {
    data: baseScenarioData,
    isPending: isGenerateBaseScenarioData,
    refetch: refetchGenerateBaseScenarioData,
    isRefetching: isRefetchingBaseScenarioData,
  } = useGetBaseScenarioData(
    {
      orgId,
      modelId,
      projectId,
      dataTableName: inputTableName ?? '',
    },
    {
      enabled: !!inputTableName && !!orgId && !!projectId && !!modelId,
    }
  );

  const { mutate: activateScenario, isPending: isActivatingScenario } =
    useActivateScenario();

  const { mutate: activateBaseScenario, isPending: isActivatingBaseScenario } =
    useActivateBaseScenario();

  const handleExportToExcel = () => {
    const colStyles: ColumnsStylesInterface = {};

    dataTable?.columns.forEach((colDef) => {
      colStyles[colDef.name] = {
        numFmt: colDef.name === 'employee_id' ? '#,##' : '#,##0.00',
        font: {},
        alignment: {},
        protection: {},
        border: {},
        fill: {
          type: 'pattern',
          pattern: 'none',
        },
      };
    });

    const options: GridExcelExportOptions = {
      fileName: getExportFileName(),
      columnsStyles: { ...colStyles },
    };

    apiRef.current.exportDataAsExcel(options);
  };

  const handleGoBack = () => {
    navigate(
      `/${orgId}/projects/${projectId}/models/${modelId}?tab=scenario&type=${type}`
    );
  };

  const handleOpenDialogEditScenario = () => {
    setOpenDialogEditScenario(true);
  };

  const handleCloseDialogEditScenario = () => {
    setOpenDialogEditScenario(false);
  };

  const getExportFileName = () => {
    const modelName = model ? model.name : 'model_unknown';
    const dataTableName = dataTable ? dataTable.name : 'table_unknown';
    const scenarioName = scenario ? scenario.name : 'scenario_unknown';

    return modelName + '_' + dataTableName + '_' + scenarioName;
  };

  const handleActivateScenario = () => {
    if (!inputTableName) {
      return;
    }

    if (scenarioId === 0) {
      activateBaseScenario({
        orgId,
        projectId,
        modelId,
        dataTableName: inputTableName,
      });
      return;
    }

    activateScenario({
      orgId,
      projectId,
      modelId,
      scenarioId,
      dataTableName: inputTableName,
    });
  };

  useEffect(() => {
    refetchGenerateScenarioData();
    refetchGenerateBaseScenarioData();
  }, [scenarioId]);

  const isGuestProject = currentProject?.is_guest_project;

  const scenarioInfo =
    scenarioId === 0
      ? {
          ...baseScenario,
          is_base: true,
        }
      : scenario;

  return (
    <FormProvider {...form}>
      <form>
        <div className="flex flex-col space-y-6 px-12">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <ChevronLeft
                onClick={handleGoBack}
                className="cursor-pointer"
                size={25}
                color="#333333"
              />
              <h1 className="text-2xl font-bold">{scenarioInfo?.name}</h1>
              {!scenarioInfo?.is_base && (
                <Pencil
                  size={20}
                  color="#333333"
                  className="cursor-pointer"
                  onClick={handleOpenDialogEditScenario}
                />
              )}
            </div>
            {!scenarioInfo?.is_active && (
              <LoadingButton
                type="button"
                loading={isActivatingScenario || isActivatingBaseScenario}
                onClick={handleActivateScenario}
                variant="contained"
              >
                Use this scenario
              </LoadingButton>
            )}
          </div>
          {!scenarioInfo?.is_base && (
            <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
              <h2 className="font-bold">Description</h2>
              <p>{scenarioInfo?.description}</p>
            </div>
          )}
          {!scenarioInfo?.is_base && !isGuestProject && dataTable?.is_pivot && (
            <div className="space-y-3">
              <div className="flex justify-between">
                <h2 className="text-xl font-bold">Vectors</h2>
                <CreateVectorFormDialog
                  open={openDialogCreateVector}
                  title="Add new vector"
                  handleCloseDialog={() => {
                    setOpenDialogCreateVector(false);
                  }}
                  handleOpenDialog={() => {
                    setOpenDialogCreateVector(true);
                  }}
                />
              </div>
              {dataTable.is_pivot ? (
                <VectorsSection
                  handleOnActivate={refetchGenerateScenarioData}
                />
              ) : (
                <div className="w-full text-center text-gray-500">
                  This input data table doesn't support vectors
                </div>
              )}
            </div>
          )}
          <div className="space-y-4">
            <div className="flex justify-between">
              <div className="flex flex-col gap-2 w-full justify-end">
                <h2 className="text-xl font-bold">Data</h2>
              </div>
              <div className="flex w-full justify-end items-end">
                <div>
                  <Button
                    onClick={handleExportToExcel}
                    variant="outlined"
                    className="space-x-2"
                  >
                    <ArrowDownToLine />
                    <label className="font-semibold capitalize">
                      Export to Excel
                    </label>
                  </Button>
                </div>
              </div>
            </div>
            <DataGridPremiumTable
              apiRef={apiRef}
              columns={
                dataTable?.columns?.map((c) => ({
                  label: c.display_name,
                  name: c.name,
                  is_pinned: c.is_pinned,
                  data_type: c.data_type,
                  styles: c.styles,
                })) ?? []
              }
              base={
                !scenarioInfo?.is_base
                  ? {
                      data: baseScenarioData,
                      mode: 'vector',
                    }
                  : undefined
              }
              isLoading={
                isGenerateScenarioData ||
                isRefetchingScenarioData ||
                isGenerateBaseScenarioData ||
                isRefetchingBaseScenarioData
              }
              data={scenarioId === 0 ? baseScenarioData : scenarioData}
            />
          </div>
        </div>
        {scenario && (
          <CreateScenarioFormDialog
            title="Edit Scenario"
            showTrigger={false}
            defaultValues={{
              id: scenario.id,
              name: scenario.name,
              description: scenario.description,
            }}
            open={openDialogEditScenario}
            handleCloseDialog={handleCloseDialogEditScenario}
            handleOpenDialog={handleOpenDialogEditScenario}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default ModelScenarioDetailView;
