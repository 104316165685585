import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { Button, Dialog, OutlinedInput } from '@mui/material';

import { useTransferOrgOwnership } from '../hooks/useOrganizationHook';
import { useGetRouteParams } from '../hooks/useUtilsHook';

import SuccessDialog from './SuccessDialog';

type ConfirmTransferDialogProps = {
  open: boolean;
  handleClose: () => void;
  user: {
    id: number;
    name: string;
    email: string;
  };
};

const ConfirmTransferDialog = ({
  open,
  handleClose,
  user,
}: ConfirmTransferDialogProps) => {
  const [confirmText, setConfirmText] = useState('');
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const { orgId } = useGetRouteParams();

  const [error, setError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: '',
  });

  const form = useForm();

  const { mutateAsync: transferOrgOwnership, isPending: isTransferring } =
    useTransferOrgOwnership({
      onSuccess: () => {
        handleClose();
        handleOpenSuccessDialog();
      },
    });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(e.target.value);
  };

  const handleOpenSuccessDialog = () => {
    setOpenSuccessDialog(true);
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  const handleSubmit = async () => {
    if (!user) {
      return;
    }

    if (confirmText !== user.email) {
      setError({
        isError: true,
        message: `User email does not match`,
      });
      return;
    }

    transferOrgOwnership({
      orgId,
      data: {
        user: user.id,
      },
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          if (isTransferring) {
            return;
          }

          handleClose();
        }}
        aria-labelledby="alertTitle"
        aria-describedby="alertContent"
      >
        <FormProvider {...form}>
          <form className="flex flex-col gap-6 p-6">
            <h2 className="text-xl font-bold">
              Are you sure you want to transfer ownmership to {user.name}
            </h2>
            <>
              <p className="text-[#666]">
                Type "<span className="font-bold">{user.email}</span>" to
                transfer. This cannot be undone.
              </p>
              <div>
                <OutlinedInput
                  fullWidth
                  size="small"
                  onChange={handleOnChange}
                />
                {error && (
                  <p className="w-full pt-2 text-start text-[14px] capitalize text-[#FF816A]">
                    {error.message as string}
                  </p>
                )}
              </div>
            </>
            <div className="flex justify-end gap-3">
              <Button
                onClick={handleClose}
                variant="outlined"
                tabIndex={3}
                disabled={isTransferring}
                style={{
                  color: '#666',
                  borderColor: '#B3B3B3',
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                onClick={handleSubmit}
                tabIndex={2}
                loading={isTransferring}
                variant="contained"
                style={{
                  backgroundColor: '#2196F3',
                  color: '#FFF',
                }}
              >
                Transfer
              </LoadingButton>
            </div>
          </form>
        </FormProvider>
      </Dialog>
      <SuccessDialog
        content={`Ownership transferred to ${user.name} successfully.`}
        open={openSuccessDialog}
        handleOnClose={handleCloseSuccessDialog}
      />
    </>
  );
};

export default ConfirmTransferDialog;
