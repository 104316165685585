import React from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import { GitCompareArrows } from 'lucide-react';
import * as z from 'zod';

import { type TypeModel } from '../../common/dataTypes';
import { useGetModels, useRunModelOutput } from '../../hooks/useModelsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { arrangeByBase } from '../../utils/helpers';
import FormAutoComplete from '../FormAutoComplete';

import ModelComparisonOutputTabs from './ModelComparisonOutputTabs';

const ModelComparisonProps = z.object({
  modelA: z.string(),
  modelB: z.string(),
});

type ModelComparisonType = z.infer<typeof ModelComparisonProps>;

const ModelComparisonView = () => {
  const { projectId, orgId } = useGetRouteParams();

  const form = useForm<ModelComparisonType>({
    resolver: zodResolver(ModelComparisonProps),
  });

  const { watch, handleSubmit } = form;

  const { modelA, modelB } = watch();

  const { data: models } = useGetModels(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { mutateAsync: runModelOutput } = useRunModelOutput();

  const onSubmit: SubmitHandler<ModelComparisonType> = async (data) => {
    if (!data.modelA || !data.modelB) {
      return;
    }

    if (data.modelA && data.modelB) {
      await runModelOutput({
        orgId,
        projectId,
        modelId: Number(data.modelA),
        data: {
          filters: {},
        },
      });

      await runModelOutput({
        orgId,
        projectId,
        modelId: Number(data.modelA),
        data: {
          filters: {},
        },
      });
      return;
    }
  };

  const listModel = models
    ? arrangeByBase(models.filter((m) => !m.sharing) as TypeModel[]).map(
        (model) => ({
          label: model.name,
          value: model.id.toString(),
        })
      )
    : [
        {
          label: '',
          value: '',
        },
      ];

  return (
    <div className="flex flex-col gap-6">
      <h1 className="font-bold text-2xl">Compare models</h1>
      <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
        <h2 className="font-bold">Instruction</h2>
        <p className="font-normal">
          The comparison works by subtracting Model B from Model A to highlight
          the differences. In the results, green indicates an increase, while
          red indicates a decrease.
        </p>
      </div>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex items-center justify-between"
        >
          <div className="flex w-[50%] gap-4">
            <FormAutoComplete
              label="Model A"
              name="modelA"
              options={listModel}
            />
            <FormAutoComplete
              label="Model B"
              name="modelB"
              options={listModel}
            />
          </div>
          <Button
            style={{
              color: 'white',
              display: 'flex',
              gap: 4,
              backgroundColor: '#B8341B',
            }}
            type="submit"
          >
            <GitCompareArrows size={20} />
            <p>Run Comparison</p>
          </Button>
        </form>
      </FormProvider>
      <div>
        <ModelComparisonOutputTabs
          baseId={Number(modelA)}
          targetId={Number(modelB)}
        />
      </div>
    </div>
  );
};

export default ModelComparisonView;
