import React from 'react';

import { useGetRoles } from '../../hooks/useUtilsHook';

import ProjectReview from './ProjectReview';

const ProjectSettings = () => {
  const { projectRole } = useGetRoles();

  return (
    <div className="px-12 pb-12 space-y-6">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">Project Settings</h1>
      </div>
      <ProjectReview
        editable={projectRole === 'project_owner'}
        deletable={projectRole === 'project_owner'}
      />
    </div>
  );
};

export default ProjectSettings;
