import { mainApi } from '../utils/api';

export type CreateModelInput = {
  orgId: number;
  projectId: number;
  data: {
    name: string;
    description?: string;
    dataset: number;
    project: number;
    copy_scenarios_from?: number;
    copy_dataset: boolean;
    new_dataset_name?: string;
    new_dataset_description?: string;
  };
};

export type UpdateModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  data: {
    name?: string;
    description?: string;
    predefined_expenses?: number[];
    userdefined_expenses?: number[];
    retirement_age?: number;
    seat_lock_period?: number;
    dataset: number;
  };
};

export type GetModelsInput = {
  orgId: number;
  projectId: number;
};

export type ShareModelInput = {
  modelId: number;
  orgId: number;
  projectId: number;
  data: {
    separate_guests?: boolean;
    guests: {
      email: string;
      role: string;
    }[];
  };
};

export type GetModelsByDatasetIdInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
};

export type GetModelByIdInput = {
  modelId: number;
  orgId: number;
  projectId: number;
};

export type GetModelOutputInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type RunModelOutputInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  data: {
    filters: {
      aircrafts?: string[];
      airports?: string[];
    };
  };
};

export type DeleteModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type CloneModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type ShareModelToHostInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type GetModelDataTableSchemasInput = {
  projectId: number;
  orgId: number;
  modelId: number;
};

export type GetSharedModelsInput = {
  orgId: number;
  projectId: number;
};

export type GetSharedModelByIdInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type GetReceivedModelsInput = {
  orgId: number;
  projectId: number;
};

export type GetSharedModelOutputInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type GetReceivedModelByIdInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type GetReceivedModelOutputInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type GetSharedModelSharingInvitationsInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type ImportReceivedModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type ImportSharedModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

const getModels = ({ orgId, projectId }: GetModelsInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/models/`);

const getSharedModels = ({ orgId, projectId }: GetSharedModelsInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/shared_models/`);

const getSharedModelById = ({
  orgId,
  projectId,
  modelId,
}: GetSharedModelByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/shared_models/${modelId}`
  );

const getReceivedModels = ({ orgId, projectId }: GetReceivedModelsInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/received_models/`);

const shareModel = ({ orgId, data, projectId, modelId }: ShareModelInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/share/`,
    data
  );

const shareModelToHost = ({
  orgId,
  projectId,
  modelId,
}: ShareModelToHostInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/share_to_host/`
  );

const cloneModel = async ({ orgId, projectId, modelId }: CloneModelInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/clone/`
  );

const getModelsByDatasetId = ({
  orgId,
  projectId,
  datasetId,
}: GetModelsByDatasetIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/?dataset=${datasetId}`
  );

const getModelById = ({ orgId, projectId, modelId }: GetModelByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/`
  );

const getReceivedModelById = ({
  orgId,
  projectId,
  modelId,
}: GetReceivedModelByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/received_models/${modelId}/`
  );

const getModelOutput = ({ modelId, orgId, projectId }: GetModelOutputInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/output/`
  );

const getSharedModelOutput = ({
  modelId,
  orgId,
  projectId,
}: GetSharedModelOutputInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/shared_models/${modelId}/output/`
  );

const getReceivedModelOutput = ({
  modelId,
  orgId,
  projectId,
}: GetReceivedModelOutputInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/received_models/${modelId}/output/`
  );

const importSharedModel = ({
  modelId,
  orgId,
  projectId,
}: ImportSharedModelInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/shared_models/${modelId}/clone/`
  );

const importReceivedModel = ({
  modelId,
  orgId,
  projectId,
}: ImportReceivedModelInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/received_models/${modelId}/clone/`
  );

const runModelOutput = ({
  modelId,
  orgId,
  projectId,
  data,
}: RunModelOutputInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/run/`,
    data
  );

const createModel = ({ orgId, projectId, data }: CreateModelInput) =>
  mainApi.post(`organizations/${orgId}/projects/${projectId}/models/`, data);

const deleteModelById = ({ orgId, projectId, modelId }: DeleteModelInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/`
  );

const updateModel = ({ orgId, projectId, modelId, data }: UpdateModelInput) =>
  mainApi.patch(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/`,
    data
  );

const getSharedModelSharingInvitations = ({
  modelId,
  orgId,
  projectId,
}: GetSharedModelSharingInvitationsInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/shared_models/${modelId}/sharing_invitations/`
  );

export {
  cloneModel,
  createModel,
  deleteModelById,
  getModelById,
  getModelOutput,
  getModels,
  getModelsByDatasetId,
  getReceivedModels,
  getReceivedModelById,
  getReceivedModelOutput,
  getSharedModelSharingInvitations,
  getSharedModels,
  getSharedModelOutput,
  getSharedModelById,
  runModelOutput,
  shareModel,
  shareModelToHost,
  updateModel,
  importReceivedModel,
  importSharedModel,
};
