import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import JSZip from 'jszip';

import { type TemplateSheet, type TypeIFS } from '../common/dataTypes';

type CreateWorkbookProps = {
  file: {
    name: string;
    imported_at: string;
    is_imported: boolean;
    template: {
      sheets: TemplateSheet[];
    };
  };
};

const createWorkbook = ({ file }: CreateWorkbookProps) => {
  const workbook = new ExcelJS.Workbook();
  const template = file.template;

  template.sheets.forEach((sheet: any) => {
    const worksheet = workbook.addWorksheet(sheet.display_name);

    // Add title
    if (sheet.title) {
      const titleRow = worksheet.addRow([sheet.title]);

      titleRow.font = {
        name: 'Arial',
        size: 14,
        bold: true,
      };
      titleRow.height = 40;
    }

    // Add columns
    if (sheet.columns) {
      worksheet.columns = sheet.columns.map((col: any) => ({
        key: col.name,
        width: col.width || 12,
      }));

      // Add table header row
      const headerNames = [];
      sheet.columns.forEach((col: any) => {
        if (col.display_name) {
          headerNames.push(col.display_name);
        }
      });

      if (headerNames.length > 0) {
        const headerRow = worksheet.addRow(
          sheet.columns.map((col: any) => col.display_name)
        );

        headerRow.font = {
          name: 'Arial',
          size: 10,
          bold: true,
        };
        headerRow.height = 40;
      }
    }

    // Add data rows
    const DEFAULT_ROW_FONT = {
      name: 'Arial',
      size: 10,
    };

    if (sheet.rows) {
      sheet.rows.forEach((row: any) => {
        worksheet.addRow(row.cells.map((cell: any) => cell.value)).font = {
          ...DEFAULT_ROW_FONT,
          ...row.format?.font,
        };
      });
    }

    // Apply sheet format
    if (sheet.format) {
      const sheetFormat = sheet.format;
      const frozen = sheetFormat.frozen;
      if (frozen) {
        worksheet.views = [
          {
            state: 'frozen',
            xSplit: frozen.x_split,
            ySplit: frozen.y_split,
          },
        ];
      }
    }

    if (sheet.format && sheet.format.tab_color) {
      worksheet.properties.tabColor = {
        argb: sheet.format.tab_color,
      };
    }
  });

  return workbook;
};

const saveIfsAsZip = (ifs: TypeIFS, datasetName: string) => {
  const zip = new JSZip();

  if (ifs.files.length > 1) {
    const promises = ifs.files.map(
      (file) =>
        new Promise<void>((resolve, reject) => {
          const workbook = createWorkbook({ file });

          // Write workbook to buffer and add to zip
          workbook.xlsx
            .writeBuffer()
            .then((data: any) => {
              const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });

              zip.file(file.name, blob);

              resolve();
            })
            .catch(reject);
        })
    );

    Promise.all(promises)
      .then(() => {
        zip
          .generateAsync({ type: 'blob' })
          .then((content) => {
            FileSaver.saveAs(content, `IFS_Template_${datasetName}.zip`);
          })
          .catch((error) => {
            console.error('Error generating zip file:', error);
          });
      })
      .catch((error) => {
        console.error('Error processing files:', error);
      });
  } else {
    const file = ifs.files[0];

    const workbook = createWorkbook({
      file,
    });

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      FileSaver.saveAs(blob, file.name);
    });
  }
};

const useIfsConverter = () => ({ saveIfsAsZip });

export default useIfsConverter;
