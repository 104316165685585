import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@mui/material';

import { type TypeProject } from '../../common/dataTypes';
import { useGetDatasetById } from '../../hooks';
import { useCompleteOnboarding } from '../../hooks/useProjectsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import ReviewIFSFilesTabs from '../ReviewIFSFilesTabs';

import ProjectReview from './ProjectReview';

type ProjectReviewConfirmProps = {
  handleOnSubmit?: (data: TypeProject) => void;
};

const ProjectReviewConfirm = ({
  handleOnSubmit,
}: ProjectReviewConfirmProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { orgId, datasetId, projectId } = useGetRouteParams();

  const step = Number(searchParams.get('step'));

  const { data: dataset } = useGetDatasetById(
    {
      datasetId,
      orgId,
      projectId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const { mutateAsync: completeOnboarding } = useCompleteOnboarding();

  const onSubmit = async () => {
    if (!projectId || !orgId) {
      return;
    }

    const res = await completeOnboarding({
      projectId,
      orgId,
    });

    handleOnSubmit?.(res);
  };

  return (
    <div className="bg-white space-y-6">
      <ProjectReview editable={false} deletable={false} />
      <div className="flex flex-col gap-3">
        <h1 className="text-xl font-bold">Dataset</h1>
        <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
          <div className="flex">
            <p className="w-[20%]">Dataset Name:</p>
            <p className="w-full font-bold">{dataset?.name}</p>
          </div>
          <div className="flex">
            <p className="w-[20%]">Description:</p>
            <p className="w-full font-bold">{dataset?.description}</p>
          </div>
        </div>
      </div>
      <div>{dataset && <ReviewIFSFilesTabs dataset={dataset} />}</div>
      <div className="flex justify-end">
        <div className="flex gap-2">
          <Button
            variant="outlined"
            onClick={() => {
              searchParams.set('step', (step - 1).toString());
              setSearchParams(searchParams);
            }}
            style={{
              color: '#666',
              borderColor: '#CCC',
              fontWeight: 600,
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            style={{
              fontWeight: 600,
            }}
            onClick={onSubmit}
          >
            Finalize project
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectReviewConfirm;
