import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  type PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';

import { useGetProjects } from '../../hooks';
import {
  useGetOrgAuditLogs,
  useGetOrgMembers,
} from '../../hooks/useOrganizationHook';
import {
  useGetRouteParams,
  useGetSearchResults,
} from '../../hooks/useUtilsHook';
import cn from '../../utils/cn';
import FormSelect from '../FormSelect';
import CustomMenuDropdown from '../model/CustomMenuDropdown';
import SearchInput from '../SearchInput';
import Spinner from '../Spinner';

export type DataRow = {
  id: number;
  email: string;
  timestamp: string | null;
  action: string;
  description: string;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('email', {
    cell: (info) => {
      const email = info.getValue();

      return <p className="text-sm text-[#666]">{email}</p>;
    },
    header: () => (
      <div className="flex items-center gap-1">
        <p>User</p>
      </div>
    ),
  }),
  columnHelper.accessor('timestamp', {
    id: 'timestamp',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'Y/MM/dd h:mm aaa')}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-start">--</div>
      ),
    header: () => <div>Timestamp</div>,
  }),
  columnHelper.accessor('action', {
    id: 'action',
    cell: (info) => {
      const action = info.getValue();

      return <div className="uppercase">{action}</div>;
    },
    header: () => <div>Action</div>,
  }),
  columnHelper.accessor('description', {
    id: 'description',
    cell: (info) => {
      const description = info.getValue();

      return <div className="capitalize">{description}</div>;
    },
    header: () => <div>Description</div>,
  }),
];

const timestamps = [
  {
    value: '7d',
    label: '7 days ago',
  },
  {
    value: '30d',
    label: '1 months ago',
  },
  {
    value: '90d',
    label: '3 months ago',
  },
];

const actions = [
  {
    value: 'create',
    label: 'CREATE',
  },
  {
    value: 'update',
    label: 'UPDATE',
  },
  {
    value: 'delete',
    label: 'DELETE',
  },
];

const AuditLogView = () => {
  const [data, setData] = useState<DataRow[]>([]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const form = useForm();

  const { watch } = form;

  const { orgId } = useGetRouteParams();

  const searchResults = useGetSearchResults<DataRow>({
    searchValue: watch('search'),
    data,
    filterFields: ['action', 'actor', 'object_repr', 'changes_str'],
  });

  const timestamp = watch('timestamp');
  const action = watch('action');
  const actor = watch('users');

  const { data: auditLogs, isLoading: isFetchingAuditLogs } =
    useGetOrgAuditLogs(
      {
        orgId,
        params: {
          actor: actor
            ? Object.keys(actor)
                .filter((key) => actor[key])
                .join(',')
            : '',
          action: action
            ? Object.keys(action)
                .filter((key) => action[key])
                .flatMap((a) => a.toUpperCase())
                .join(',')
            : '',
          timestamp: timestamp ?? '',
        },
      },
      {
        enabled: !!orgId,
      }
    );

  const { data: members } = useGetOrgMembers(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const { data: projects } = useGetProjects(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const orgUsers =
    members?.map((member) => ({
      label: member.user.full_name,
      value: member.user.id,
    })) ?? [];

  const orgProjects =
    projects?.map((project) => ({
      label: project.name,
      value: project.id,
    })) ?? [];

  useEffect(() => {
    const dataRows =
      auditLogs?.map((auditLog) => ({
        id: auditLog.id,
        email: auditLog.actor.email,
        timestamp: auditLog.timestamp,
        action: auditLog.action,
        description: auditLog.object_repr + auditLog.changes_str,
      })) ?? [];

    setData(dataRows);
  }, [auditLogs]);

  const table = useReactTable({
    data: searchResults ?? data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    autoResetPageIndex: false,
    state: {
      pagination,
    },
  });

  const pageCount = table.getPageCount();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    table.setPageIndex(value - 1);
  };

  return (
    <div className="mx-auto flex max-w-lg 3xl:max-w-2xl flex-col space-y-6 py-6">
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-bold text-[#333]">Audit Log</h1>
        </div>
      </div>
      <div className="w-full">
        <FormProvider {...form}>
          <form className="flex flex-col">
            <div className="flex gap-3 flex-wrap">
              <div className="w-full max-w-[300px] ">
                <SearchInput />
              </div>
              {orgUsers && (
                <CustomMenuDropdown
                  name={'users'}
                  label={'User'}
                  data={orgUsers}
                />
              )}
              {/* <CustomMenuDropdown
                name={'projects'}
                label={'Project'}
                data={orgProjects}
              /> */}
              <div>
                <FormSelect
                  name={'timestamp'}
                  placeholder={'Timestamp'}
                  defaultValue={'7d'}
                  options={timestamps}
                />
              </div>
              {actions && (
                <CustomMenuDropdown
                  name={'action'}
                  label={'Action'}
                  data={actions}
                />
              )}
              <div />
            </div>
            <div className="py-4">
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      className="!rounded-md bg-[#F8F8F8]"
                    >
                      {headerGroup.headers.map((header, index) => (
                        <th
                          key={header.id}
                          className={cn(
                            'first:border-l last:border-r border-t border-b px-6 py-2 text-start text-sm text-[#4D4D4D]',
                            {
                              'w-[45%]': index === 0,
                            }
                          )}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="relative">
                  {isFetchingAuditLogs && (
                    <div className="absolute border-[#E4E7EC] h-full border flex w-full items-center justify-center opacity-50">
                      <Spinner />
                    </div>
                  )}
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="cursor-pointer px-6 py-3">
                      {row.getVisibleCells().map((cell, index) => (
                        <td
                          key={cell.id}
                          className={cn(
                            'first:border-l last:border-r border-b border-[#E4E7EC] px-6 text-[#333]',
                            {
                              'w-[45%]': index === 0,
                            }
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex items-center px-4 py-2 text-[#666] w-full gap-2 justify-between border-t-0 border border-gray-300">
                <div>
                  Showing {table.getRowModel().rows.length.toLocaleString()} of{' '}
                  {table.getRowCount().toLocaleString()} Rows
                </div>
                <div className="flex items-center">
                  <button
                    className="px-3"
                    type="button"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <ChevronLeft size={20} />
                  </button>
                  <Stack spacing={2}>
                    <Pagination
                      shape="rounded"
                      count={pageCount}
                      page={pagination.pageIndex + 1}
                      onChange={handleChange}
                      hidePrevButton
                      hideNextButton
                      sx={{
                        '& .Mui-focusVisible': {
                          backgroundColor: '#FFFAF9 !important',
                          color: '#B8341B',
                        },
                        '& .Mui-selected': {
                          backgroundColor: '#FFFAF9 !important',
                          color: '#B8341B',
                        },
                      }}
                    />
                  </Stack>
                  <button
                    className="px-3"
                    type="button"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    <ChevronRight size={20} />
                  </button>
                </div>
              </div>
            </div>
            {!table.getRowModel().rows.length && (
              <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
                <p className="text-lg text-[#999]">
                  No users available. Please invite a user.
                </p>
              </div>
            )}
          </form>
        </FormProvider>
        <div className="h-4" />
      </div>
    </div>
  );
};

export default AuditLogView;
