import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ArrowUpward } from '@mui/icons-material';
import {
  Avatar,
  Button,
  MenuItem,
  Select as MuiSelect,
  type SelectChangeEvent,
  styled,
} from '@mui/material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import _ from 'lodash';
import { ChevronLeft, Plus } from 'lucide-react';

import { type TypeMember, type TypeProject } from '../common/dataTypes';
import { OrganizationRolesOptions, ProjectRolesOptions } from '../common/utils';
import { useGetOrgMemberById } from '../hooks/useOrganizationHook';
import { useUpdateProjectMember } from '../hooks/useProjectsHook';
import { useGetUser } from '../hooks/useUserHook';
import {
  isOrgAdmin,
  isOrgSuperAdmin,
  useCustomNavigate,
  useGetRouteParams,
} from '../hooks/useUtilsHook';
import routes from '../routes';
import { type OrganizationRole, type Role } from '../types';
import cn from '../utils/cn';
import { useCurrentOrganization } from '../utils/helpers';

import TransferOwnershipDialog from './organizations/TransferOwnershipDialog';
import InviteProjectFormDialog from './projects/InviteProjectFormDialog';
import ConfirmDeleteUserDialog from './userManagement/ConfirmDeleteUserDialog';
import ConfirmLeaveProjectDialog from './userManagement/ConfirmLeaveProjectDialog';
import ConfirmTransferDialog from './ConfirmTransferDialog';
import TanstackTable from './TanstackTable';

export type DataRow = {
  id: number;
  name: string;
  lastActive: string | null;
  role: 'project_member' | 'project_owner' | 'Unknown';
};

export type User = {
  id: number;
  userId: number;
  role: OrganizationRole;
  first_name?: string;
  last_name?: string;
  profile_pic?: string;
  updated_at: string;
  email: string;
  projects: TypeProject[];
};

const columnHelper = createColumnHelper<DataRow>();

const Select = styled(MuiSelect)({
  padding: '0px !important',
  fontSize: '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
    padding: '0px !important',
  },

  '& .MuiSelect-select': {
    backgroundColor: '#F8F8F8',
    paddingLeft: '10px !important',
    paddingRight: '60px !important',
  },
  '& .MuiSelect-nativeInput': {},
});

const columns = (user: User) => [
  columnHelper.accessor('name', {
    cell: (info) => (
      <div className="group flex items-center gap-2 py-3 text-start">
        <p className="w-full">{info.getValue()}</p>
      </div>
    ),
    header: () => (
      <div className="items-centert flex gap-2">
        <p>Project</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('lastActive', {
    id: 'lastActive',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'Y/MM/dd h:mm a')}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-start">--</div>
      ),
    header: () => <div>Last active</div>,
  }),
  columnHelper.accessor('role', {
    id: 'role',
    cell: (info) => {
      const currentRole = info.getValue();
      const project = info.row.original;

      const [searchParams] = useSearchParams();

      const { userId, orgId } = useGetRouteParams();

      const [role, setRole] = useState<Role>(currentRole);
      const { data: currentUser } = useGetUser();

      const currentOrg = useCurrentOrganization();

      const type = searchParams.get('type');

      const { data: orgMember } = useGetOrgMemberById(
        {
          orgId,
          memberId: userId,
        },
        {
          enabled: !!userId && !!orgId,
        }
      );

      const projectMember = orgMember?.project_memberships.find(
        (p) => p.project.id === project.id
      );

      useEffect(() => {
        if (currentRole) {
          setRole(currentRole);
        }
      }, [currentRole]);

      const { mutate: updateProjectMember } = useUpdateProjectMember();

      if (!info.getValue()) {
        return <div className="text-gray-400 w-full text-start">--</div>;
      }

      const handleChangeRole = (event: SelectChangeEvent<unknown>) => {
        if (!currentOrg) {
          return;
        }

        const selectedRole = event.target.value as Role;

        if (projectMember) {
          updateProjectMember({
            orgId,
            projectId: project.id,
            memberId: projectMember.id,
            data: {
              role: selectedRole,
            },
          });
        }

        setRole(selectedRole);
      };

      const currentOrgMember = useCurrentOrganization();

      const isAllowEdit =
        currentOrgMember && currentUser?.id !== user.userId
          ? (isOrgAdmin(currentOrgMember.role) &&
              !isOrgSuperAdmin(user.role)) ||
            isOrgSuperAdmin(currentOrgMember.role)
          : false;

      const isAuthenticated =
        currentUser?.id !== user.userId
          ? currentOrgMember &&
            (isOrgSuperAdmin(currentOrgMember.role) ||
              (isOrgAdmin(currentOrgMember.role) &&
                !isOrgSuperAdmin(user.role)))
          : true;

      return (
        <div className="w-full flex justify-between">
          <div className="max-w-[200px] w-full capitalize">
            {!isAllowEdit ? (
              <div className="bg-[#F8F8F8] px-4 py-1 rounded-[4px] text-[#333333]">
                {ProjectRolesOptions.find((p) => p.value === role)?.label}
              </div>
            ) : (
              <Select
                id="role"
                size="small"
                value={role}
                fullWidth
                onChange={handleChangeRole}
                className="bottom-0"
                placeholder="Please select"
              >
                {ProjectRolesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <span className="capitalize">{option.label}</span>
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
          {projectMember && orgMember && isAuthenticated && (
            <ConfirmLeaveProjectDialog
              project={project}
              user={{
                id: projectMember.id,
                email: orgMember.user.email,
                type: 'member',
              }}
            />
          )}
        </div>
      );
    },
    header: () => <div>Project roles</div>,
  }),
];

const UserAccountDetails = () => {
  const [data, setData] = useState<DataRow[]>([]);
  const [selectedMember, setSelectedMember] = useState<TypeMember | null>(null);
  const [openInviteFormDialog, setOpenInviteFormDialog] = useState(false);

  const [openConfirmTransferDialog, setOpenConfirmTransferDialog] =
    useState(false);

  const [openTransferOwnershipDialog, setOpenTransferOwnershipDialog] =
    useState(false);

  const navigate = useCustomNavigate();

  const { userId, orgId } = useGetRouteParams();

  const {
    data: member,
    isLoading: isFetchingOrgMember,
    isRefetching: isRefetchingOrgMember,
  } = useGetOrgMemberById(
    {
      orgId,
      memberId: userId,
    },
    {
      enabled: !!userId && !!orgId,
    }
  );

  const { data: currentUser } = useGetUser();

  const currentOrgMember = currentUser?.organizations.find(
    (o) => o.id === orgId
  );

  const user = useMemo(
    () =>
      member &&
      ({
        ..._.omit(member.user, ['id', 'role', 'projects']),
        id: member.id,
        userId: member.user.id,
        role: member.role,
        projects: member.project_memberships.map((p) => ({
          ...p.project,
          id: p.project.id,
          role: p.role,
        })),
        updated_at: member.updated_at,
      } as User),
    [member]
  );

  useEffect(() => {
    if (user) {
      setData(
        user.projects.map((project) => ({
          id: project.id,
          name: project.name,
          lastActive: project.updated_at,
          role: project.role,
        }))
      );
    }
  }, [user]);

  const table = useReactTable({
    data,
    columns: columns(user ?? (member as unknown as User)),
    getCoreRowModel: getCoreRowModel(),
  });

  const handleGoBack = () => {
    navigate(routes.userManagement.index, { org_id: orgId.toString() });
  };

  const handleOpenInviteFormDialog = () => {
    setOpenInviteFormDialog(true);
  };

  const handleCloseInviteFormDialog = () => {
    setOpenInviteFormDialog(false);
  };

  const handleOpenTransferOwnershipDialog = () => {
    setOpenTransferOwnershipDialog(true);
  };

  const handleCloseTransferOwnershipDialog = () => {
    setOpenTransferOwnershipDialog(false);
  };

  const handleOpenConfirmTransferDialog = () => {
    setOpenConfirmTransferDialog(true);
  };

  const handleCloseConfirmTransferDialog = () => {
    setOpenConfirmTransferDialog(false);
  };

  const isAllowEdit =
    currentOrgMember && user && currentUser?.id !== user.userId
      ? (isOrgAdmin(currentOrgMember.role) && !isOrgSuperAdmin(user.role)) ||
        isOrgSuperAdmin(currentOrgMember.role)
      : false;

  const isAuthenticated = currentOrgMember?.role !== 'organization_member';

  return (
    <div className="mx-auto flex max-w-lg flex-col gap-4 py-6">
      <button onClick={handleGoBack} className="flex items-center gap-2">
        <ChevronLeft />
        {user && (
          <p className="text-[28px] font-bold">
            {`${user.first_name} ${user.last_name}`}
          </p>
        )}
        <p
          className={cn(
            'flex items-center rounded-full px-3 py-1 text-xs font-bold uppercase',
            {
              'bg-[#FFE8B9]': user?.role === 'organization_admin',
              'bg-[#E8F2FF]': user?.role === 'organization_owner',
            }
          )}
        >
          {user &&
            isOrgAdmin(user.role as OrganizationRole) &&
            OrganizationRolesOptions.find((p) => p.value === user.role)?.label}
        </p>
      </button>
      <div className="flex gap-6">
        <div className="bg-white w-full space-y-3 pt-2">
          {user?.updated_at && (
            <p className="text-sm font-semibold text-[#666]">
              Last active on{' '}
              {format(user?.updated_at ?? ('' as string), 'Y/MM/dd h:mm a')}
            </p>
          )}
          <div className="flex w-full justify-between">
            <div className="flex flex-col gap-3 rounded-[4px] border border-[#DBE7F6] p-4">
              <div className="flex gap-3">
                <Avatar
                  src={user?.profile_pic}
                  sx={{
                    width: 70,
                    height: 70,
                  }}
                  alt="member avatar"
                />
                <div className="flex flex-col justify-center">
                  <p className="font-semibold">
                    {user?.first_name} {user?.last_name}
                  </p>
                  <p className="text-sm">{user?.email}</p>
                </div>
              </div>
              {user?.role === 'organization_owner' &&
                user.userId === currentUser?.id && (
                  <div className="w-full">
                    <Button
                      style={{
                        borderColor: '#B8341B',
                        color: '#B8341B',
                      }}
                      type="button"
                      onClick={handleOpenTransferOwnershipDialog}
                      variant="outlined"
                      fullWidth
                    >
                      Transfer ownership
                    </Button>
                    <TransferOwnershipDialog
                      open={openTransferOwnershipDialog}
                      handleCancelDialog={handleCloseTransferOwnershipDialog}
                      handleSubmitDialog={(member) => {
                        setSelectedMember(member);
                        handleCloseTransferOwnershipDialog();
                        handleOpenConfirmTransferDialog();
                      }}
                    />
                  </div>
                )}
            </div>
            <div>
              {' '}
              {user && isAllowEdit && isAuthenticated ? (
                <ConfirmDeleteUserDialog
                  trigger={{
                    className: 'text-primary-red',
                  }}
                  user={user}
                  level={'organization'}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div />
      </div>
      <div className="flex grow flex-col gap-3">
        <div className="flex justify-between items-center">
          <p className="text-xl font-semibold">Project access</p>
          {user && user.userId !== currentUser?.id && isAuthenticated && (
            <InviteProjectFormDialog
              iUser={{
                id: user.userId,
                email: user.email,
                fullName: `${user.first_name} ${user.last_name}`,
              }}
              inviteUserMode="single"
              trigger={{
                label: (
                  <div className="flex gap-1 items-center">
                    <Plus size={20} />
                    <p>Add to project(s)</p>
                  </div>
                ),
              }}
              open={openInviteFormDialog}
              handleClose={handleCloseInviteFormDialog}
              handleOpen={handleOpenInviteFormDialog}
            />
          )}
        </div>
        <TanstackTable
          table={table}
          isLoading={isFetchingOrgMember || isRefetchingOrgMember}
          emptyText="No available projects."
        />
      </div>
      {selectedMember && (
        <ConfirmTransferDialog
          open={openConfirmTransferDialog}
          handleClose={handleCloseConfirmTransferDialog}
          user={{
            id: selectedMember.user.id,
            name: selectedMember.user.full_name,
            email: selectedMember.user.email,
          }}
        />
      )}
    </div>
  );
};

export default UserAccountDetails;
