import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Button, Dialog } from '@mui/material';
import { Trash2 } from 'lucide-react';

import { toastPromise } from '../../common/utils';
import { useGetOrgMemberById } from '../../hooks/useOrganizationHook';
import {
  useDeleteProjectInvitation,
  useDeleteProjectMemberById,
  useLeaveProject,
} from '../../hooks/useProjectsHook';
import { useGetUser } from '../../hooks/useUserHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { type OrganizationStatus } from '../../types';

type ConfirmDeleteProjectDialogProps = {
  user: {
    id: number;
    email: string;
    type: OrganizationStatus;
  };
  project: {
    id: number;
    name: string;
  };
};

const ConfirmLeaveProjectDialog = ({
  user,
  project,
}: ConfirmDeleteProjectDialogProps) => {
  const [open, setOpen] = useState(false);
  const { orgId } = useGetRouteParams();

  const navigate = useNavigate();

  const type = user.type;
  const userId = user.id;
  const projectId = project.id;

  const {
    mutateAsync: deleteProjectMember,
    isPending: isDeletingProjectMember,
  } = useDeleteProjectMemberById({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const {
    mutateAsync: deleteProjectInvitation,
    isPending: isDeletingProjectInvitation,
  } = useDeleteProjectInvitation({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const { mutateAsync: leaveProject, isPending: isLeavingProject } =
    useLeaveProject({
      onSuccess: () => {
        navigate(`/${orgId}/projects`);
        setOpen(false);
      },
    });

  const { data: member } = useGetOrgMemberById(
    {
      orgId,
      memberId: userId,
    },
    {
      enabled: !!userId && !!orgId && type === 'member',
    }
  );

  const { data: currentUser } = useGetUser();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    if (!orgId || !currentUser || !user) {
      return;
    }

    let res = null;

    if (type === 'member') {
      if (currentUser.email === user.email) {
        res = leaveProject({
          orgId,
          projectId: project.id,
        });

        toastPromise({
          promise: res,
          content: 'Leave project successfully',
        });
        return;
      }
      res = deleteProjectMember({
        orgId,
        projectId,
        memberId: user.id,
      });

      toastPromise({
        promise: res,
        content: 'User has been deleted successfully',
      });
    }

    if (type === 'invitation') {
      res = deleteProjectInvitation({
        orgId,
        projectId,
        invitationId: user.id,
      });

      toastPromise({
        promise: res,
        content: 'Invitation has been deleted successfully',
      });
    }
  };

  return (
    <>
      <button type="button" onClick={handleOpen}>
        {currentUser?.id === member?.user.id ||
        currentUser?.email === user.email ? (
          <p className="text-primary-red">Leave</p>
        ) : (
          <Trash2 color="#666666" width={20} height={20} />
        )}
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alertTitle"
        aria-describedby="alertContent"
      >
        <div className="flex flex-col gap-6 p-6">
          <h2 className="text-xl font-bold">
            {member?.user.id === currentUser?.id ||
            currentUser?.email === user.email
              ? `Are you sure you want to leave ${project.name} project?`
              : `Are you sure you want to remove ${user.email} from ${project.name} project?`}
          </h2>
          <div className="flex justify-end gap-3">
            <Button
              onClick={handleClose}
              variant="outlined"
              tabIndex={3}
              style={{
                color: '#666',
                borderColor: '#B3B3B3',
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSubmit}
              tabIndex={2}
              disabled={isDeletingProjectMember || isLeavingProject}
              loading={isDeletingProjectMember || isLeavingProject}
              variant="contained"
              style={{
                backgroundColor: '#B8341B',
                color: '#FFF',
              }}
            >
              Yes
            </LoadingButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmLeaveProjectDialog;
