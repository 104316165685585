import React, { useEffect, useState } from 'react';

import { ArrowUpward } from '@mui/icons-material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import { useGetOrgSharedRequests } from '../../../hooks/useOrganizationHook';
import { isOrgAdmin, useGetSearchResults } from '../../../hooks/useUtilsHook';
import { useCurrentOrganization } from '../../../utils/helpers';
import TanstackTable from '../../TanstackTable';

import MemberApprovalListDialog from './MemberApprovalListDialog';

export type DataRow = {
  id: number;
  name: string;
  project: string;
  requester: string;
  approvedReceivers: string;
  createdAt: string;
  action?: React.ReactNode;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('name', {
    cell: (info) => (
      <div className="group flex items-center gap-2 py-3 text-start">
        <p className="w-full">{info.getValue()}</p>
      </div>
    ),
    header: () => (
      <div className="items-centert flex gap-2">
        <p>Model Name</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('project', {
    id: 'project',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      ),
    header: () => <div>Project</div>,
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'Y/MM/dd h:mm a')}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      ),
    header: () => <div>Date Requested</div>,
  }),
  columnHelper.accessor('approvedReceivers', {
    id: 'approvedReceivers',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      ),
    header: () => <div>No. of Receivers</div>,
  }),
  columnHelper.accessor('action', {
    id: 'action',
    cell: (info) => {
      const id = info.row.original.id;

      return (
        <div className="w-full">
          <MemberApprovalListDialog id={id} />
        </div>
      );
    },
    header: () => <div>Action</div>,
  }),
];

const SentRequestsTable = ({ searchKey }: { searchKey: string }) => {
  const [data, setData] = useState<DataRow[]>([]);

  const currentOrg = useCurrentOrganization();

  const {
    data: sharedRequests,
    isLoading: isFetchingOrgSharedRequests,
    isFetching: isRefetchingOrgSharedRequests,
  } = useGetOrgSharedRequests(currentOrg?.id ?? NaN, {
    enabled: !!currentOrg?.id && currentOrg && isOrgAdmin(currentOrg.role),
  });

  const searchResults = useGetSearchResults<DataRow>({
    searchValue: searchKey,
    data,
    filterFields: ['project', 'name', 'requester'],
  });

  useEffect(() => {
    if (sharedRequests) {
      setData(
        sharedRequests.map((r) => {
          const approvedReceivers = r.sharing_invitations.filter(
            (i) => i.accept_status === 'Accepted'
          );

          return {
            id: r.id,
            name: r.model.name,
            project: r.project.name,
            approvedReceivers: `${approvedReceivers.length} of ${r.sharing_invitations.length}`,
            createdAt: format(r.updated_at, 'Y/MM/dd h:mm a'),
          };
        }) as DataRow[]
      );
    }
  }, [sharedRequests]);

  const table = useReactTable({
    data: searchResults ?? data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TanstackTable
      table={table}
      isLoading={isFetchingOrgSharedRequests || isRefetchingOrgSharedRequests}
      emptyText={'No sharing shared requests.'}
    />
  );
};

export default SentRequestsTable;
