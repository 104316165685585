import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  createProject,
  deleteProject,
  getProjectById,
  getProjectMembers,
  getProjects,
} from '../api';
import {
  type TypeProject,
  type TypeProjectInvitation,
  type TypeProjectMember,
  type TypeProjectOutputSchema,
} from '../common/dataTypes';

import {
  type CompleteCreateProjectInput,
  completeOnboarding,
  type CreateProjectInput,
  createProjectMember,
  type CreateProjectMemberInput,
  type DeleteProjectInput,
  deleteProjectInvitation,
  type DeleteProjectInvitationInput,
  deleteProjectMember,
  type DeleteProjectMemberInput,
  type GetProjectByIdInput,
  getProjectInvitationById,
  type GetProjectInvitationByIdInput,
  getProjectInvitations,
  type GetProjectInvitationsInput,
  type GetProjectMembersInput,
  getProjectOutputSchema,
  type GetProjectOutputSchemaInput,
  type GetProjectsInput,
  joinProject,
  type JoinProjectInput,
  leaveProject,
  resendProjectInvitation,
  type ResendProjectInvitationInput,
  updateProject,
  type UpdateProjectInput,
  updateProjectInvitation,
  type UpdateProjectInvitationInput,
  updateProjectMember,
  type UpdateProjectMemberInput,
  verifyProjectInvitation,
} from './../api/project';
import { type DefaultMutationError, type DefaultQueryError } from './index';

export const useGetProjects = (
  input: GetProjectsInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeProject[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getProjects', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeProject[]> = await getProjects(input);

      const result = data.sort((p1: TypeProject, p2: TypeProject) => {
        if (new Date(p1.created_at) > new Date(p2.created_at)) {
          return -1;
        } else {
          return 1;
        }
      });

      return result;
    },
    ...opts,
  });

export const useCreateProject = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeProject>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createProject'],
    mutationFn: async (input: CreateProjectInput) => {
      const { data: project }: AxiosResponse<TypeProject> =
        await createProject(input);

      return project;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useGetProjectById = (
  input: GetProjectByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeProject>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getProjectById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeProject> = await getProjectById(input);

      return data;
    },
    ...opts,
  });

export const useGetProjectOutputSchema = (
  input: GetProjectOutputSchemaInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeProjectOutputSchema>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getProjectOutputSchema', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeProjectOutputSchema> =
        await getProjectOutputSchema(input);

      return data;
    },
    ...opts,
  });

export const useDeleteProject = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteProject'],
    mutationFn: async (input: DeleteProjectInput) => {
      const { data }: AxiosResponse = await deleteProject(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useJoinProject = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['joinProject'],
    mutationFn: async (input: JoinProjectInput) => {
      const { data }: AxiosResponse = await joinProject(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjectById'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjectMembers'],
      });
      queryClient.refetchQueries({
        queryKey: ['getUser'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useLeaveProject = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['leaveProject'],
    mutationFn: async (input: JoinProjectInput) => {
      const { data }: AxiosResponse = await leaveProject(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getUser'],
      });
      queryClient.refetchQueries({
        queryKey: ['getOrgMemberById'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjectMembers'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useUpdateProject = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeProject>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateProject'],
    mutationFn: async (input: UpdateProjectInput) => {
      const { data }: AxiosResponse<TypeProject> = await updateProject(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjectById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useCompleteOnboarding = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeProject>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['completeOnboarding'],
    mutationFn: async (input: CompleteCreateProjectInput) => {
      const { data }: AxiosResponse<TypeProject> =
        await completeOnboarding(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjectById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetProjectMembers = (
  input: GetProjectMembersInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeProjectMember[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getProjectMembers', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeProjectMember[]> =
        await getProjectMembers(input);

      return data;
    },
    ...opts,
  });

export const useUpdateProjectMember = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateProjectMember'],
    mutationFn: async (input: UpdateProjectMemberInput) => {
      const { data }: AxiosResponse = await updateProjectMember(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ['getOrgMemberById', variables],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useDeleteProjectMemberById = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteProjectMemberById'],
    mutationFn: async (input: DeleteProjectMemberInput) => {
      const { data }: AxiosResponse = await deleteProjectMember(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjectMembers'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      queryClient.refetchQueries({
        queryKey: ['getOrgMemberById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetProjectInvitations = (
  input: GetProjectInvitationsInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeProjectInvitation[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getProjectInvitations', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeProjectInvitation[]> =
        await getProjectInvitations(input);

      return data;
    },
    ...opts,
  });

export const useGetProjectInvitationById = (
  input: GetProjectInvitationByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getProjectInvitationById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<[]> = await getProjectInvitationById(input);

      return data;
    },
    ...opts,
  });

export const useCreateProjectMember = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createProjectMember'],
    mutationFn: async (input: CreateProjectMemberInput) => {
      const { data }: AxiosResponse = await createProjectMember(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjectMembers'],
      });
      queryClient.refetchQueries({
        queryKey: ['getOrgMemberById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUpdateProjectInvitation = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateProjectInvitation'],
    mutationFn: async (input: UpdateProjectInvitationInput) => {
      const { data }: AxiosResponse = await updateProjectInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjectInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteProjectInvitation = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteProjectInvitation'],
    mutationFn: async (input: DeleteProjectInvitationInput) => {
      const { data }: AxiosResponse = await deleteProjectInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjectInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useResendProjectInvitation = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['resendProjectInvitation'],
    mutationFn: async (input: ResendProjectInvitationInput) => {
      const { data }: AxiosResponse = await resendProjectInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useVerifyProjectInvitation = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['verifyProjectInvitation'],
    mutationFn: async (input: ResendProjectInvitationInput) => {
      const { data }: AxiosResponse = await verifyProjectInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      opts?.onSuccess?.(data, variables, context);
    },
  });
};
