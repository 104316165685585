import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { type TypeOrgInvitation } from '../../common/dataTypes';
import {
  useAcceptReceivedInvitation,
  useGetOrgReceivedInvitations,
  useRejectReceivedInvitation,
} from '../../hooks/useOrganizationHook';
import routes from '../../routes';

const InvitationCard = ({ invitation }: { invitation: TypeOrgInvitation }) => {
  const navigate = useNavigate();

  const { mutate: acceptInvitation } = useAcceptReceivedInvitation({
    onSuccess() {
      navigate(
        routes.projects.index.replace(
          ':org_id',
          invitation.organization.id.toString()
        )
      );
      localStorage.delete('orgId');
    },
  });

  const { mutate: rejectInvitation } = useRejectReceivedInvitation({
    onSuccess() {
      localStorage.delete('orgId');
    },
  });

  const handleAcceptInvitation = () => {
    if (!invitation) {
      return;
    }

    acceptInvitation({
      orgId: invitation.organization.id,
      invitationId: invitation.id,
    });
  };

  const handleRejectInvitation = () => {
    if (!invitation) {
      return;
    }

    rejectInvitation({
      orgId: invitation.organization.id,
      invitationId: invitation.id,
    });
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex justify-between items-center w-full">
        <div>
          <p>
            {invitation.invited_by.full_name} invited you to{' '}
            <span className="font-bold">{invitation.organization.name}</span>
          </p>
        </div>
        <div className="flex gap-2">
          <Button
            size="small"
            variant="contained"
            onClick={handleRejectInvitation}
            style={{
              backgroundColor: '#E6E6E6',
              color: '#333333',
            }}
          >
            Decline
          </Button>
          <Button
            onClick={handleAcceptInvitation}
            size="small"
            variant="contained"
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};

const ViewAllInvitations = () => {
  const orgId = Number(localStorage.getItem('orgId')) || 1;

  const { data: invitations } = useGetOrgReceivedInvitations(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  return (
    <div className="mx-auto w-full max-w-lg px-12 lg:px-0">
      <div className="flex flex-col gap-6 py-6">
        <h1 className="text-2xl font-bold">
          {invitations?.length ?? 0} Invitations
        </h1>
        {invitations?.map((invitation) => (
          <InvitationCard key={invitation.id} invitation={invitation} />
        ))}
      </div>
    </div>
  );
};

export default ViewAllInvitations;
