import React, { type SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

import { type ModelType } from '../../../types';
import ModelComparisonView from '../../model/ModelComparisonView';
import {
  type StyledTabProps,
  type StyledTabsProps,
  type TabPanelProps,
} from '../../mui-custom';

import DefaultModelsTable from './DefaultModelsTable';
import ReceivedModelsTable from './ReceivedModelsTable';
import SharedModelsTable from './SharedModelsTable';

export const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  gap: '12px !important',
  backgroundColor: '#F5F9FF',

  '& .MuiTabs-indicator': {
    display: 'flex',
    gap: '12px !important',
    justifyContent: 'center',
    backgroundColor: '#F5F9FF',
  },

  '& .MuiTabs-flexContainer': {
    paddingLeft: '48px',
    gap: '24px',
  },

  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#01285F',
  },
});

export const Tab = styled(
  (
    props: StyledTabProps & {
      disabled?: boolean;
    }
  ) => <MuiTab disableRipple {...props} />
)({
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'none',
  padding: '0px !important',
  minWidth: 'fit-content !important',

  '&.MuiButtonBase-root': {
    padding: '0px !important',
  },
  '&.Mui-selected': {
    color: '#01285F',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

export const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="space-y-6 px-12 py-5"
      role="tabpanel"
      hidden={value !== index}
      id={value}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const TABS = ['Models', 'Shared', 'Received', 'Compare Models'];

enum TabsEnum {
  'default-models' = 0,
  'shared-models' = 1,
  'received-models' = 2,
  'compare-models' = 3,
}

const ProjectModelsManagement = () => {
  const [index, setIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get('type') as ModelType;
  const tab = type === 'shared' ? 'shared-models' : searchParams.get('tab');

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setIndex(newValue);

    if (newValue === 0) {
      searchParams.set('tab', 'default-models');
    } else if (newValue === 1) {
      searchParams.set('tab', 'shared-models');
    } else if (newValue === 2) {
      searchParams.set('tab', 'received-models');
    } else if (newValue === 3) {
      searchParams.set('tab', 'compare-models');
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!tab) {
      setIndex(0);
      return;
    }

    setIndex((TabsEnum as unknown as Record<string, number>)[tab]);
  }, [tab]);

  return (
    <div className="text-2xl font-bold">
      <Tabs value={index} onChange={handleChange}>
        {TABS.map((report) => (
          <Tab id={report} key={report} label={report} />
        ))}
      </Tabs>
      <CustomTabPanel value={index.toString()} index="0">
        <DefaultModelsTable />
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="1">
        <SharedModelsTable />
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="2">
        <ReceivedModelsTable />
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="3">
        <ModelComparisonView />
      </CustomTabPanel>
    </div>
  );
};

export default ProjectModelsManagement;
