import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  createOrganization,
  getOrganizationById,
  getOrganizations,
} from '../api';
import {
  acceptOrgReceivedInvitation,
  type AcceptOrgReceivedInvitationInput,
  approveSharedInvitation,
  type ApproveSharedRequestInput,
  createMember,
  type CreateOrganizationInput,
  type CreateOrganizationMemberInput,
  createOrgInvitation,
  type CreateOrgInvitationInput,
  deleteMemberById,
  type DeleteMemberByIdInput,
  deleteOrgInvitationById,
  type DeleteOrgInvitationByIdInput,
  disapproveSharedInvitation,
  type DisapproveSharedRequestInput,
  getOrganizationAuditLogs,
  type GetOrganizationAuditLogsInput,
  type GetOrganizationByIdInput,
  getOrganizationInvitations,
  type GetOrganizationInvitationsInput,
  getOrgInvitationById,
  type GetOrgInvitationByIdInput,
  getOrgMemberById,
  type GetOrgMemberByIdInput,
  getOrgMembers,
  type GetOrgMembersInput,
  getOrgReceivedInvitations,
  type GetOrgReceivedInvitationsInput,
  getSharedRequestById,
  type GetSharedRequestByIdInput,
  getSharedRequests,
  inviteGuestOrgMember,
  type InviteGuestOrgMemberInput,
  leaveOrganization,
  type LeaveOrganizationInput,
  rejectOrgReceivedInvitation,
  type RejectOrgReceivedInvitationInput,
  resendInvitation,
  type ResendInvitationInput,
  transferOrganization,
  type TransferOrgOwnershipInput,
  updateInvitation,
  updateMember,
  type UpdateOrganizationInvitationInput,
  type UpdateOrganizationMemberInput,
  verifyInvitation,
  type VerifyInvitationInput,
  verifySharingInvitation,
  type VerifySharingInvitationInput,
} from '../api/organization';
import {
  type TypeAuditLog,
  type TypeMember,
  type TypeOrganization,
  type TypeOrgInvitation,
  type TypeProject,
  type TypeSharedRequest,
  type TypeUser,
} from '../common/dataTypes';
import { type OrganizationRole, type ProjectRole } from '../types';

import { type DefaultMutationError, type DefaultQueryError } from './index';

export type Project = {
  id: number;
  member_count: number;
  created_at: string;
  updated_at: string;
  role: ProjectRole;
  name: string;
  description: string;
  organization: number;
};

export type Invitation = {
  id: number;
  email: string;
  role: OrganizationRole;
  expire_at: string;
  organization: TypeOrganization;
  is_new_user: boolean;
  invited_by: TypeUser;
  updated_at: string;
  projects: Project[];
};

type InviteMemberResponse = {
  id: number;
  name: string;
  description: string;
  member_count: number;
  project_count: number;
  members: TypeUser[];
  projects: TypeProject[];
};

export const useGetOrganizations = (
  opts?: Partial<
    UseQueryOptions<Awaited<TypeOrganization[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getOrganizations'],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeOrganization[]> =
        await getOrganizations();

      const result = data.sort((p1: TypeOrganization, p2: TypeOrganization) => {
        if (new Date(p1.created_at) > new Date(p2.created_at)) {
          return -1;
        } else {
          return 1;
        }
      });

      return result;
    },
    ...opts,
  });

export const useGetOrgInvitations = (
  input: GetOrganizationInvitationsInput,
  opts?: Partial<UseQueryOptions<Awaited<Invitation[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrgInvitations', input],
    queryFn: async () => {
      const { data }: AxiosResponse<Invitation[]> =
        await getOrganizationInvitations(input);

      return data;
    },
    ...opts,
  });

export const useGetOrgInvitationById = (
  input: GetOrgInvitationByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<Invitation>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrgInvitationById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<Invitation> =
        await getOrgInvitationById(input);

      return data;
    },
    ...opts,
  });

export const useGetOrgMemberById = (
  input: GetOrgMemberByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeMember>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrgMemberById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeMember> = await getOrgMemberById(input);

      return data;
    },
    ...opts,
  });

export const useCreateOrgInvitation = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<InviteMemberResponse>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createOrgInvitation'],
    mutationFn: async (input: CreateOrgInvitationInput) => {
      const { data }: AxiosResponse<InviteMemberResponse> =
        await createOrgInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getOrgInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useCreateOrganizationMember = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<InviteMemberResponse>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['createMember'],
    mutationFn: async (input: CreateOrganizationMemberInput) => {
      const { data }: AxiosResponse<InviteMemberResponse> =
        await createMember(input);

      return data;
    },
    ...opts,
  });

export const useVerifyInvitation = (
  opts?: Partial<
    UseMutationOptions<Awaited<Invitation>, DefaultMutationError, any, any>
  >
) =>
  useMutation({
    mutationKey: ['verifyInvitation'],
    mutationFn: async (input: VerifyInvitationInput) => {
      const { data }: AxiosResponse<Invitation> = await verifyInvitation(input);

      return data;
    },
    ...opts,
  });

export const useResendInvitation = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) =>
  useMutation({
    mutationKey: ['resendInvitation'],
    mutationFn: async (input: ResendInvitationInput) => {
      const { data }: AxiosResponse<AxiosResponse> =
        await resendInvitation(input);

      return data;
    },
    ...opts,
  });

export const useUpdateOrgMember = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeMember>, DefaultMutationError, any, any>
  >
) =>
  useMutation({
    mutationKey: ['updateOrgMember'],
    mutationFn: async (input: UpdateOrganizationMemberInput) => {
      const { data }: AxiosResponse<TypeMember> = await updateMember(input);

      return data;
    },
    ...opts,
  });

export const useUpdateOrgInvitation = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<TypeOrgInvitation>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['updateOrgInvitation'],
    mutationFn: async (input: UpdateOrganizationInvitationInput) => {
      const { data }: AxiosResponse<TypeOrgInvitation> =
        await updateInvitation(input);

      return data;
    },
    ...opts,
  });

export const useDeleteInvitationById = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteInvitationById'],
    mutationFn: async (input: DeleteOrgInvitationByIdInput) => {
      const { data }: AxiosResponse = await deleteOrgInvitationById(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getOrgInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useGetOrgMembers = (
  input: GetOrgMembersInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeMember[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrgMembers', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeMember[]> = await getOrgMembers(input);

      return data;
    },
    ...opts,
  });

export const useGetOrgReceivedInvitations = (
  input: GetOrgReceivedInvitationsInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeOrgInvitation[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getOrgReceivedInvitations', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeOrgInvitation[]> =
        await getOrgReceivedInvitations(input);

      return data;
    },
    ...opts,
  });

export const useDeleteOrgMemberById = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteOrgMemberById'],
    mutationFn: async (input: DeleteMemberByIdInput) => {
      const { data }: AxiosResponse = await deleteMemberById(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getOrgMembers'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCreateOrganization = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['createOrganization'],
    mutationFn: async (input: CreateOrganizationInput) => {
      const {
        data: project,
      }: AxiosResponse<{
        id: string;
      }> = await createOrganization(input);

      return project;
    },
    ...opts,
  });

export const useLeaveOrganization = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['leaveOrganization'],
    mutationFn: async (input: LeaveOrganizationInput) => {
      const {
        data: project,
      }: AxiosResponse<{
        id: string;
      }> = await leaveOrganization(input);

      return project;
    },
    ...opts,
  });

export const useTransferOrgOwnership = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['transferOrgOwnership'],
    mutationFn: async (input: TransferOrgOwnershipInput) => {
      const {
        data: project,
      }: AxiosResponse<{
        id: string;
      }> = await transferOrganization(input);

      return project;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getOrgMembers'],
      });
      queryClient.refetchQueries({
        queryKey: ['getOrgMemberById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetOrganizationById = (
  input: GetOrganizationByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeOrganization>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrganizationById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeOrganization> =
        await getOrganizationById(input);

      return data;
    },
    ...opts,
  });

export const useGetOrgSharedRequests = (
  id: number,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeSharedRequest[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getSharedRequests', id],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeSharedRequest[]> =
        await getSharedRequests(id);

      return data;
    },
    ...opts,
  });

export const useGetOrgSharedRequestById = (
  input: GetSharedRequestByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeSharedRequest>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getSharedRequestById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeSharedRequest> =
        await getSharedRequestById(input);

      return data;
    },
    ...opts,
  });

export const useGetOrgAuditLogs = (
  input: GetOrganizationAuditLogsInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeAuditLog[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrgAuditLogs', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeAuditLog[]> =
        await getOrganizationAuditLogs(input);

      return data;
    },
    ...opts,
  });

export const useAcceptReceivedInvitation = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['acceptOrgReceivedInvitation'],
    mutationFn: async (input: AcceptOrgReceivedInvitationInput) => {
      const { data }: AxiosResponse = await acceptOrgReceivedInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getOrganizations'],
      });
      queryClient.refetchQueries({
        queryKey: ['getOrgReceivedInvitations'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      queryClient.refetchQueries({
        queryKey: ['getUser'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useRejectReceivedInvitation = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['rejectOrgReceivedInvitation'],
    mutationFn: async (input: RejectOrgReceivedInvitationInput) => {
      const { data }: AxiosResponse = await rejectOrgReceivedInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getOrgReceivedInvitationsInput'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useApproveSharedRequest = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['approveSharedRequest'],
    mutationFn: async (input: ApproveSharedRequestInput) => {
      const { data }: AxiosResponse = await approveSharedInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getSharedRequests'],
      });
      queryClient.refetchQueries({
        queryKey: ['getSentInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useDisapproveSharedRequest = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['disapproveSharedRequest'],
    mutationFn: async (input: DisapproveSharedRequestInput) => {
      const { data }: AxiosResponse = await disapproveSharedInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getSharedRequests'],
      });
      queryClient.refetchQueries({
        queryKey: ['getSentInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useVerifySharingInvitation = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['verifySharingInvitation'],
    mutationFn: async (input: VerifySharingInvitationInput) => {
      const { data }: AxiosResponse = await verifySharingInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useInviteGuestOrgMember = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['inviteGuestOrgMember'],
    mutationFn: async (input: InviteGuestOrgMemberInput) => {
      const { data }: AxiosResponse = await inviteGuestOrgMember(input);

      return data;
    },
    onSuccess(data, variables, context) {
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};
