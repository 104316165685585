import React, { useState } from 'react';

import { Button, Dialog } from '@mui/material';
import { Trash2 } from 'lucide-react';

import { toastPromise } from '../../common/utils';
import {
  useDeleteInvitationById,
  useDeleteOrgMemberById,
  useGetOrganizationById,
} from '../../hooks/useOrganizationHook';
import {
  useDeleteProjectMemberById,
  useLeaveProject,
} from '../../hooks/useProjectsHook';
import { useGetUser } from '../../hooks/useUserHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { type OrganizationStatus } from '../../types';

type UserType = {
  avatarImage?: string;
  email: string;
  id: number;
  name?: string | undefined | null;
  type?: OrganizationStatus;
};

type ConfirmDeleteUserDialogProps = {
  user: UserType;
  level: 'project' | 'organization';
  trigger?: {
    label?: React.ReactNode;
    className?: React.HTMLAttributes<HTMLButtonElement>['className'];
  };
};

const ConfirmDeleteUserDialog = ({
  user,
  trigger,
  level,
}: ConfirmDeleteUserDialogProps) => {
  const [open, setOpen] = useState(false);

  const { orgId, projectId } = useGetRouteParams();

  const { data: currentOrg } = useGetOrganizationById(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const { data: currentUser } = useGetUser();

  const { mutateAsync: deleteOrgMemberById } = useDeleteOrgMemberById({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const { mutateAsync: leaveProject } = useLeaveProject({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const { mutateAsync: deleteProjectMemberById } = useDeleteProjectMemberById({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const { mutateAsync: deleteInvitationById } = useDeleteInvitationById({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    if (!orgId || !user) {
      return;
    }

    let res;

    if (user.type === 'member') {
      if (level === 'project') {
        if (currentUser?.email === user.email) {
          res = leaveProject({
            orgId,
            projectId,
          });
          return;
        }
        res = deleteProjectMemberById({
          orgId,
          projectId,
          memberId: user.id,
        });
        return;
      }

      res = deleteOrgMemberById({
        orgId,
        userId: user.id,
      });
    } else if (user.type === 'invitation') {
      res = deleteInvitationById({
        orgId,
        invitationId: user.id,
      });
    }

    if (res) {
      toastPromise({
        promise: res,
        content: 'User has been deleted successfully',
      });
    }
  };

  return (
    <>
      <button type="button" onClick={handleOpen} className={trigger?.className}>
        {currentUser?.email === user.email ? (
          <p className="text-primary-red">Leave</p>
        ) : (
          <Trash2 width={20} height={20} />
        )}
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alertTitle"
        aria-describedby="alertContent"
      >
        <div className="flex flex-col gap-6 p-6">
          <h2 className="text-xl font-semibold">
            Are you sure you want to delete{' '}
            <span className="font-bold text-xl">{user.email}</span> from the{' '}
            {currentOrg?.name}?
          </h2>
          <div className="flex justify-end gap-3">
            <Button
              onClick={handleClose}
              variant="outlined"
              tabIndex={3}
              style={{
                color: '#666',
                borderColor: '#B3B3B3',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              tabIndex={2}
              variant="contained"
              style={{
                backgroundColor: '#B8341B',
                color: '#FFF',
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmDeleteUserDialog;
