import React, { type SyntheticEvent, useState } from 'react';

import { useGetIFS } from '../hooks/useIFSHook';
import { useGetRouteParams } from '../hooks/useUtilsHook';

import IFSTableSection from './dataset/IFSTableSection';
import { CustomTabPanel, Tab, Tabs } from './mui-custom';
import ViewFilesDataTable from './ViewFilesDataTable';

const TABS = ['Import', 'View Data'];

const UploadIFSFilesTabs = () => {
  const [index, setIndex] = useState(0);

  const { orgId, projectId, datasetId } = useGetRouteParams();

  const { data: ifs } = useGetIFS(
    {
      orgId,
      projectId,
      datasetId,
    },
    {
      enabled: !!datasetId && !!projectId && !!orgId,
    }
  );

  const isDisabled =
    ifs?.files.filter((file) => !file.is_imported).length === ifs?.files.length;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  return (
    <div className="flex flex-col gap-4">
      <Tabs value={index} onChange={handleChange}>
        {TABS.map((report) => (
          <Tab
            id={report}
            key={report}
            label={report}
            disabled={report === 'View Data' && !!isDisabled}
          />
        ))}
      </Tabs>
      <CustomTabPanel value={index.toString()} index="0">
        <IFSTableSection />
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="1">
        <ViewFilesDataTable />
      </CustomTabPanel>
    </div>
  );
};

export default UploadIFSFilesTabs;
