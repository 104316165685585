import React from 'react';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { X } from 'lucide-react';

type AlertDialogProps = {
  open: boolean;
  handleOnClose: () => void;
  data: {
    name: string;
    projectName: string;
    description: string;
    from: string;
    to: string;
    organization: string;
  };
};

const SentInvitationDialog = ({
  open,
  handleOnClose,
  data,
}: AlertDialogProps) => (
  <Dialog
    open={open}
    onClose={handleOnClose}
    aria-labelledby="alertTitle"
    aria-describedby="alertContent"
    fullWidth
  >
    <DialogTitle id="alertTitle">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-bold">Model Details</h2>
        <X size={20} onClick={handleOnClose} className="cursor-pointer" />
      </div>
    </DialogTitle>
    <DialogContent id="alertContent">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <div className="flex gap-2">
            <label className="text-[#666]">Model Name: </label>
            <p className="font-semibold">{data.name}</p>
          </div>
          <div className="flex gap-2">
            <label className="text-[#666]">Project: </label>
            <p className="font-semibold">{data.projectName}</p>
          </div>
        </div>
        <div className="flex gap-2">
          <label className="text-[#666]">Model Description: </label>
          <p className="font-semibold">{data.description}</p>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <label className="text-[#666]">From: </label>
            <p className="font-semibold">{data.from}</p>
          </div>
          <div className="flex gap-2">
            <label className="text-[#666]">Organization: </label>
            <p className="font-semibold">{data.organization}</p>
          </div>
        </div>
        <div className="flex gap-2">
          <label className="text-[#666]">To: </label>
          <p className="font-semibold">{data.to}</p>
        </div>
      </div>
    </DialogContent>
  </Dialog>
);

export default SentInvitationDialog;
