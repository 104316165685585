import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useGridApiRef } from '@mui/x-data-grid-premium';

import {
  useGetDatasetInputFileById,
  useGetDatasetInputFiles,
  useGetDatasetInputFilesData,
} from '../hooks/useDatasetsHook';
import { useGetRouteParams } from '../hooks/useUtilsHook';

import DataGridPremiumTable from './DataGridPremiumTable';
import FormSelect from './FormSelect';

type FileDataTableProps = {
  columns: {
    excel_name: string;
    is_pinned: boolean;
    name: string;
    data_type: 'number' | 'string' | 'date';
    styles: Record<string, Record<string, boolean | number | string | any>>[];
  }[];
  isLoading: boolean;
  data: Record<string, (string | number)[]> | undefined;
};

const FileDataTable = ({ columns, data, isLoading }: FileDataTableProps) => {
  const apiRef = useGridApiRef();

  return (
    <DataGridPremiumTable
      apiRef={apiRef}
      columns={columns.map((c) => ({
        label: c.excel_name,
        is_pinned: c.is_pinned,
        name: c.name,
        data_type: c.data_type,
        styles: c.styles,
      }))}
      isLoading={isLoading}
      data={data}
    />
  );
};

const ViewFilesDataTable = () => {
  const form = useForm();
  const { orgId, projectId, datasetId } = useGetRouteParams();

  const { watch, setValue } = form;

  const fileId = watch('fileName');
  const tableNameId = watch('tableName');

  const { data: inputFiles } = useGetDatasetInputFiles(
    {
      orgId,
      projectId,
      datasetId,
    },
    {
      enabled: !!orgId && !!projectId && !!datasetId,
    }
  );

  const { data: inputFileById } = useGetDatasetInputFileById(
    {
      orgId,
      projectId,
      datasetId,
      fileId,
    },
    {
      enabled: !!orgId && !!projectId && !!datasetId && !!fileId,
    }
  );

  const fileNames = useMemo(
    () =>
      inputFiles?.map((file) => ({
        label: file.name.replace('.xlsx', ''),
        value: file.id,
      })),
    [inputFiles]
  );

  const tableNames = useMemo(
    () =>
      inputFileById?.schema.map((s) => ({
        label: s.excel_tab_name,
        value: s.name,
      })),
    [inputFileById]
  );

  const {
    data: inputFilesData,
    isLoading: isFetchingData,
    isRefetching: isRefetchingData,
  } = useGetDatasetInputFilesData(
    {
      orgId,
      projectId,
      datasetId,
      fileId,
      dataTableName: tableNameId,
    },
    {
      enabled:
        !!orgId && !!projectId && !!datasetId && !!fileId && !!tableNameId,
    }
  );

  useEffect(() => {
    if (inputFiles?.length) {
      setValue('fileName', inputFiles[0].id);
    }
  }, [inputFiles]);

  useEffect(() => {
    if (inputFileById) {
      setValue('tableName', inputFileById.schema[0].name);
    }
  }, [inputFileById, fileId]);

  const columns = inputFileById?.schema.find(
    (s) => s.name === tableNameId
  )?.columns;

  return (
    <div className="space-y-4">
      <FormProvider {...form}>
        <form>
          <div className="flex max-w-[50%] gap-3">
            {fileNames && (
              <FormSelect
                name="fileName"
                defaultValue={inputFiles?.[0]?.id}
                options={fileNames}
                label="File Name"
              />
            )}
            {tableNames && (
              <FormSelect
                name="tableName"
                defaultValue={inputFileById?.schema[0].name}
                options={tableNames}
                label="Table Name"
              />
            )}
          </div>
        </form>
      </FormProvider>
      {columns && (
        <FileDataTable
          columns={columns}
          data={inputFilesData}
          isLoading={isFetchingData || isRefetchingData}
        />
      )}
    </div>
  );
};

export default ViewFilesDataTable;
