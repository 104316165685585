import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Dialog } from '@mui/material';

import Warning from '../icons/Warning';

type ConfirmDeleteDialogProps = {
  open: boolean;
  secondaryActionText?: string;
  primaryActionText: string;
  handleSecondaryAction?: () => void;
  handlePrimaryAction: () => void;
  title?: string;
  content: React.ReactNode;
  isPending?: boolean;
};

const WarningDialog = ({
  open,
  handleSecondaryAction,
  handlePrimaryAction,
  secondaryActionText,
  primaryActionText,
  title,
  content,
  isPending,
}: ConfirmDeleteDialogProps) => (
  <Dialog
    open={open}
    onClose={handleSecondaryAction}
    aria-labelledby="alertTitle"
    aria-describedby="alertContent"
  >
    <div className="flex flex-col gap-6 p-6">
      <h2 className="text-xl font-bold flex items-end gap-2">
        <Warning />
        <p className="text-xl font-semibold">{title ?? 'Warning'}</p>
      </h2>
      <div className="text-[#666]">{content}</div>
      <div className="flex justify-end gap-3">
        {secondaryActionText && (
          <Button
            onClick={handleSecondaryAction}
            variant="outlined"
            disabled={isPending}
            tabIndex={3}
            style={{
              color: '#666',
              borderColor: '#B3B3B3',
            }}
          >
            {secondaryActionText}
          </Button>
        )}
        {primaryActionText && (
          <LoadingButton
            onClick={handlePrimaryAction}
            tabIndex={2}
            variant="contained"
            disabled={isPending}
            loading={isPending}
            style={{
              backgroundColor: '#B8341B',
              color: '#FFF',
            }}
          >
            {primaryActionText}
          </LoadingButton>
        )}
      </div>
    </div>
  </Dialog>
);

export default WarningDialog;
