import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ArrowUpward } from '@mui/icons-material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { type TypeSharedModel } from '../../../common/dataTypes';
import { useGetReceivedModels } from '../../../hooks/useModelsHook';
import {
  useGetRouteParams,
  useGetSearchResults,
} from '../../../hooks/useUtilsHook';
import SearchInput from '../../SearchInput';
import TanstackTable from '../../TanstackTable';

export type DataRow = {
  id: number;
  name: string;
  sender: {
    email: string;
    full_name: string;
  };
  receivers: {
    email: string;
  }[];
  organization: string;
  createdAt: string;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = ({ handleSelect }: { handleSelect: (id: number) => void }) => [
  columnHelper.accessor('name', {
    cell: (info) => {
      const id = info.row.original.id;

      return (
        <div
          onClick={() => handleSelect(id)}
          className="group flex items-center gap-2 py-3 text-start"
        >
          <p className="w-full">{info.getValue()}</p>
        </div>
      );
    },
    header: () => (
      <div className="flex items-start gap-2">
        <p>Model</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('sender', {
    id: 'sender',
    cell: (info) => {
      const id = info.row.original.id;

      const handleOnclick = () => {
        handleSelect(id);
      };

      return info.getValue() ? (
        <div onClick={handleOnclick} className="w-full">
          {info.getValue().full_name}
        </div>
      ) : (
        <div
          onClick={handleOnclick}
          className="text-gray-400 w-full text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Shared By</div>,
  }),
  columnHelper.accessor('receivers', {
    id: 'receivers',
    cell: (info) => {
      const id = info.row.original.id;
      const value = info.getValue();

      const handleOnclick = () => {
        handleSelect(id);
      };

      return value.length ? (
        <div onClick={handleOnclick} className="w-full">
          {value.map((v) => v.email).join(', ')}
        </div>
      ) : (
        <div
          onClick={handleOnclick}
          className="text-gray-400 w-full text-center"
        >
          --
        </div>
      );
    },
    header: () => <div>Shared To</div>,
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    cell: (info) => {
      const id = info.row.original.id;

      const handleOnclick = () => {
        handleSelect(id);
      };

      return info.getValue() ? (
        <div onClick={handleOnclick} className="w-full">
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={handleOnclick}
          className="text-gray-400 w-full text-center"
        >
          --
        </div>
      );
    },
    header: () => <div>Date Sent</div>,
  }),
];

const ReceivedModelsTable = () => {
  const [data, setData] = useState<DataRow[]>([]);

  const { projectId, orgId } = useGetRouteParams();

  const form = useForm();

  const { watch } = form;

  const navigate = useNavigate();

  const {
    data: receivedModels,
    isLoading: isLoadingModels,
    isRefetching: isRefetchingModels,
  } = useGetReceivedModels(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const searchResults = useGetSearchResults<TypeSharedModel>({
    searchValue: watch('search'),
    data: receivedModels,
  });

  useEffect(() => {
    if (receivedModels) {
      setData(
        (searchResults ?? receivedModels).map((r) => ({
          id: r.id,
          name: r.name,
          sender: {
            email: r.shared_by.email,
            full_name: r.shared_by.full_name,
          },
          receivers: r.shared_with.map((r) => ({
            email: r.email,
          })),
          createdAt: r.created_at,
        })) as DataRow[]
      );
    }
  }, [receivedModels, searchResults]);

  const handleSelect = (id: number) => {
    navigate(`${id}?type=received`);
  };

  const table = useReactTable({
    data,
    columns: columns({ handleSelect }),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-2xl font-bold">Received</h1>
      <FormProvider {...form}>
        <form className="flex justify-between">
          <div className="flex w-[300px] gap-2">
            <SearchInput />
          </div>
        </form>
      </FormProvider>
      <TanstackTable
        table={table}
        isLoading={isLoadingModels || isRefetchingModels}
        emptyText="No received models available"
      />
    </div>
  );
};

export default ReceivedModelsTable;
