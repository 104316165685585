import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ArrowUpward } from '@mui/icons-material';
import {
  Avatar,
  MenuItem,
  Select as MuiSelect,
  type SelectChangeEvent,
  styled,
} from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import { ProjectRolesOptions } from '../../common/utils';
import {
  useGetProjectById,
  useGetProjectMembers,
  useUpdateProjectInvitation,
  useUpdateProjectMember,
} from '../../hooks/useProjectsHook';
import { useGetUser } from '../../hooks/useUserHook';
import {
  useGetRouteParams,
  useGetSearchResults,
} from '../../hooks/useUtilsHook';
import {
  type OrganizationStatus,
  type ProjectRole,
  type Role,
  type UserStatus,
} from '../../types';
import cn from '../../utils/cn';
import { transformToLabel } from '../../utils/helpers';
import Spinner from '../Spinner';
import ConfirmLeaveProjectDialog from '../userManagement/ConfirmLeaveProjectDialog';
import ResendOrgInvitation from '../userManagement/ResendOrgInvitation';

export type DataRow = {
  id: number;
  user: {
    id: number;
    name?: string | null;
    avatarImage: string;
    email: string;
  };
  type: OrganizationStatus;
  role: ProjectRole;
  lastActive: string | null;
  status: UserStatus;
};

const Select = styled(MuiSelect)({
  padding: '0px !important',
  fontSize: '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
    padding: '0px !important',
  },

  '& .MuiSelect-select': {
    backgroundColor: '#F8F8F8',
    paddingLeft: '10px !important',
    paddingRight: '60px !important',
  },
  '& .MuiSelect-nativeInput': {},
});

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('user', {
    cell: (info) => {
      const { email, name, avatarImage } = info.getValue();

      return (
        <div className="group flex items-center gap-1 py-2 text-start max-w-[160px]">
          <div className="flex items-center gap-2">
            <Avatar alt="User avatar" src={avatarImage} />
            <div className="flex flex-col">
              <div className="flex w-full justify-between gap-3">
                <p
                  className={cn('font-semibold', {
                    'opacity-70': !name,
                  })}
                >
                  {name ?? 'Unknown'}
                </p>
              </div>
              <p className="text-sm text-[#666]">{email}</p>
            </div>
          </div>
        </div>
      );
    },
    header: (info) => (
      <div className="flex items-center gap-1">
        <p>User</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('lastActive', {
    id: 'lastActive',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'Y/MM/dd hh:mm a')}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-start">--</div>
      ),
    header: () => <div>Last active</div>,
  }),
  columnHelper.accessor('role', {
    id: 'role',
    cell: (info) => {
      const memberId = info.row.original.id;
      const email = info.row.original.user.email;
      const type = info.row.original.type;

      const [role, setRole] = useState<Role>(info.getValue());

      const { orgId, projectId } = useGetRouteParams();

      const { data: currentUser } = useGetUser();

      const { mutate: updateProjectMember } = useUpdateProjectMember();

      const { mutate: updateProjectInvitation } = useUpdateProjectInvitation();

      const handleChangeRole = (event: SelectChangeEvent<unknown>) => {
        if (!orgId) {
          return;
        }

        const selectedRole = event.target.value as Role;

        if (type === 'member') {
          updateProjectMember({
            orgId,
            projectId,
            memberId,
            data: {
              role: selectedRole,
            },
          });
        }

        if (type === 'invitation') {
          updateProjectInvitation({
            orgId,
            projectId,
            data: {
              role: selectedRole,
            },
          });
        }

        setRole(selectedRole);
      };

      const orgMember = currentUser?.organizations.find((o) => o.id === orgId);

      return (
        <div className="max-w-[190px]">
          {currentUser?.email !== email &&
          orgMember?.role !== 'organization_member' ? (
            <Select
              id="role"
              size="small"
              value={role}
              disabled={currentUser?.email === email}
              onChange={handleChangeRole}
              className="bottom-0"
              fullWidth
              placeholder="Please select"
            >
              {ProjectRolesOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <span className="capitalize">{option.label}</span>
                </MenuItem>
              ))}
            </Select>
          ) : (
            <div className="flex items-center justify-between rounded-[4px] bg-[#F8F8F8] p-2 capitalize">
              <p>{transformToLabel(role)}</p>
            </div>
          )}
        </div>
      );
    },
    header: () => <div>Role</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const status = info.getValue();

      const { projectId, orgId } = useGetRouteParams();

      const type = info.row.original.type;

      const { data: project } = useGetProjectById(
        {
          orgId,
          projectId,
        },
        {
          enabled: !!orgId && !!projectId,
        }
      );

      const { data: currentUser } = useGetUser();

      const currentOrgMember = currentUser?.organizations.find(
        (org) => org.id === orgId
      );

      const projectRole = project?.role;

      const row = {
        ...info.row.original,
        email: info.row.original.user.email,
      };

      const user = {
        ...row,
        type:
          status === 'pending'
            ? 'invitation'
            : ('member' as 'invitation' | 'member'),
      };

      const isAllowDelete =
        user.email !== currentUser?.email &&
        projectRole === 'project_owner' &&
        user.role !== 'project_owner';

      const StatusLabel = ({
        label,
        value,
      }: {
        value: 'active' | 'pending';
        label: string;
      }) => (
        <div className="flex justify-start py-2">
          <p
            className={cn(
              'w-fit rounded-full bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#EEF6FF] text-[#117DD3]': value === 'active',
                'text-[#C38D04] bg-[#FFF8E0]': value === 'pending',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      return (
        <div className="flex w-full min-w-[200px] items-center justify-between">
          {status === 'active' ? (
            <StatusLabel value="active" label="Active" />
          ) : null}
          {status === 'pending' ? (
            <StatusLabel value="pending" label="Pending" />
          ) : null}
          {isAllowDelete && (
            <div className="flex gap-4">
              {type === 'invitation' && (
                <ResendOrgInvitation invitationId={row.id} orgId={orgId} />
              )}
              {project && (
                <ConfirmLeaveProjectDialog user={user} project={project} />
              )}
            </div>
          )}
        </div>
      );
    },
    header: () => <div>Status</div>,
    size: 200,
  }),
];

const ProjectMembersTable = ({ searchKey }: { searchKey: string }) => {
  const [data, setData] = useState<DataRow[]>([]);

  const { projectId, orgId } = useGetRouteParams();

  const { data: members, isPending: isFetchingProjectMembers } =
    useGetProjectMembers(
      {
        projectId,
        orgId,
      },
      {
        enabled: !!orgId && !!projectId,
      }
    );

  const form = useForm();
  const { watch } = form;

  const selectedUsers = watch('selectedUsers');

  const searchResults = useGetSearchResults<DataRow>({
    searchValue: searchKey,
    data,
    filterFields: ['user.name', 'user.email', 'role'],
  });

  useEffect(() => {
    const dataRows: DataRow[] = [];

    if (members && members?.length > 0) {
      members.forEach((m) =>
        dataRows.push({
          id: m.id,
          lastActive: m.date_joined,
          user: {
            id: m.user.id,
            name: `${m.user.first_name} ${m.user.last_name}`,
            avatarImage: m.user.profile_pic,
            email: m.user.email,
          },
          type: 'member',
          role: m.role,
          status: 'active',
        })
      );
    }

    setData(dataRows);
  }, [members]);

  const isIndeterminate = selectedUsers
    ? Object.keys(selectedUsers).some((key) => selectedUsers[key])
    : false;

  const table = useReactTable({
    data: searchResults ?? data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full">
      <FormProvider {...form}>
        <form>
          <table className="w-full">
            {!isIndeterminate && (
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="!rounded-md bg-[#F8F8F8]">
                    {headerGroup.headers.map((header, index) => (
                      <th
                        key={header.id}
                        className={cn(
                          'first:border-l last:border-r border-t border-b px-6 py-2 text-start text-sm text-[#4D4D4D]',
                          {
                            'w-[35%]': index === 0,
                          }
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            )}
            <tbody className="relative">
              {isFetchingProjectMembers && (
                <div className="absolute border-[#E4E7EC] h-full border flex w-full items-center justify-center opacity-50">
                  <Spinner />
                </div>
              )}
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="cursor-pointer px-6 py-3">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={cn(
                        'first:border-l last:border-r border-b border-[#E4E7EC] px-6 text-[#333]'
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {!table.getRowModel().rows.length && !isFetchingProjectMembers && (
            <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
              <p className="text-lg text-[#999]">
                No users available. Please add a user.
              </p>
            </div>
          )}
        </form>
      </FormProvider>
      <div className="h-4" />
    </div>
  );
};

export default ProjectMembersTable;
