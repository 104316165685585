import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ArrowUpward } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { ChevronLeft } from 'lucide-react';

import {
  useApproveSharedRequest,
  useDisapproveSharedRequest,
  useGetOrgSharedRequestById,
} from '../hooks/useOrganizationHook';
import { useCustomNavigate, useGetRouteParams } from '../hooks/useUtilsHook';
import routes from '../routes';
import { type ApproveStatus } from '../types';

import TanstackTable from './TanstackTable';

export type DataRow = {
  id: number;
  email: string;
  status: ApproveStatus;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('email', {
    cell: (info) => {
      const { orgId } = useGetRouteParams();

      const id = info.row.original.id;
      const status = info.row.original.status;
      const email = info.row.original.email;

      const navigate = useCustomNavigate();

      const { mutate: approve, isPending: isApprovingSharedRequest } =
        useApproveSharedRequest({
          onSuccess: () => {
            navigate(routes.sharedModelVault.index, {
              org_id: orgId,
            });
          },
        });

      const { mutate: disapprove, isPending: isDisapprovingSharedRequest } =
        useDisapproveSharedRequest({
          onSuccess: () => {
            navigate(routes.sharedModelVault.index, {
              org_id: orgId,
            });
          },
        });

      const handleApprove = () => {
        if (!orgId) {
          return;
        }
        approve({
          orgId,
          reqId: id,
        });
      };

      const handleDisapprove = () => {
        if (!orgId) {
          return;
        }
        disapprove({
          orgId,
          reqId: id,
        });
      };

      const isDisabled =
        isApprovingSharedRequest || isDisapprovingSharedRequest;

      return (
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col py-2">
            <p className="font-semibold">{info.getValue()}</p>
          </div>
          <div>
            {status === 'Disapproved' && (
              <button className="py-2 text-primary-red">Rejected</button>
            )}
            {status === 'Approved' && (
              <button className="py-2 text-[#4782D6]">Approved</button>
            )}
            {status === 'Pending' && (
              <div className="flex gap-3 py-2">
                <LoadingButton
                  disabled={isDisabled}
                  loading={isApprovingSharedRequest}
                  onClick={handleApprove}
                  variant="contained"
                  style={{
                    borderRadius: '4px',
                    backgroundColor: '#E1FBE7',
                    color: '#0B9919',
                    fontWeight: 500,
                  }}
                >
                  Approve
                </LoadingButton>
                <LoadingButton
                  disabled={isDisabled}
                  loading={isDisapprovingSharedRequest}
                  onClick={handleDisapprove}
                  variant="contained"
                  style={{
                    borderRadius: '4px',
                    backgroundColor: '#FFF4F2',
                    color: '#FF6A5C',
                    fontWeight: 500,
                  }}
                >
                  Reject
                </LoadingButton>
              </div>
            )}
          </div>
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-1">
        <p>Receiver</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
];

const SharingRequestDetailView = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<DataRow[]>([]);

  const tab = searchParams.get('tab');

  const { requestId, orgId } = useGetRouteParams();
  const navigate = useCustomNavigate();

  const {
    data: request,
    isLoading: isFetchingSharedRequests,
    isRefetching: isRefetchingSharedRequests,
  } = useGetOrgSharedRequestById(
    {
      orgId,
      requestId,
    },
    {
      enabled: !!orgId || !!requestId,
    }
  );

  const handleGoBack = () => {
    navigate(`${routes.sharedModelVault.index}?tab=${tab}`, {
      org_id: orgId,
    });
  };

  useEffect(() => {
    if (request?.sharing_invitations) {
      setData(
        request.sharing_invitations.map((i) => ({
          id: i.id,
          email: i.email,
          status: i.approve_status,
        }))
      );
    }
  }, [request]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex mx-auto flex-col gap-6 py-6 max-w-lg 3xl:max-w-2x">
      <button onClick={handleGoBack} className="flex items-center gap-2">
        <ChevronLeft />
        <p className="text-2xl font-bold">Detail information</p>
      </button>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1 flex">
          <span className="text-[#666] w-[25%]">Project Name: </span>
          <p className="font-bold">{request?.project.name}</p>
        </div>
        <div className="col-span-1 flex">
          <span className="text-[#666] w-[25%]">Model Name: </span>
          <p className="font-bold">{request?.model.name}</p>
        </div>
        <div className="col-span-2 flex">
          <span className="text-[#666] w-[17.5%]">Description: </span>
          <p className="font-bold whitespace-normal">
            {request?.model.description}
          </p>
        </div>
        <div className="col-span-1 flex">
          <div className="text-[#666] w-[25%]">Requester: </div>
          <p className="font-bold">{request?.shared_by.full_name}</p>
        </div>
        <div className="col-span-1 flex">
          <span className="text-[#666] w-[25%]">Date requested: </span>
          <p className="font-bold">
            {request?.created_at
              ? format(request.created_at, 'Y/MM/dd h:mm a')
              : ''}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <h2 className="text-xl font-semibold">Approval Waiting List</h2>
        <TanstackTable
          table={table}
          isLoading={isRefetchingSharedRequests || isFetchingSharedRequests}
          emptyText={'No available requests.'}
        />
      </div>
    </div>
  );
};

export default SharingRequestDetailView;
