import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, type SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  styled,
} from '@mui/material';
import { AxiosError } from 'axios';
import { UserPlus } from 'lucide-react';
import * as z from 'zod';

import EmailInputChips from '../../common/EmailInputChips';
import {
  useCreateOrgInvitation,
  useGetOrganizationById,
  useGetOrgMembers,
} from '../../hooks/useOrganizationHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { type Role } from '../../types';
import cn from '../../utils/cn';
import SuccessDialog from '../SuccessDialog';

const Select = styled(MuiSelect)({
  fontSize: '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },

  '& .MuiSelect-select': {},
  '& .MuiSelect-nativeInput': {},
});

const EmailSchema = z.string().trim().email('Invalid email address');

type EmailSchemaType = z.infer<typeof EmailSchema>;

const MenuItem = styled(MuiMenuItem)({
  fontSize: '14px',
});

type InviteFormDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  roles: {
    label: string;
    value: string;
  }[];
  to?: 'organization' | 'project';
  showTrigger?: boolean;
  iUser?: {
    id: number;
    email: string;
    fullName: string;
  };
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
  };
};

const InviteOrgFormDialog = ({
  open,
  handleClose,
  handleOpen,
  roles,
  trigger,
  iUser,
  showTrigger = true,
}: InviteFormDialogProps) => {
  const inputId = 'emails';

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const [emails, setEmails] = useState<{ value: string; isError?: boolean }[]>(
    []
  );

  const ref = useRef<HTMLInputElement>(null);

  const form = useForm();

  const {
    handleSubmit,
    formState,
    setError,
    reset,
    register,
    watch,
    clearErrors,
  } = form;

  const role = watch('role') as Role;

  const { isSubmitting, errors } = formState;

  const { orgId } = useGetRouteParams();

  const { data: currentProject } = useGetOrganizationById(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const { data: orgMembers } = useGetOrgMembers(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const { mutateAsync: createOrgInvitation, isPending: isSendingInvitation } =
    useCreateOrgInvitation({
      onSuccess() {
        handleCloseDialog();
        reset();
        handleOpenSuccessDialog();
      },
      onError(error) {
        if (error instanceof AxiosError) {
          setError(inputId, {
            message: error.response.data[inputId][0],
          });
        }
      },
    });

  useEffect(() => {
    reset({ role: roles[0].value });
  }, []);

  const handleOpenSuccessDialog = () => {
    setOpenSuccessDialog(true);
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  const handleCloseDialog = () => {
    reset();
    setEmails([]);
    handleClose();
  };

  const handleError = ({
    message,
    email,
    ref,
  }: {
    email: string;
    message: string;
    ref: React.RefObject<HTMLInputElement>;
  }) => {
    setError(`${inputId}.${email.replaceAll('.', '')}`, { message });
    setEmails((prev) => [
      ...prev,
      {
        value: email,
        isError: true,
      },
    ]);
    if (ref.current) {
      ref.current.value = '';
    }
  };

  const handleAddEmail = (email: EmailSchemaType) => {
    const { error } = EmailSchema.safeParse(email);

    if (error) {
      handleError({
        email,
        message: error.errors[0].message,
        ref,
      });
      return;
    }

    if (orgMembers?.find((member) => member.user.email === email)) {
      handleError({
        email,
        message: 'This user already exists in the organization',
        ref,
      });
      return;
    }

    setEmails((prev) => [...prev, { value: email }]);

    if (ref.current) {
      ref.current.value = '';
    }
  };

  const handleRemoveEmail = (email: string) => {
    clearErrors(`${inputId}.${email.replaceAll('.', '')}`);
    setEmails((prev) => prev.filter((e) => e.value !== email));
  };

  const onSubmit: SubmitHandler<any> = async () => {
    const currentEmail = ref.current?.value;

    if (!emails.length && !currentEmail) {
      setError(inputId, {
        message: 'You must enter at least one email',
      });
      return;
    }

    if (!role) {
      setError(inputId, { message: 'Please select a role' });
      return;
    }

    if (!emails.length && currentEmail) {
      createOrgInvitation({
        orgId,
        data: {
          email: currentEmail,
          role,
        },
      });
      return;
    }

    await Promise.all(
      emails.map((email) =>
        createOrgInvitation({
          orgId,
          data: {
            email: email.value,
            role,
          },
        })
      )
    );
  };

  const errorsKeys = Object.keys(errors[inputId] ?? {});

  const errorMessage = useMemo(
    () =>
      errors[inputId]
        ? (errors[inputId] as Record<string, { message: string }>)[
            errorsKeys[errorsKeys.length - 1]
          ].message
        : null,
    [errorsKeys]
  );

  return (
    <>
      {showTrigger && (
        <button
          type="button"
          onClick={handleOpen}
          style={{
            color: 'white',
            backgroundColor: '#B8341B',
            display: 'flex',
            gap: 4,
            borderRadius: 4,
            padding: '6px 12px',
            ...trigger?.style,
          }}
        >
          {trigger?.label ?? (
            <div className="flex w-fit items-center gap-2">
              <UserPlus size={20} />
              <p className="w-fit">Invite users</p>
            </div>
          )}
        </button>
      )}
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle className="w-full">
          <h2 className="pt-2 text-2xl font-bold">
            Invite user(s) to {currentProject?.name}
          </h2>
        </DialogTitle>
        <DialogContent>
          <div>
            <FormProvider {...form}>
              <form className="mb-2 space-y-6">
                <div className="flex flex-col gap-2">
                  <div
                    className={cn(
                      'flex items-center justify-between rounded-[4px] border-2 border-[#2196F3] px-2',
                      {
                        'border-primary-red': errorMessage,
                      }
                    )}
                  >
                    <div className="flex w-full flex-wrap items-center gap-1 py-2">
                      <EmailInputChips
                        emails={emails}
                        onRemoveEmail={handleRemoveEmail}
                      />
                      <input
                        ref={ref}
                        name="email"
                        placeholder='Type email and press "Enter" or ","'
                        value={iUser?.email}
                        disabled={!!iUser}
                        onKeyDown={(e) => {
                          const keyCode = e.key;

                          const isEmpty =
                            e.currentTarget.value.replace(',', '').length === 0;

                          if (isEmpty && ref.current) {
                            ref.current.value = '';
                            return;
                          }

                          if (keyCode === 'Enter' || keyCode === ',') {
                            handleAddEmail(
                              e.currentTarget.value.replace(',', '')
                            );
                          }
                        }}
                        className="grow outline-none"
                      />
                    </div>
                    <Select
                      {...register('role')}
                      defaultValue={roles[0].value}
                      size="small"
                      className="bottom-0"
                      placeholder="Please select"
                    >
                      {roles.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <span className="capitalize">{option.label}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {errorMessage && (
                    <p className="px-2 text-sm text-primary-red">
                      {errorMessage}
                    </p>
                  )}
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialog}
                    variant="outlined"
                    disabled={isSubmitting || isSendingInvitation}
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    onClick={handleSubmit((event) => onSubmit(event))}
                    loading={isSubmitting || isSendingInvitation}
                    disabled={isSubmitting || isSendingInvitation}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    Send
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
      <SuccessDialog
        content="Invitation sent successfully."
        open={openSuccessDialog}
        handleOnClose={handleCloseSuccessDialog}
      />
    </>
  );
};

export default InviteOrgFormDialog;
