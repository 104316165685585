import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormControl,
  MenuItem,
  Select,
  styled,
  type SxProps,
  type Theme,
} from '@mui/material';
import { ChevronDown as LucideChevronDown } from 'lucide-react';

import { transformToLabel } from '../utils/helpers';

type FormSelectProps = {
  label?: string;
  name: string;
  options: {
    label: string;
    value: string | number;
  }[];
  defaultValue?: string | number;
  isError?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
};

const ChevronDown = styled(LucideChevronDown)({
  width: '16px',
  height: '16px',
  marginRight: '2px',
});

const FormSelect = ({
  label,
  defaultValue,
  name,
  options,
  style,
  fullWidth = true,
  ...props
}: FormSelectProps) => {
  const { register, formState, watch } = useFormContext();

  const { errors } = formState;

  const errorMessage = errors[name]?.message as string | undefined;

  const value = watch(name);

  return (
    <FormControl
      {...props}
      fullWidth={fullWidth}
      style={{
        ...style,
      }}
    >
      {label !== undefined && (
        <label htmlFor={name} className="min-h-[29px] pb-2 text-sm text-[#666]">
          {transformToLabel(label)}
        </label>
      )}
      <Select
        {...register(name)}
        value={value ?? defaultValue}
        size="small"
        IconComponent={ChevronDown}
        className="bottom-0"
        placeholder="Please select"
        endAdornment={null}
        sx={{
          '& .MuiSelect-select': {
            color: '#666666',
            fontSize: '14px',
            fontWeight: 500,
            padding: '7.5px 14px',
          },
        }}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <span className="capitalize">{option.label}</span>
          </MenuItem>
        ))}
      </Select>
      <div className="flex justify-between">
        {errorMessage && (
          <p className="w-full pt-2 text-start text-[14px] text-[#FF816A]">
            {errorMessage}
          </p>
        )}
      </div>
    </FormControl>
  );
};

export default FormSelect;
