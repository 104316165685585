import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { isOrgAdmin } from '../../hooks/useUtilsHook';
import { useCurrentOrganization } from '../../utils/helpers';
import { Tab, Tabs } from '../mui-custom';
import SearchInput from '../SearchInput';

import PendingRequestsTable from './admin/PendingRequestsTable';
import AdminReceivedInvitationsTable from './admin/ReceivedInvitationsTable';
import SentRequestsTable from './owner/SentRequestsTable';
import SentInvitationsTable from './SentInvitationsTable';

const tabs = () => {
  const currentOrg = useCurrentOrganization();

  if (currentOrg && isOrgAdmin(currentOrg.role)) {
    return ['Sent Invitations', 'Received Invitations', 'Pending Requests'];
  }

  return ['Sent Invitations', 'Received Invitations', 'Sent Requests'];
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

enum TabsEnum {
  'sent-invitations' = 0,
  'received-invitations' = 1,
  'pending-requests' = 2,
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={value} {...other}>
      {value === index && children}
    </div>
  );
};

const SharedModelVault = () => {
  const currentOrg = useCurrentOrganization();

  const form = useForm();

  const { watch } = form;

  const searchKey = watch('search');

  const [index, setIndex] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get('tab');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);

    if (newValue === 0) {
      searchParams.set('tab', 'sent-invitations');
    } else if (newValue === 1) {
      searchParams.set('tab', 'received-invitations');
    } else if (newValue === 2) {
      searchParams.set('tab', 'pending-requests');
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!tab) {
      setIndex(0);
      return;
    }

    setIndex((TabsEnum as unknown as Record<string, number>)[tab]);
  }, [tab]);

  return (
    <div className="mx-auto flex w-full max-w-lg 3xl:max-w-2xl flex-col space-y-6 py-6">
      <h1 className="text-2xl font-bold">Shared Model Vault</h1>
      <Tabs value={index} onChange={handleChange}>
        {tabs().map((tab, i) => (
          <Tab id={i.toString()} key={tab} label={tab} />
        ))}
      </Tabs>
      <div className="flex gap-3">
        <FormProvider {...form}>
          <form className="w-[300px]">
            <SearchInput />
          </form>
        </FormProvider>
      </div>
      <div>
        <TabPanel index="0" value={index.toString()}>
          <SentInvitationsTable searchKey={searchKey} />
        </TabPanel>
        <TabPanel index="1" value={index.toString()}>
          <AdminReceivedInvitationsTable searchKey={searchKey} />
        </TabPanel>
        <TabPanel index="2" value={index.toString()}>
          {currentOrg && isOrgAdmin(currentOrg.role) ? (
            <PendingRequestsTable searchKey={searchKey} />
          ) : (
            <SentRequestsTable searchKey={searchKey} />
          )}
        </TabPanel>
      </div>
    </div>
  );
};

export default SharedModelVault;
