import React, {
  type SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@mui/material';
import {
  type ColumnsStylesInterface,
  type GridExcelExportOptions,
  gridVisibleSortedRowIdsSelector,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import _ from 'lodash';
import { ArrowDownToLine } from 'lucide-react';
import { FoldVertical, UnfoldVertical } from 'lucide-react';

import { type OutputSChemaDataTable } from '../../common/dataTypes';
import { useGetDatasetById } from '../../hooks';
import { useGetIFSConfig } from '../../hooks/useDatasetsHook';
import { useGetModelOutput, useGetModels } from '../../hooks/useModelsHook';
import { useGetProjectOutputSchema } from '../../hooks/useProjectsHook';
import {
  useGetDistinguishModel,
  useGetRouteParams,
} from '../../hooks/useUtilsHook';
import DataGridPremiumTable from '../DataGridPremiumTable';
import { CustomTabPanel, Tab, Tabs } from '../mui-custom';

import CustomMenuDropdown from './CustomMenuDropdown';

export type StyledTabProps = {
  label: string;
  id: string;
};

export const Reports = {
  average_expense: 'Average Expenses',
  average_rate: 'Average Rate',
  stats: 'Stats',
  kpis: 'KPIs',
  financials: 'Financials',
  crew_requirements: 'Crew Requirements',
  movement_matrix: 'Movement Matrix',
  training: 'Training',
  seniority_distribution: 'Seniority Distribution',
};

export type Report = keyof typeof Reports;

const ModelTable = ({ dataTable }: { dataTable: OutputSChemaDataTable }) => {
  const apiRef = useGridApiRef();

  const [isExpandAll, setIsExpandAll] = useState(true);

  const { orgId, projectId, datasetId } = useGetRouteParams();

  const { formState } = useFormContext();

  const { isSubmitting } = formState;

  const report = dataTable.name;

  const {
    model,
    modelOutput,
    isLoading: isModelLoading,
  } = useGetDistinguishModel();

  const hasTreeData = useMemo(
    () =>
      !_.isEmpty(apiRef.current) &&
      apiRef.current.getSortedRows().some((r) => r.path.length > 1),
    [!_.isEmpty(apiRef.current)]
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId: model?.dataset ?? datasetId,
      orgId,
      projectId,
    },
    {
      enabled: (!!datasetId || !!model?.dataset) && !!orgId && !!projectId,
    }
  );

  let treeData;

  if (report === 'financials') {
    treeData = {
      headerName: 'Expense',
      groupColumns: ['expense_group_name', 'expense_name', 'crew_type'],
    };
  } else if (report === 'kpis') {
    treeData = {
      headerName: 'KPIs',
      groupColumns: ['stats_name', 'crew_type'],
    };
  } else if (report === 'average_expense') {
    treeData = {
      headerName: 'Average Expense',
      groupColumns: ['expense_name', 'crew_type'],
    };
  }

  const handleToggleGroups = useCallback(() => {
    if (!apiRef.current) {
      return;
    }

    const rowIds = apiRef.current.getAllRowIds();
    const visibleRowsIds = gridVisibleSortedRowIdsSelector(apiRef);

    let expand = true;

    if (rowIds.length === visibleRowsIds.length) {
      setIsExpandAll(false);
      expand = false;
    } else {
      setIsExpandAll(true);
      expand = true;
    }

    rowIds.forEach((rowId) => {
      apiRef.current.setRowChildrenExpansion(rowId, expand);
    });
  }, [apiRef, dataTable]);

  const handleExportToExcel = () => {
    const colStyles: ColumnsStylesInterface = {};
    dataTable?.columns.forEach((colDef) => {
      colStyles[colDef.name] = {
        numFmt: '#,###',
        font: {},
        alignment: {},
        protection: {},
        border: {},
        fill: {
          type: 'pattern',
          pattern: 'none',
        },
      };
    });

    const options: GridExcelExportOptions = {
      fileName: `${model?.name}_${dataset?.name}_${report}`,
      columnsStyles: { ...colStyles },
    };

    apiRef.current.exportDataAsExcel(options);
  };

  const isLoading = isSubmitting || isModelLoading;

  return (
    <>
      <div className="flex gap-4 items-center justify-end">
        <Button
          size="small"
          onClick={handleExportToExcel}
          variant="outlined"
          className="space-x-1 font-semibold"
        >
          <ArrowDownToLine />
          <label>Export to Excel</label>
        </Button>
        {hasTreeData && (
          <Button
            onClick={handleToggleGroups}
            variant="outlined"
            size="small"
            style={{
              borderColor: '#CCC',
              color: '#333333',
              fontWeight: 600,
            }}
          >
            {isExpandAll ? (
              <div className="flex items-center gap-1">
                <FoldVertical strokeWidth={1.5} />
                <p>Collapse All</p>
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <UnfoldVertical strokeWidth={1.5} />
                <p>Expand All</p>
              </div>
            )}
          </Button>
        )}
      </div>
      <DataGridPremiumTable
        treeData={treeData ?? undefined}
        apiRef={apiRef}
        columns={dataTable.columns.map((c) => ({
          name: c.name,
          label: c.display_name,
          is_pinned: c.is_pinned,
          styles: c.styles,
          data_type: c.data_type,
        }))}
        placeholder="Click 'Run' to view the data"
        isLoading={isLoading}
        data={modelOutput?.[report]}
      />
    </>
  );
};

export const ModelTablesTab = ({ report }: { report: Report }) => {
  const { orgId, projectId } = useGetRouteParams();

  const { data: outputSchema } = useGetProjectOutputSchema(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const dataTables = outputSchema?.sections.find(
    (s) => s.name === report
  )?.data_tables;

  return <>{dataTables?.map((d) => <ModelTable dataTable={d} />)}</>;
};

const ModelOutputTabs = () => {
  const [index, setIndex] = useState(0);
  const [checkVariance, setCheckVariance] = useState(false);
  const [report, setReport] = useState<Report>('financials');

  const { orgId, projectId } = useGetRouteParams();

  const { data: models } = useGetModels(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const baseModel = models?.find((m) => m.is_base);

  const { data: baseModelOutput } = useGetModelOutput(
    {
      modelId: Number(baseModel?.id) ?? NaN,
      orgId,
      projectId,
    },
    {
      enabled: !!baseModel?.id && !!orgId && !!projectId,
    }
  );

  const { data: ifsConfig, isPending: isGettingIfsConfig } = useGetIFSConfig(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { data: outputSchema } = useGetProjectOutputSchema(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const aircrafts =
    ifsConfig?.aircrafts.map((a) => ({
      label: a.name,
      value: a.name,
    })) ?? [];

  const airports =
    ifsConfig?.aircrafts
      .map((a) =>
        a.airports.map((ai) => ({
          label: ai,
          value: ai,
        }))
      )
      .flat() ?? [];

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const value = event.currentTarget.id as Report;

    setIndex(newValue);
    setReport(value);
  };

  const handleOnToggleVariance = () => {
    setCheckVariance(!checkVariance);
  };

  const dataTable = outputSchema?.sections
    ?.find((s) => s.name === report)
    ?.data_tables.find((d) => d.name === report);

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex items-center justify-between">
        <Tabs value={index} onChange={handleChange}>
          {outputSchema?.sections?.map((s) => (
            <Tab id={s.name} key={s.name} label={s.display_name} />
          ))}
        </Tabs>
        <div className="flex items-center gap-1">
          {/* {!model?.is_base && (
            <div className="flex items-center">
              <label className="text-sm font-semibold text-[#333]">
                Variance
              </label>
              <Switch
                checked={checkVariance}
                onChange={handleOnToggleVariance}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
          )} */}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex w-[40%] gap-4">
          {aircrafts && !isGettingIfsConfig && (
            <CustomMenuDropdown
              name={'aircrafts'}
              label={'Aircraft'}
              defaultSelectAll
              data={aircrafts}
            />
          )}
          {airports && !isGettingIfsConfig && (
            <CustomMenuDropdown
              name={'airports'}
              label={'Airport'}
              defaultSelectAll
              data={airports}
            />
          )}
        </div>
      </div>
      {report && (
        <div>
          <CustomTabPanel value={report} index={report}>
            <ModelTablesTab report={report} />
          </CustomTabPanel>
        </div>
      )}
    </div>
  );
};

export default ModelOutputTabs;
