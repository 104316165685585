import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { type FieldValues } from 'react-hook-form/dist/types';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { type TypeMember } from '../../common/dataTypes';
import { useGetOrgMembers } from '../../hooks/useOrganizationHook';
import { useGetUser } from '../../hooks/useUserHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import FormAutoComplete from '../FormAutoComplete';

type TransferOwnershipDialogProps = {
  open: boolean;
  handleCancelDialog: () => void;
  handleSubmitDialog: (member: TypeMember) => void;
};

const TransferOwnershipDialog = ({
  open,
  handleCancelDialog,
  handleSubmitDialog,
}: TransferOwnershipDialogProps) => {
  const form = useForm();

  const { reset, formState, handleSubmit } = form;

  const { errors, isSubmitting } = formState;

  const { orgId } = useGetRouteParams();

  const { data: currentUser } = useGetUser();

  const { data: orgMembers } = useGetOrgMembers(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const handleCloseDialog = () => {
    reset();
    handleCancelDialog();
  };

  const onSubmit = (data: FieldValues) => {
    const { user } = data;

    const selectedUser = orgMembers?.find((o) => o.user.id === Number(user));

    if (selectedUser) {
      handleSubmitDialog(selectedUser);
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle className="w-full flex flex-col">
        <h2 className="pt-2 text-2xl font-bold">Transfer ownership</h2>
      </DialogTitle>
      <DialogContent>
        <div className="space-y-4">
          <DialogContentText>
            By transferring ownership, your account will be downgraded to
            'Admin' status
          </DialogContentText>
          <FormProvider {...form}>
            <form className="mb-2 space-y-6">
              <div className="flex flex-col gap-2">
                {orgMembers && currentUser && (
                  <FormAutoComplete
                    name="user"
                    label="Select a user to transfer"
                    options={orgMembers
                      .filter((orgMember) => orgMember.id !== currentUser.id)
                      .map((member) => ({
                        label:
                          member.user.first_name + ' ' + member.user.last_name,
                        value: member.user.id.toString(),
                      }))}
                  />
                )}
                {errors['email'] && (
                  <p className="px-2 text-sm text-primary-red">
                    {errors['email'].message as string}
                  </p>
                )}
              </div>
              <div className="flex-end flex justify-end gap-3">
                <Button
                  onClick={handleCloseDialog}
                  variant="outlined"
                  disabled={isSubmitting}
                  style={{
                    color: '#666',
                    borderColor: '#B3B3B3',
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  onClick={handleSubmit((event) => onSubmit(event))}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  variant="contained"
                  style={{
                    backgroundColor: '#2196F3;',
                    color: '#FFF',
                  }}
                >
                  Next
                </LoadingButton>
              </div>
            </form>
          </FormProvider>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TransferOwnershipDialog;
