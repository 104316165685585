import React, { useEffect, useState } from 'react';

import { useVerifySharingInvitation } from '../../hooks/useOrganizationHook';
import { useCustomNavigate, useGetRouteParams } from '../../hooks/useUtilsHook';
import routes from '../../routes';

import VerificationFailed from './VerificationFailed';

const SharingInvitationVerification = () => {
  const { token, orgId, projectId } = useGetRouteParams();

  const navigate = useCustomNavigate();

  const [status, setStatus] = useState<'success' | 'pending' | 'failed'>(
    'pending'
  );

  const { mutate: verifySharingInvitation } = useVerifySharingInvitation({
    onSuccess: (data) => {
      navigate(routes.home, {
        orgId,
      });
    },
    onError() {
      navigate(routes.home, {
        orgId,
      });
      setStatus('failed');
    },
  });

  useEffect(() => {
    verifySharingInvitation({
      orgId,
      projectId,
      data: {
        token: token as string,
      },
    });
  }, [token]);

  if (status === 'failed') {
    return <VerificationFailed subDescription="" />;
  }

  return <div className="flex h-screen bg-[#01285F] " />;
};

export default SharingInvitationVerification;
