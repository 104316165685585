import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import TopBar from '../common/TopBar';
import { useGetUser } from '../hooks/useUserHook';
import { useGetRouteParams } from '../hooks/useUtilsHook';
import routes from '../routes';

const ProtectedRoute = () => {
  const { orgId } = useGetRouteParams();

  const { data: currentUser } = useGetUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (currentUser.organizations.length === 0) {
      navigate('/' + routes.organization.invitations);
      return;
    }

    if (!Number(orgId) && currentUser.organizations.length > 0) {
      navigate(`/${currentUser.organizations[0]?.id}/projects/`);
      return;
    }
  }, [orgId, currentUser]);

  return (
    <div className="flex">
      <div className="w-full">
        <header className="sticky top-0 z-50 w-full">
          <TopBar />
        </header>
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedRoute;
