import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { type SubmitHandler } from 'react-hook-form/dist/types';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowBackIosNew } from '@mui/icons-material';
import { Alert, Button, Tooltip } from '@mui/material';
import { HelpCircle } from 'lucide-react';
import z from 'zod';

import { toastPromise } from '../../common/utils';
import {
  useGetPreDefinedExpenseById,
  useGetPreDefinedExpenses,
  useGetUserDefinedExpenseById,
  useUpdateUserDefinedExpense,
} from '../../hooks/useExpenseHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { handleQueryError } from '../../utils/api';
import FormInput from '../FormInput';
import FormQueryInput from '../FormQueryInput';
import FormSelect from '../FormSelect';

const projectExpensesDetailSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  group: z.number({
    message: 'Category is required',
  }),
  annualizedAdjustment: z.string().optional(),
  formula: z.string().min(1, 'Formula is required'),
  description: z.string().optional(),
});

type ProjectExpensesDetailType = z.infer<typeof projectExpensesDetailSchema>;

const ProjectExpensesDetail = () => {
  const { expenseId, orgId, projectId } = useGetRouteParams();
  const [error, setError] = useState(Object || null);

  const formulaFieldName = 'formula';
  const formulaErrorMessage = error?.[formulaFieldName]?.[0];

  const navigate = useNavigate();

  const form = useForm<ProjectExpensesDetailType>({
    resolver: zodResolver(projectExpensesDetailSchema),
    defaultValues: {
      name: '',
      group: NaN,
      formula: '',
      description: '',
      annualizedAdjustment: '0',
    },
  });

  const { reset, handleSubmit, formState } = form;

  const { isDirty } = formState;

  const { data: predefinedExpenses } = useGetPreDefinedExpenses(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const { data: predefinedExpense } = useGetPreDefinedExpenseById(
    {
      expenseId,
      orgId,
    },
    {
      enabled: !!expenseId && !!orgId,
    }
  );

  const { data: userdefinedExpense } = useGetUserDefinedExpenseById(
    {
      expenseId,
      orgId,
      projectId,
    },
    {
      enabled: !!expenseId && !!orgId && !!projectId,
    }
  );

  const { mutateAsync: updateUserDefinedExpense } = useUpdateUserDefinedExpense(
    {
      onSuccess() {
        setError(null);
      },
      onError(error) {
        handleQueryError(error, setError);
      },
    }
  );

  const expense = userdefinedExpense || predefinedExpense;

  const groups = useMemo(
    () =>
      Array.from(new Set(predefinedExpenses?.map((p) => p.group.name)))
        .map((e) => {
          const expense = predefinedExpenses?.find((g) => g.group.name === e);

          if (!expense) {
            return null;
          }

          return {
            label: expense.group.name,
            value: expense.group.id,
          };
        })
        .filter((e) => e) as { label: string; value: number }[],
    [predefinedExpenses]
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (expense) {
      reset({
        name: expense.name,
        group: expense.group.id,
        description: expense.description,
        formula: expense.formula,
      });
    }
  }, [expense]);

  const onSubmit: SubmitHandler<ProjectExpensesDetailType> = async (
    data: ProjectExpensesDetailType
  ) => {
    if (!isDirty) {
      setError(null);
      return;
    }

    const res = updateUserDefinedExpense({
      expenseId,
      orgId,
      projectId,
      data: {
        ...data,
        project: projectId,
      },
    });

    toastPromise({
      promise: res,
      content: 'Expense updated successfully!',
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="px-12 flex flex-col">
        <div className="flex items-center justify-between">
          <div
            onClick={handleGoBack}
            className="flex items-center gap-4 cursor-pointer"
          >
            <ArrowBackIosNew
              style={{
                height: '16px',
                width: '16px',
              }}
            />
            <h1 className="text-2xl font-bold">{expense?.name}</h1>
          </div>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </div>
        <div className="grid grid-cols-3 gap-2 py-4">
          <div className="col-span-1">
            <FormInput name="name" label="Name" />
          </div>
          <div className="col-span-1">
            <FormSelect options={groups} name="group" label="Category" />
          </div>
          <div className="col-span-1">
            <FormInput
              type="number"
              name="annualizedAdjustment"
              label="Annualized Adjustment"
            />
          </div>
          <div className="col-span-3">
            <FormInput
              name="description"
              label="Description"
              multiline
              rows={4}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex gap-2 items-center">
            <h2 className="text-2xl font-bold">Formula</h2>
            <Tooltip
              title="Start by typing $ to bring up the variant selector. Choose your desired variant, then enter the mathematical operation you want to perform."
              placement="top-end"
              arrow
            >
              <HelpCircle size={20} color="#2196F3" />
            </Tooltip>
          </div>
          <FormQueryInput name={formulaFieldName} />
        </div>
        <div className="py-4">
          {formulaErrorMessage && (
            <Alert severity="error">{formulaErrorMessage}</Alert>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default ProjectExpensesDetail;
