import React, { useMemo, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem as MuiMenuItem,
  styled,
} from '@mui/material';
import { AxiosError } from 'axios';
import { UserPlus } from 'lucide-react';

import { type TypeProjectMember } from '../../common/dataTypes';
import { ProjectRolesOptions, toastPromise } from '../../common/utils';
import { useGetProjects } from '../../hooks';
import {
  useGetOrgMemberById,
  useGetOrgMembers,
} from '../../hooks/useOrganizationHook';
import {
  useCreateProjectMember,
  useGetProjectMembers,
  useUpdateProjectMember,
} from '../../hooks/useProjectsHook';
import { useGetUser } from '../../hooks/useUserHook';
import { isOrgAdmin, useGetRouteParams } from '../../hooks/useUtilsHook';
import { type ProjectRole } from '../../types';
import CustomFormAutoComplete, {
  StyledSelect,
} from '../CustomFormAutoComplete';

const MenuItem = styled(MuiMenuItem)({
  fontSize: '14px',
});

type InviteProjectFormDialogProps = {
  open: boolean;
  inviteUserMode: 'single' | 'multiple';
  handleClose: () => void;
  handleOpen: () => void;
  showTrigger?: boolean;
  iProjectId?: number;
  iUser?: {
    id: number;
    email: string;
    fullName: string;
  };
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
  };
};

const InviteProjectFormDialog = ({
  open,
  handleClose,
  handleOpen,
  trigger,
  iProjectId,
  iUser,
  inviteUserMode,
  showTrigger = true,
}: InviteProjectFormDialogProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const form = useForm({
    defaultValues: {
      role: 'project_owner',
      email: '',
      projects: {},
      users: [],
    },
  });

  const { handleSubmit, formState, setError, reset, register, watch } = form;

  const role = watch('role') as ProjectRole;

  const [searchParams] = useSearchParams();

  const { isSubmitting, errors } = formState;

  const { orgId, projectId: routeProjectId, userId } = useGetRouteParams();

  const projectId = iProjectId || routeProjectId;

  const type = searchParams.get('type');

  const { data: member } = useGetOrgMemberById(
    {
      orgId,
      memberId: userId,
    },
    {
      enabled: !!userId && !!orgId && type === 'member',
    }
  );

  const { data: currentUser } = useGetUser();

  const { data: projectMembers } = useGetProjectMembers(
    {
      orgId,
      projectId: iProjectId || projectId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const { data: orgMembers } = useGetOrgMembers(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const { data: projects } = useGetProjects(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const currentProject = useMemo(
    () => projects?.find((p) => p.id === projectId),
    [projects, projectId]
  );

  const { mutateAsync: updateProjectMember } = useUpdateProjectMember();

  const { mutateAsync: createProjectMember } = useCreateProjectMember({
    onSuccess() {
      handleCloseDialog();
      reset();
    },
    onError(error) {
      if (error instanceof AxiosError) {
        setError('email', {
          message: error.response.data['email'][0],
        });
      }
    },
  });

  const handleCloseDialog = () => {
    reset();
    setErrorMessage(null);
    handleClose();
  };

  const handleChangeRole = ({
    member,
    role,
  }: {
    member: TypeProjectMember;
    role: ProjectRole;
  }) => {
    const res = updateProjectMember({
      orgId,
      projectId,
      memberId: member.id,
      data: {
        role,
      },
    });

    toastPromise({
      promise: res,
      content: `${member.user.first_name} ${member.user.last_name} role updated to ${ProjectRolesOptions.find((p) => p.value === role)?.label}`,
    });
  };

  const onSubmit: SubmitHandler<any> = async () => {
    if (inviteUserMode === 'single') {
      if (!iUser) {
        return;
      }

      const formProjects: Record<
        string,
        {
          checked: boolean;
          role: ProjectRole;
        }
      > = watch('projects');

      const selectedProjectIds = Object.keys(formProjects).filter(
        (key) => formProjects[key].checked
      );

      if (!selectedProjectIds.length) {
        setErrorMessage('You must select at least one project');
        return;
      }

      const res = Promise.all(
        selectedProjectIds.map((key) =>
          createProjectMember({
            orgId,
            projectId: Number(key),
            data: {
              email: iUser.email,
              user: iUser.id ?? userId,
              role: formProjects[key].role,
              project: Number(key),
            },
          })
        )
      );

      toastPromise({
        promise: res,
        content: 'User added to project(s) successfully',
      });

      return;
    }

    if (inviteUserMode === 'multiple') {
      const formUsers: {
        id: number;
        email: string;
        avatar: string;
        fullName: string;
      }[] = watch('users');

      if (!role) {
        setErrorMessage('You must select a role');
        return;
      }

      if (!formUsers.length) {
        setErrorMessage('You must enter at least one email');
        return;
      }

      const res = Promise.all(
        formUsers.map((u) => {
          createProjectMember({
            orgId,
            projectId,
            data: {
              email: u.email,
              user: u.id,
              project: projectId,
              role,
            },
          });
        })
      );

      toastPromise({
        promise: res,
        content: 'User(s) added to project(s) successfully',
      });

      return;
    }
  };

  const projectTitle = currentProject?.name ?? 'project(s)';

  const memberTitle = member
    ? member.user.first_name + ' ' + member.user.last_name
    : 'member(s)';

  const members = useMemo(
    () =>
      orgMembers?.filter(
        (o) => !projectMembers?.find((p) => p.user.id === o.user.id)
      ),
    [projectMembers, orgMembers]
  );

  return (
    <>
      {showTrigger && (
        <button
          type="button"
          onClick={handleOpen}
          style={{
            color: 'white',
            backgroundColor: '#B8341B',
            display: 'flex',
            gap: 4,
            borderRadius: 4,
            padding: '6px 12px',
            ...trigger?.style,
          }}
        >
          {trigger?.label ?? (
            <div className="flex w-fit items-center gap-2">
              <UserPlus size={20} />
              <p className="w-fit">Add members</p>
            </div>
          )}
        </button>
      )}
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle className="w-full">
          <h2 className="pt-2 text-2xl font-bold">
            Add {memberTitle} to{' ' + projectTitle}
          </h2>
        </DialogTitle>
        <DialogContent>
          <div>
            <FormProvider {...form}>
              <form className="mb-2 space-y-6">
                <div className="flex flex-col gap-2">
                  {members && (
                    <CustomFormAutoComplete
                      disabled={inviteUserMode === 'single'}
                      placeholder="Type or select emails"
                      defaultValue={
                        iUser && {
                          id: iUser.id,
                          fullName: iUser.fullName,
                          email: iUser.email,
                          avatar: '',
                        }
                      }
                      selectFieldName="role"
                      options={members.map((m) => ({
                        id: m.user.id,
                        fullName: `${m.user.first_name} ${m.user.last_name}`,
                        avatar: m.user.profile_pic,
                        email: m.user.email,
                      }))}
                    />
                  )}
                  {errors['email'] && (
                    <p className="px-2 text-sm text-primary-red">
                      {errors['email'].message as string}
                    </p>
                  )}
                </div>
                {!iProjectId && (
                  <div className="px-3">
                    <FormGroup>
                      {projects
                        ?.filter(
                          (p) =>
                            !member?.project_memberships
                              .map((pr) => pr.project.id)
                              .includes(p.id)
                        )
                        ?.map((project) => (
                          <FormControlLabel
                            style={{
                              width: '100%',
                            }}
                            key={project.id}
                            sx={{
                              '.MuiFormControlLabel-label': {
                                width: '100%',
                              },
                            }}
                            control={
                              <Checkbox
                                {...register(
                                  `projects.${project.id}.checked` as any
                                )}
                              />
                            }
                            label={
                              <div className="flex !w-full justify-between items-center">
                                <p>{project.name}</p>
                                <StyledSelect
                                  {...register(
                                    `projects.${project.id}.role` as any
                                  )}
                                  defaultValue={ProjectRolesOptions[0].value}
                                  size="small"
                                  placeholder="Please select"
                                >
                                  {ProjectRolesOptions.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      <span className="capitalize text-sm">
                                        {option.label}
                                      </span>
                                    </MenuItem>
                                  ))}
                                </StyledSelect>
                              </div>
                            }
                          />
                        ))}
                    </FormGroup>
                  </div>
                )}
                {projectMembers?.map((m) => {
                  const currentOrgMembership = currentUser?.organizations.find(
                    (o) => o.id === orgId
                  );

                  const isAllowEdit =
                    currentUser?.id !== m.user.id &&
                    currentOrgMembership &&
                    isOrgAdmin(currentOrgMembership.role);

                  return (
                    <div
                      key={m.id}
                      className="flex items-center justify-between"
                    >
                      <div className="flex items-center gap-3">
                        <Avatar src={m.user.profile_pic} alt="member avatar" />
                        <div className="flex flex-col">
                          <label className="font-semibold">
                            {m.user.first_name} {m.user.last_name}
                          </label>
                          <p className="text-xs text-[#666]">{m.user.email}</p>
                        </div>
                      </div>
                      {isAllowEdit ? (
                        <StyledSelect
                          id="demo-simple-select"
                          size="small"
                          defaultValue={m.role}
                          onChange={(e) =>
                            handleChangeRole({
                              member: m,
                              role: e.target.value as ProjectRole,
                            })
                          }
                        >
                          <MenuItem value="project_owner">
                            Project Owner
                          </MenuItem>
                          <MenuItem value="project_member">
                            Project Member
                          </MenuItem>
                        </StyledSelect>
                      ) : (
                        <div className="px-4 text-sm">
                          {
                            ProjectRolesOptions.find(
                              ({ value }) => value === m.role
                            )?.label
                          }
                        </div>
                      )}
                    </div>
                  );
                })}
                {errorMessage && (
                  <div className="w-full text-center">
                    <p className="text-primary-red">{errorMessage}</p>
                  </div>
                )}
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialog}
                    variant="outlined"
                    disabled={isSubmitting}
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    onClick={handleSubmit((event) => onSubmit(event))}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    Add
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InviteProjectFormDialog;
