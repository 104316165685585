import React, { useEffect, useState } from 'react';

import { ArrowUpward } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useGetOrgSharedRequestById } from '../../../hooks/useOrganizationHook';
import { type ApproveStatus } from '../../../types';
import cn from '../../../utils/cn';
import { useCurrentOrganization } from '../../../utils/helpers';
import TanstackTable from '../../TanstackTable';

export type DataRow = {
  id: number;
  name: string;
  status: ApproveStatus;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('name', {
    cell: (info) => {
      const name = info.getValue();

      return (
        <div className="flex w-full items-center justify-between">{name}</div>
      );
    },
    header: () => (
      <div className="flex items-center gap-1">
        <p>Receiver Name</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('status', {
    cell: (info) => {
      const status = info.getValue();

      const StatusLabel = ({
        label,
        value,
      }: {
        value: 'pending' | 'approved' | 'disapproved';
        label: string;
      }) => (
        <div className="flex justify-start py-2">
          <p
            className={cn(
              'w-fit rounded-full bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#EEF6FF] text-[#117DD3]': value === 'approved',
                'text-[#C38D04] bg-[#FFF8E0]': value === 'pending',
                'bg-[#FFF4F2] text-primary-red': value === 'disapproved',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      return (
        <div className="flex w-full items-center justify-between">
          {status === 'Approved' ? (
            <StatusLabel value="approved" label="Approved" />
          ) : null}
          {status === 'Pending' ? (
            <StatusLabel value="pending" label="Pending" />
          ) : null}
          {status === 'Disapproved' ? (
            <StatusLabel value="disapproved" label="Disapproved" />
          ) : null}
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-1">
        <p>Status</p>
      </div>
    ),
  }),
];

const MemberApprovalListDialog = ({ id }: { id: number }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<DataRow[]>([]);

  const currentOrg = useCurrentOrganization();

  const {
    data: request,
    isLoading: isFetchingSharedRequests,
    isRefetching: isRefetchingSharedRequests,
  } = useGetOrgSharedRequestById(
    {
      orgId: Number(currentOrg?.id),
      requestId: Number(id),
    },
    {
      enabled: !!currentOrg?.id || !!id,
    }
  );

  useEffect(() => {
    if (request?.sharing_invitations) {
      setData(
        request.sharing_invitations.map((i) => ({
          id: i.id,
          name: i.email,
          status: i.approve_status,
        }))
      );
    }
  }, [request]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <button onClick={handleOpenDialog} className="text-[#2196F3]">
        View details
      </button>
      <Dialog open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
        <div className="flex flex-col gap-6 p-12">
          <h2 className="text-xl font-semibold">Members Approval List</h2>
          <TanstackTable
            table={table}
            isLoading={isFetchingSharedRequests || isRefetchingSharedRequests}
            emptyText={'No available shared requests.'}
          />
        </div>
      </Dialog>
    </>
  );
};

export default MemberApprovalListDialog;
