import { CircleAlert, CircleCheck } from 'lucide-react';
import { toast } from 'sonner';

import { type Aircraft } from '../components/dataset/Aircraft/AircraftTable';
import { type OrganizationRole, type ProjectRole } from '../types';

import { type TypeIFSConfiguration } from './dataTypes';

export function formatDate(dateString: string) {
  return new Date(dateString).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export const ProjectRolesOptions = [
  {
    value: 'project_owner',
    label: 'Project Owner',
  },
  {
    value: 'project_member',
    label: 'Project Member',
  },
] as {
  value: ProjectRole;
  label: string;
}[];

export const OrganizationRolesOptions = [
  {
    value: 'organization_admin',
    label: 'Organization Admin',
  },
  {
    value: 'organization_member',
    label: 'Organization Member',
  },
  {
    value: 'organization_owner',
    label: 'Organization Owner',
  },
] as {
  value: OrganizationRole;
  label: string;
}[];

export const extractValue = (obj: Record<string, string | string[]>) => {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      return obj[key][0] || null;
    } else if (typeof obj[key] === 'string') {
      return obj[key] || null;
    }
  }
  return null;
};

export const formatIfsConfig = (data: TypeIFSConfiguration) => {
  const {
    id,
    granularity,
    start_period,
    end_period,
    duration,
    positions,
    aircrafts,
    retirement_age,
    years_of_service,
    labor_group,
    seat_lock_period,
  } = data;

  const ifsConfigData = {
    id,
    granularity,
    startDate: start_period,
    endDate: end_period,
    startPeriodYear: start_period,
    startPeriodMonth: '',
    startPeriodWeek: '',
    seatLockPeriod: seat_lock_period,
    positions: positions.reduce(
      (
        acc: string[],
        pos: {
          name: string;
        }
      ) => {
        acc.push(pos.name.trim());
        return acc;
      },
      [] as string[]
    ),
    aircrafts: aircrafts.reduce(
      (
        acc: Aircraft[],
        aircraft: { name: string; airports: string[] },
        index
      ) => {
        acc.push({
          aircraft: aircraft.name,
          airports: aircraft.airports,
          hierarchy: index + 1,
        });
        return acc;
      },
      [] as Aircraft[]
    ),
    retirementAge: retirement_age,
    yearsOfService: years_of_service,
    duration,
    laborGroup: labor_group,
  };

  return ifsConfigData;
};

export const toastPromise = ({
  promise,
  content,
}: {
  promise: Promise<any>;
  content: string;
}) =>
  toast.promise(promise, {
    success: (data) => (
      <div className="text-white flex w-full gap-2 rounded-[4px] bg-[#2E7D32] p-3">
        <div className="flex items-center">
          <CircleCheck />
        </div>
        <div className="flex flex-col">
          <span className="text-lg font-bold">Success</span>
          <p className="text-sm font-light">{content}</p>
        </div>
      </div>
    ),
    error: (data) => (
      <div className="text-white flex w-full gap-2 rounded-[4px] bg-[#FF816A] p-3">
        <div className="flex items-center">
          <CircleAlert />
        </div>
        <div className="flex flex-col">
          <span className="text-lg font-bold">Error</span>
          <p className="text-sm font-light">{data.message}</p>
        </div>
      </div>
    ),
    unstyled: true,
  });
