import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Alert, Button } from '@mui/material';
import * as z from 'zod';

import { type TypeProject } from '../../common/dataTypes';
import { extractValue } from '../../common/utils';
import { useCreateProject } from '../../hooks';
import { useUpdateProject } from '../../hooks/useProjectsHook';
import { useGetRoles, useGetRouteParams } from '../../hooks/useUtilsHook';
import { handleQueryError } from '../../utils/api';
import FormInput from '../FormInput';

const CreateProjectSchema = z.object({
  name: z.string().trim().trim().min(1, 'Project name is required'),
  description: z.string().trim().optional(),
});

type CreateProjectSchemaType = z.infer<typeof CreateProjectSchema>;

type CreateProjectFormProps = {
  primaryActionLabel?: string;
  handleOnCancel?: () => void;
  handleOnSubmit?: (
    data:
      | TypeProject
      | {
          id: number;
        }
  ) => void;
  defaultValues?: CreateProjectSchemaType & {
    id: number;
  };
};

const CreateProjectForm = ({
  handleOnSubmit,
  handleOnCancel,
  defaultValues,
  primaryActionLabel,
}: CreateProjectFormProps) => {
  const [error, setError] = useState(Object);

  const errorMessage = extractValue(error);

  const { orgId } = useGetRouteParams();

  const form = useForm<CreateProjectSchemaType>({
    resolver: zodResolver(CreateProjectSchema),
    defaultValues,
  });

  const { handleSubmit, reset, formState } = form;

  const { isDirty } = formState;

  const { projectRole } = useGetRoles();

  const { mutateAsync: createProject, isPending: isCreatingProject } =
    useCreateProject({
      onSuccess(data) {
        handleOnSubmit?.(data);
      },
      onError: (error) => {
        handleQueryError(error, setError);
      },
    });

  const { mutateAsync: updateProject, isPending: isUpdatingProject } =
    useUpdateProject({
      onSuccess(data) {
        handleOnSubmit?.(data);
      },
      onError: (error) => {
        handleQueryError(error, setError);
      },
    });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handleCreateProject: SubmitHandler<CreateProjectSchemaType> = async (
    data
  ) => {
    if (!isDirty && defaultValues) {
      handleOnSubmit?.({
        id: defaultValues.id,
      });
      return;
    }

    if (!orgId) {
      return;
    }

    if (defaultValues) {
      await updateProject({
        projectId: defaultValues.id,
        orgId,
        data: {
          ...data,
          organization: orgId,
        },
      });

      return;
    }

    await createProject({
      orgId,
      data: {
        ...data,
        organization: orgId,
      },
    });
  };

  const isDisabled = projectRole ? projectRole !== 'project_owner' : false;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(handleCreateProject)}
        className="space-y-4 px-6 pb-4"
      >
        <FormInput
          label="Name"
          inputProps={{
            maxLength: 100,
          }}
          disabled={isDisabled}
          name="name"
        />
        <FormInput
          label="Description"
          multiline
          rows={4}
          disabled={isDisabled}
          placeholder="(Optional)"
          name="description"
        />
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <div className="flex justify-end gap-4">
          <Button
            onClick={handleOnCancel}
            variant="outlined"
            style={{
              color: '#666',
              borderColor: '#B3B3B3',
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isCreatingProject ?? isUpdatingProject}
            type="submit"
            disabled={isDisabled}
            variant="contained"
          >
            {primaryActionLabel ??
              (defaultValues
                ? isDirty
                  ? 'Update'
                  : 'Next'
                : 'Create project')}
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateProjectForm;
