import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import { Dot, EllipsisVertical, Info } from 'lucide-react';

import { type TypeScenario } from '../../common/dataTypes';
import { useGetDataTableByName } from '../../hooks/useDataTablesHook';
import {
  useDeleteScenarioById,
  useGetBaseScenario,
  useGetScenariosByDataTableName,
} from '../../hooks/useScenariosHook';
import {
  useGetDistinguishModel,
  useGetRouteParams,
  useGetSearchResults,
} from '../../hooks/useUtilsHook';
import cn from '../../utils/cn';
import AlertDialog from '../dataset/AlertDialog';
import MenuDropdown from '../MenuDropdown';
import CreateScenarioFormDialog from '../scenario/CreateScenarioFormDialog';
import SearchInput from '../SearchInput';
import ConfirmDeleteScenarioDialog from '../SimpleConfirmDeleteDialog';

const ScenariosDrawerSection = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const open = Boolean(anchorEl);

  const [searchParams, setSearchParams] = useSearchParams();

  const { inputTableName, orgId, projectId, modelId, scenarioId } =
    useGetRouteParams();

  const [openDialogCreateScenario, setOpenDialogCreateScenario] =
    useState(false);

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const form = useForm();

  const { watch } = form;

  const { data: scenarios } = useGetScenariosByDataTableName(
    {
      modelId,
      orgId,
      projectId,
      dataTableName: inputTableName ?? '',
    },
    {
      enabled: !!modelId && !!orgId && !!projectId && !!inputTableName,
    }
  );

  const { model } = useGetDistinguishModel();

  const { data: dataTable } = useGetDataTableByName(
    {
      dataTableName: inputTableName ?? '',
      orgId,
      projectId,
      modelId,
    },
    {
      enabled: !!inputTableName && !!orgId && !!projectId && !!modelId,
    }
  );

  const { data: baseScenario } = useGetBaseScenario(
    {
      orgId,
      modelId,
      projectId,
      dataTableName: inputTableName ?? '',
    },
    {
      enabled: !!orgId && !!modelId && !!projectId && !!inputTableName,
    }
  );

  const { mutate: deleteScenario, isPending: isDeletingScenario } =
    useDeleteScenarioById({
      onSuccess: () => {
        if (baseScenario) {
          searchParams.set('scenario_id', baseScenario.id.toString());
          setSearchParams(searchParams);
        }

        setOpenConfirmDeleteDialog(false);
      },
      onError: () => {
        setOpenConfirmDeleteDialog(false);
      },
    });

  const data = useMemo(
    () =>
      baseScenario && scenarios
        ? [
            {
              ...baseScenario,
              is_base: true,
            } as TypeScenario,
          ].concat(scenarios as TypeScenario[])
        : undefined,
    [scenarios, baseScenario]
  );

  const searchResults = useGetSearchResults<TypeScenario>({
    searchValue: watch('search'),
    data,
  });

  const handleCloseDropdown = () => setAnchorEl(null);

  const handleOpenConfirmDeleteDialog = () => setOpenConfirmDeleteDialog(true);

  const handleOpenDialogCreateScenario = () => {
    setOpenDialogCreateScenario(true);
  };

  const handleCloseDialogCreateScenario = () => {
    setOpenDialogCreateScenario(false);
  };

  const handleCloseConfirmDeleteDialog = () =>
    setOpenConfirmDeleteDialog(false);

  const handleOpenAlertDialog = () => setOpenAlertDialog(true);

  const handleCloseAlertDialog = () => setOpenAlertDialog(false);

  const handleSelect = (scenarioId: number) => {
    searchParams.set('scenario_id', scenarioId.toString());

    setSearchParams(searchParams);
  };

  const handleDelete = async () => {
    if (!scenarioId || !orgId || !projectId || !modelId) {
      return;
    }

    deleteScenario({
      orgId,
      projectId,
      scenarioId,
      modelId,
    });
  };

  const handleSubmitCreateScenario = (data: TypeScenario) => {
    searchParams.set('scenario_id', data.id.toString());
    setSearchParams(searchParams);
  };

  const drawerItems = useMemo(
    () => searchResults ?? data,
    [searchResults, data]
  );

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-3 px-3 py-6">
        <form className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <span className="w-full text-lg font-semibold text-[#E8F2FF]">
              Scenario
            </span>
            {dataTable?.support_vectors && !model?.is_base ? (
              <CreateScenarioFormDialog
                title="Create A New Scenario"
                open={openDialogCreateScenario}
                handleSubmitDialog={handleSubmitCreateScenario}
                handleCloseDialog={handleCloseDialogCreateScenario}
                handleOpenDialog={handleOpenDialogCreateScenario}
              />
            ) : (
              <button type="button" onClick={handleOpenAlertDialog}>
                <Info width={20} height={20} color="#E8F2FF" />
              </button>
            )}
          </div>
          <AlertDialog
            title="Scenarios Not Supported"
            open={openAlertDialog}
            content={
              'This table is for viewing only, you cannot create a scenario'
            }
            handleOnClose={handleCloseAlertDialog}
          />
          <SearchInput className="border-none bg-[#21438C] text-[#E8F2FF] placeholder-[#E8F2FF]" />
        </form>
        <div className="py-1">
          {drawerItems?.map((s) => {
            const isActive = s.is_active ?? baseScenario?.is_active;

            return (
              <button
                type="button"
                onClick={() => handleSelect(s.id)}
                className={cn(
                  'flex text-[#E8F2FF] items-center transition w-full rounded-[4px] pr-3 py-2',
                  {
                    'bg-[#21438C]': s.id === Number(scenarioId),
                  }
                )}
              >
                <div>
                  <Dot
                    className={cn('opacity-0', {
                      'opacity-100': isActive,
                    })}
                  />
                </div>
                <div className="flex items-center justify-between w-full">
                  <p className="whitespace-normal text-start">{s.name}</p>
                  {Number(scenarioId) === s.id && !s.is_base && (
                    <div>
                      <MenuDropdown
                        trigger={
                          <button type="button">
                            <EllipsisVertical width={20} height={20} />
                          </button>
                        }
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        style={{
                          marginTop: '5px',
                          marginLeft: '-5px',
                        }}
                        open={open}
                      >
                        <MenuItem
                          onClick={() => {
                            handleCloseDropdown();
                            handleOpenConfirmDeleteDialog();
                          }}
                        >
                          Delete
                        </MenuItem>
                      </MenuDropdown>
                    </div>
                  )}
                </div>
              </button>
            );
          })}
        </div>
      </div>
      <ConfirmDeleteScenarioDialog
        open={openConfirmDeleteDialog}
        handleOnClose={handleCloseConfirmDeleteDialog}
        handleOnSubmit={handleDelete}
        isLoading={isDeletingScenario}
      />
    </FormProvider>
  );
};

export default ScenariosDrawerSection;
