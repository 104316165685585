import React, { useState } from 'react';

import { Avatar, Badge, Popover } from '@mui/material';
import { formatDistance } from 'date-fns';
import { CheckCheck } from 'lucide-react';

import { type TypeReceivedSharedInvitation } from '../common/dataTypes';
import {
  useAcceptReceivedSharingInvitation,
  useGetReceivedSharingInvitations,
  useRejectReceivedSharingInvitation,
} from '../hooks/useReceviedSharingInvitations';
import { useGetRouteParams } from '../hooks/useUtilsHook';
import Notification from '../icons/Notification';
import { useCurrentOrganization } from '../utils/helpers';

import { Tab, Tabs } from './mui-custom';

const tabs = ['All', 'Unread'];

type NotificationDropdownProps = {
  children?: React.ReactNode;
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={value} {...other}>
      {value === index && children}
    </div>
  );
};

const ReceivedNotification = ({
  project,
}: {
  project: TypeReceivedSharedInvitation;
}) => {
  const sender = project.shared_by;

  const currentOrg = useCurrentOrganization();

  const { mutate: acceptSharingInvitation } =
    useAcceptReceivedSharingInvitation();

  const { mutate: rejectSharingInvitation } =
    useRejectReceivedSharingInvitation();

  const handleAcceptSharedInvitation = () => {
    acceptSharingInvitation({
      orgId: Number(currentOrg?.id),
      invitationId: project.id,
    });
  };

  const handleRejectSharedProject = () => {
    rejectSharingInvitation({
      orgId: Number(currentOrg?.id),
      invitationId: project.id,
    });
  };

  return (
    <div className="flex gap-2 rounded-lg bg-[#F5F9FF] p-3 px-4">
      <div>
        <Avatar src={sender?.profile_pic} alt="avatar" />
      </div>
      <div className="flex w-full flex-col gap-1">
        <p className="text-sm">
          {sender?.first_name + ' ' + sender?.last_name} has shared the model:{' '}
          <span className="text-sm font-semibold">{project.model.name}</span>
        </p>
        <p className="text-xs">
          {formatDistance(new Date(), project.created_at, {
            addSuffix: true,
          })}
        </p>
        {project.accept_status === 'Accepted' && (
          <div className="w-fit text-[#2196F3]">Accepted</div>
        )}
        {project.accept_status === 'Pending' && (
          <div className="mt-2 flex justify-between gap-3 text-sm font-semibold">
            <button
              onClick={handleAcceptSharedInvitation}
              className="w-full rounded-full border border-[#2196F3] py-1 text-[#2196F3]"
            >
              Accept
            </button>
            <button
              onClick={handleRejectSharedProject}
              className="w-full rounded-full border border-[#CCC] py-1 text-[#666]"
            >
              Deny
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const NotificationDropdown = ({ children }: NotificationDropdownProps) => {
  const [index, setIndex] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { orgId } = useGetRouteParams();

  const { data: sharedInvitations } = useGetReceivedSharingInvitations(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  return (
    <div>
      <button
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Badge variant="dot" color="error" invisible={true}>
          <Notification />
        </Badge>
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{
          marginTop: '17px',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex w-[360px] flex-col p-3 py-5">
          <h1 className="text-xl font-semibold">Notification</h1>
          <div className="flex justify-between">
            <Tabs value={index} onChange={handleChange}>
              {tabs.map((tab, i) => (
                <Tab id={i.toString()} key={tab} label={tab} />
              ))}
            </Tabs>
            <div className="flex items-center gap-2">
              <CheckCheck color="#0B9919" width={20} height={20} />
              <button className="text-sm font-semibold text-[#0B9919]">
                Mark all as read
              </button>
            </div>
          </div>
          <TabPanel value={index.toString()} index="0">
            <div className="space-y-3">
              {sharedInvitations?.map((project) => (
                <ReceivedNotification project={project} />
              ))}
            </div>
          </TabPanel>
        </div>
      </Popover>
    </div>
  );
};

export default NotificationDropdown;
