import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ArrowUpward } from '@mui/icons-material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import { useGetOrgSharedRequests } from '../../../hooks/useOrganizationHook';
import {
  isOrgAdmin,
  useCustomNavigate,
  useGetRouteParams,
  useGetSearchResults,
} from '../../../hooks/useUtilsHook';
import routes from '../../../routes';
import { useCurrentOrganization } from '../../../utils/helpers';
import TanstackTable from '../../TanstackTable';

export type DataRow = {
  id: number;
  name: string;
  project: string;
  requester: string;
  numberOfReceivers: number;
  createdAt: string;
  action: React.ReactNode | null;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('name', {
    cell: (info) => (
      <div className="group flex items-center gap-2 py-3 text-start">
        <p className="w-full">{info.getValue()}</p>
      </div>
    ),
    header: () => (
      <div className="items-center flex gap-2">
        <p>Model</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('project', {
    id: 'project',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      ),
    header: () => <div>Project</div>,
  }),
  columnHelper.accessor('requester', {
    id: 'requester',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      ),
    header: () => <div>Requester</div>,
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'Y/MM/dd h:mm a')}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      ),
    header: () => <div>Date Requested</div>,
  }),
  columnHelper.accessor('numberOfReceivers', {
    id: 'numberOfReceivers',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      ),
    header: () => <div>No. of Receivers</div>,
  }),
  columnHelper.accessor('action', {
    id: 'action',
    cell: (info) => {
      const [searchParams] = useSearchParams();
      const id = info.row.original.id;

      const tab = searchParams.get('tab');

      const { orgId } = useGetRouteParams();

      const navigate = useCustomNavigate();

      return (
        <div className="w-full">
          <a
            onClick={() =>
              navigate(`${routes.sharedModelVault.detail}?tab=${tab}`, {
                org_id: orgId,
                request_id: id,
              })
            }
            className="text-[#2196F3]"
          >
            View details
          </a>
        </div>
      );
    },
    header: () => <div>Action</div>,
  }),
];

const PendingRequestsTable = ({ searchKey }: { searchKey: string }) => {
  const [data, setData] = useState<DataRow[]>([]);

  const currentOrg = useCurrentOrganization();

  const { data: sharedRequests, isLoading: isFetchingSharedRequests } =
    useGetOrgSharedRequests(currentOrg?.id ?? NaN, {
      enabled: !!currentOrg?.id && currentOrg && isOrgAdmin(currentOrg.role),
    });

  const searchResults = useGetSearchResults<DataRow>({
    searchValue: searchKey,
    data,
    filterFields: ['project', 'name', 'requester'],
  });

  useEffect(() => {
    if (sharedRequests) {
      setData(
        sharedRequests.map((r) => ({
          id: r.id,
          name: r.model.name,
          project: r.project.name,
          requester: r.shared_by?.first_name + ' ' + r.shared_by?.last_name,
          numberOfReceivers: r.sharing_invitations.length,
          createdAt: format(r.updated_at, 'Y/MM/dd h:mm a'),
        })) as DataRow[]
      );
    }
  }, [sharedRequests]);

  const table = useReactTable({
    data: searchResults ?? data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TanstackTable
      table={table}
      isLoading={isFetchingSharedRequests}
      emptyText={'No available shared requests.'}
    />
  );
};

export default PendingRequestsTable;
