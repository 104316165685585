import React, { type SyntheticEvent, useState } from 'react';

import { type TypeDataset } from '../common/dataTypes';
import { useGetIFS } from '../hooks/useIFSHook';
import { useGetRouteParams } from '../hooks/useUtilsHook';

import ReviewIFSTable from './dataset/ReviewIFSTable';
import { CustomTabPanel, Tab, Tabs } from './mui-custom';
import ViewFilesDataTable from './ViewFilesDataTable';

const TABS = ['Data files', 'View Data'];

type ReviewIFSFilesTabsProps = {
  dataset: TypeDataset;
};

const ReviewIFSFilesTabs = ({ dataset }: ReviewIFSFilesTabsProps) => {
  const [index, setIndex] = useState(0);

  const { orgId, projectId } = useGetRouteParams();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  const { data: ifs } = useGetIFS(
    {
      projectId,
      datasetId: dataset.id,
      orgId,
    },
    {
      enabled: !!dataset && !!orgId && !!projectId,
    }
  );

  return (
    <div className="flex flex-col gap-4">
      <Tabs value={index} onChange={handleChange}>
        {TABS.map((report) => (
          <Tab id={report} key={report} label={report} />
        ))}
      </Tabs>
      <CustomTabPanel value={index.toString()} index="0">
        {ifs && projectId && (
          <ReviewIFSTable
            projectId={projectId}
            dataset={dataset}
            ifs={{
              ...ifs,
              files: ifs.files,
            }}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="1">
        <ViewFilesDataTable />
      </CustomTabPanel>
    </div>
  );
};

export default ReviewIFSFilesTabs;
