import React, { useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import _ from 'lodash';

import { type TypeDataset } from '../../common/dataTypes';
import {
  useGetDatasetInputFiles,
  useGetDatasets,
} from '../../hooks/useDatasetsHook';
import { useGetIFS, useImportExistingIFS } from '../../hooks/useIFSHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import cn from '../../utils/cn';

type ImportExistingFilesDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const ExistingFilesCheckboxes = ({
  dataset,
  isPending,
}: {
  dataset: TypeDataset;
  isPending: boolean;
}) => {
  const [open, setOpen] = useState(true);
  const { orgId, projectId } = useGetRouteParams();

  const { register, setValue, watch } = useFormContext();

  const formData = watch();

  const { data: sortedInputFiles } = useGetDatasetInputFiles(
    {
      orgId,
      projectId,
      datasetId: dataset.id,
    },
    {
      enabled: !!orgId && !!projectId && !!dataset.id,
    }
  );

  const { data: ifsSchema } = useGetIFS(
    {
      orgId,
      projectId,
      datasetId: dataset.id,
    },
    {
      enabled: !!orgId && !!projectId && !!dataset.id,
    }
  );

  const data = useMemo(
    () =>
      ifsSchema?.files.map((file) => {
        const importedFile = sortedInputFiles?.find(
          (sortedFile) => sortedFile.name === file.name
        );

        if (importedFile) {
          return {
            ...importedFile,
            is_imported: true,
          } as {
            id: number;
            name: string;
            is_imported?: boolean;
          };
        }

        return file as unknown as {
          id: undefined;
          name: string;
          is_imported?: boolean;
        };
      }),
    [ifsSchema?.files, sortedInputFiles]
  );

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChange = ({
    datasetId,
    isChecked,
    index,
  }: {
    index: number;
    datasetId: number;
    isChecked: boolean;
  }) => {
    if (!sortedInputFiles) {
      return;
    }
    setValue(`${datasetId}.${index}.checked`, isChecked);

    Object.keys(formData)
      .filter((dKey) => !_.isEqual(Number(dKey), datasetId))
      .forEach((k) => {
        setValue(`${k}.${index}.checked`, false);
      });
  };

  return (
    <div>
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <label className="font-bold capitalize">{dataset.name}</label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="border-gray-300 mb-4 ml-[11px] mt-2 flex flex-col overflow-y-hidden border-l pl-4">
          {data?.map((i, index) => (
            <div key={i.id} className="flex justify-between">
              <div className="flex items-center gap-1">
                <Checkbox
                  disabled={isPending || i.is_imported === false}
                  id={`${dataset.id}.${i.id}`}
                  {...register(`${dataset.id}.${index}.checked`)}
                  checked={formData[dataset.id]?.[index]?.checked ?? false}
                  onChange={(e) => {
                    if (!i.id) {
                      return;
                    }

                    handleChange({
                      index,
                      datasetId: dataset.id,
                      isChecked: e.target.checked,
                    });
                  }}
                />
                <input
                  className="hidden"
                  type="text"
                  {...register(`${dataset.id}.${index}.id`)}
                  value={i.id}
                />
                <label
                  htmlFor={`${dataset.id}.${i.id}`}
                  className={cn('', {
                    'text-gray-400': i.is_imported === false,
                  })}
                >
                  {i.name}
                </label>
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

const ImportExistingFilesDialog = ({
  open,
  handleClose,
}: ImportExistingFilesDialogProps) => {
  const { orgId, projectId, datasetId: currentDatasetId } = useGetRouteParams();

  const form = useForm();

  const { handleSubmit, formState, reset } = form;

  const { isSubmitting } = formState;

  const { data: datasets } = useGetDatasets(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { mutateAsync: importExistingIFS, isPending } = useImportExistingIFS();

  const handleCloseDialog = () => {
    handleClose();
    reset();
  };

  const onSubmit = async (data: any) => {
    const promises = Object.keys(data)
      .filter((k) => k !== 'search')
      .flatMap((datasetKey) => {
        const files = data[datasetKey].filter(
          (k: { id: string; checked: boolean }) =>
            k.checked && k.id !== undefined
        );

        return files.map((file: { id: string; checked: boolean }) =>
          importExistingIFS({
            datasetId: Number(currentDatasetId),
            orgId: Number(orgId),
            projectId: Number(projectId),
            data: {
              input_file: Number(file.id),
            },
          })
        );
      });

    await Promise.all(promises);

    handleCloseDialog();
    return;
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          if (isPending) {
            return;
          }

          handleCloseDialog();
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="w-full">
          <h2 className="pt-2 text-2xl font-bold">
            Import existing file(s) from available Dataset
          </h2>
        </DialogTitle>
        <DialogContent>
          <div>
            <FormProvider {...form}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mb-2 space-y-6"
              >
                {/* <SearchInput /> */}
                <div>
                  {datasets
                    ?.filter((d) => d.id !== Number(currentDatasetId))
                    ?.map((dataset) => (
                      <ExistingFilesCheckboxes
                        key={dataset.id}
                        isPending={isPending}
                        dataset={dataset}
                      />
                    ))}
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialog}
                    disabled={isPending}
                    variant="outlined"
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    Save
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImportExistingFilesDialog;
