import React, { useEffect, useState } from 'react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { type TypeOrganization } from '../../common/dataTypes';
import { useGetOrganizations } from '../../hooks/useOrganizationHook';
import cn from '../../utils/cn';
import Spinner from '../Spinner';

export type ProjectRow = {
  id: number;
  name: string;
  memberCount: number | undefined;
  projectCount: number | undefined;
};

const defaultData: ProjectRow[] = [];

const columnHelper = createColumnHelper<ProjectRow>();

const columns = ({ handleSelect }: { handleSelect: (id: number) => void }) => [
  columnHelper.accessor('name', {
    cell: (info) => (
      <div className="group flex items-center gap-2 py-3 text-start">
        <p
          onClick={() => handleSelect(info.row.original.id)}
          className="w-full"
        >
          {info.getValue()}
        </p>
      </div>
    ),
    header: () => (
      <div className="flex items-center gap-2">
        <p>Name</p>
      </div>
    ),
  }),
  columnHelper.accessor('memberCount', {
    id: 'membersCount',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div
          onClick={() => handleSelect(id)}
          className="line-clamp-5 max-w-[250px] 2xl:max-w-[350px]"
        >
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="text-gray-400 max-w-[250px] text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Number of members</div>,
  }),
  columnHelper.accessor('projectCount', {
    id: 'projectsCount',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div
          onClick={() => handleSelect(id)}
          className="line-clamp-5 max-w-[250px] 2xl:max-w-[350px]"
        >
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="text-gray-400 max-w-[250px] text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Number of projects</div>,
  }),
];

const OrganizationsTable = ({
  defaultOrganizations,
}: {
  defaultOrganizations: TypeOrganization[] | undefined;
}) => {
  const [data, setData] = useState<ProjectRow[]>(defaultData);

  const { data: organizations, isRefetching } = useGetOrganizations();

  // @todo: Update this when doing the actual implementation for organization detail page
  const handleSelect = (id: number) => {};

  useEffect(() => {
    if (defaultOrganizations && defaultOrganizations?.length > 0) {
      setData(
        defaultOrganizations.map((org) => ({
          id: org.id,
          name: org.name,
          memberCount: org.member_count,
          projectCount: org.project_count,
        }))
      );
      return;
    }

    if (organizations) {
      setData(
        organizations.map((org) => ({
          id: org.id,
          name: org.name,
          memberCount: org.member_count,
          projectCount: org.project_count,
        }))
      );
    }
  }, [organizations, defaultOrganizations]);

  const table = useReactTable({
    data,
    columns: columns({
      handleSelect,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn(
                    'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="relative">
          {isRefetching && (
            <div className="absolute border-[#E4E7EC] h-full border flex w-full items-center justify-center opacity-50">
              <Spinner />
            </div>
          )}
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={cn('cursor-pointer text-[#333] transition py-3')}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border-b border-[#E4E7EC] px-6 first:border-l last:border-r"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!table.getRowModel().rows.length && (
        <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
          <p className="text-lg text-[#999]">No organizations available.</p>
        </div>
      )}
    </div>
  );
};

export default OrganizationsTable;
