import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { Trash2 } from 'lucide-react';

import { toastPromise } from '../../common/utils';
import { useDeleteUserDefinedExpense } from '../../hooks/useExpenseHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import routes from '../../routes';
import cn from '../../utils/cn';
import WarningDialog from '../WarningDialog';

type ExpenseItem = {
  label: string;
  value: number;
  type?: 'default' | 'custom';
};

type ExpenseCollapseProps = {
  title: string;
  items: ExpenseItem[];
  isSelectable?: boolean;
  isDeletable?: boolean;
};

const ExpenseCollapse = ({
  title,
  items,
  isSelectable = true,
  isDeletable,
}: ExpenseCollapseProps) => {
  const [open, setOpen] = useState(true);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const { orgId, projectId } = useGetRouteParams();

  const { mutateAsync: deleteExpense } = useDeleteUserDefinedExpense({
    onSuccess: () => {
      handleCloseConfirmDeleteDialog();
    },
  });

  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleOpenConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleCloseConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleSelect = (id: number) => {
    if (!isSelectable) {
      return;
    }

    navigate(
      routes.projects.expenses.detail
        .replace(':expense_id', id.toString())
        .replace(':project_id', projectId.toString())
        .replace(':org_id', orgId.toString())
    );
  };

  const handleDeleteExpense = (item: ExpenseItem) => {
    const res = deleteExpense({
      orgId,
      projectId,
      expenseId: item.value,
    });

    toastPromise({
      promise: res,
      content: `Delete expense ${item.label} successfully`,
    });
  };

  return (
    <div>
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <label className="font-bold capitalize">{title}</label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="border-gray-300 mb-4 ml-[11px] mt-2 flex flex-col overflow-y-hidden border-l pl-4">
          {items.map((e: ExpenseItem) => (
            <div key={e.value} className="flex justify-between">
              <div
                className={cn(
                  'flex transition justify-between w-full items-center gap-1 rounded-[4px]'
                )}
              >
                <div
                  className={cn(
                    'w-full p-2 text-start font-normal flex gap-2 items-center',
                    {
                      'cursor-pointer': isSelectable,
                    }
                  )}
                  onClick={() => handleSelect(e.value)}
                >
                  <p>{e.label}</p>
                  {e.type === 'custom' && (
                    <span className="rounded-md font-bold bg-[#F5F5F5] text-[#666666] px-[10px] py-1 text-sm">
                      Custom
                    </span>
                  )}
                </div>
                {isDeletable && (
                  <button type="button" onClick={handleOpenConfirmDeleteDialog}>
                    <Trash2 size={20} color="#666666" />
                  </button>
                )}
                <WarningDialog
                  open={openConfirmDeleteDialog}
                  primaryActionText={'Yes, delete it'}
                  secondaryActionText={'Cancel'}
                  handleSecondaryAction={handleCloseConfirmDeleteDialog}
                  handlePrimaryAction={() => handleDeleteExpense(e)}
                  content={
                    'If you delete this custom expense, it will be deleted from all models using it'
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default ExpenseCollapse;
