import { type ProjectRole, type Role } from '../types';
import { mainApi } from '../utils/api';

export type DeleteProjectMemberInput = {
  projectId: number;
  orgId: number;
  memberId: number;
};

export type UpdateProjectMemberInput = {
  projectId: number;
  orgId: number;
  memberId: number;
  data: {
    role: Role;
    date_joined?: string;
  };
};

export type CreateProjectMemberInput = {
  projectId: number;
  orgId: number;
  data: {
    email: string;
    user: number;
    role: ProjectRole;
    project: number;
  };
};

export type GetProjectByIdInput = {
  projectId: number;
  orgId: number;
};

export type UpdateProjectInput = {
  projectId: number;
  orgId: number;
  data: {
    name?: string;
    description?: string;
    organization: number;
  };
};

export type DeleteProjectInput = {
  projectId: number;
  orgId: number;
};

export type CreateProjectInput = {
  orgId: number;
  data: {
    name: string;
    organization: number;
    description?: string;
  };
};

export type GetProjectMembersInput = {
  projectId: number;
  orgId: number;
};

export type GetProjectMemberByIdInput = {
  projectId: number;
  orgId: number;
  memberId: number;
};

export type GetProjectsInput = {
  orgId: number;
};

export type GetProjectSharedScenariosInput = {
  projectId: number;
  orgId: number;
};

export type CompleteCreateProjectInput = {
  projectId: number;
  orgId: number;
};

export type GetProjectOutputSchemaInput = {
  projectId: number;
  orgId: number;
};

export type JoinProjectInput = {
  projectId: number;
  orgId: number;
};

export type LeaveProjectInput = {
  projectId: number;
  orgId: number;
};

export type GetProjectInvitationsInput = {
  projectId: number;
  orgId: number;
};

export type CreateProjectInvitationInput = {
  projectId: number;
  orgId: number;
  data: {
    email: string;
    role: Role;
  };
};

export type GetProjectInvitationByIdInput = {
  orgId: number;
  projectId: number;
  invitationId: number;
};

export type ResendProjectInvitationInput = {
  orgId: number;
  projectId: number;
  invitationId: number;
};

export type DeleteProjectInvitationInput = {
  orgId: number;
  projectId: number;
  invitationId: number;
};

export type VerifyProjectInvitationInput = {
  orgId: number;
  projectId: number;
  invitationId: number;
};

export type UpdateProjectInvitationInput = {
  orgId: number;
  projectId: number;
  data: {
    role: Role;
  };
};

const getProjectMembers = ({ orgId, projectId }: GetProjectMembersInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/members/`);

const deleteProjectMember = ({
  orgId,
  projectId,
  memberId,
}: DeleteProjectMemberInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/members/${memberId}/`
  );

const createProjectMember = ({
  orgId,
  projectId,
  data,
}: CreateProjectMemberInput) =>
  mainApi.post(`organizations/${orgId}/projects/${projectId}/members/`, data);

const updateProjectMember = ({
  projectId,
  memberId,
  orgId,
  data,
}: UpdateProjectMemberInput) =>
  mainApi.patch(
    `organizations/${orgId}/projects/${projectId}/members/${memberId}/`,
    data
  );

const getProjectMemberById = ({
  orgId,
  projectId,
  memberId,
}: GetProjectMemberByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/members/${memberId}/`
  );

const getProjectById = ({ projectId, orgId }: GetProjectByIdInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/`);

const updateProject = ({ projectId, orgId, data }: UpdateProjectInput) =>
  mainApi.patch(`organizations/${orgId}/projects/${projectId}/`, data);

const deleteProject = ({ projectId, orgId }: DeleteProjectInput) =>
  mainApi.delete(`organizations/${orgId}/projects/${projectId}/`);

const getProjects = ({ orgId }: GetProjectsInput) =>
  mainApi.get(`organizations/${orgId}/projects/`);

const joinProject = ({ projectId, orgId }: JoinProjectInput) =>
  mainApi.post(`organizations/${orgId}/projects/${projectId}/join/`);

const leaveProject = ({ orgId, projectId }: LeaveProjectInput) =>
  mainApi.post(`organizations/${orgId}/projects/${projectId}/leave/`);

const createProject = ({ orgId, data }: CreateProjectInput) =>
  mainApi.post(`organizations/${orgId}/projects/`, data);

const getProjectInvitations = ({
  orgId,
  projectId,
}: GetProjectInvitationsInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/invitations/`);

const createProjectInvitation = ({
  orgId,
  projectId,
  data,
}: CreateProjectInvitationInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/invitations/`,
    data
  );

const updateProjectInvitation = ({
  orgId,
  projectId,
  data,
}: UpdateProjectInvitationInput) =>
  mainApi.patch(
    `organizations/${orgId}/projects/${projectId}/invitations/`,
    data
  );

const getProjectInvitationById = ({
  orgId,
  projectId,
  invitationId,
}: GetProjectInvitationByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/invitations/${invitationId}`
  );

const deleteProjectInvitation = ({
  orgId,
  projectId,
  invitationId,
}: GetProjectInvitationByIdInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/invitations/${invitationId}`
  );

const resendProjectInvitation = ({
  orgId,
  projectId,
  invitationId,
}: ResendProjectInvitationInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/invitations/${invitationId}/resend/`
  );

const verifyProjectInvitation = ({
  orgId,
  projectId,
  invitationId,
}: VerifyProjectInvitationInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/invitations/${invitationId}/verify/`
  );

const getProjectSharedScenarios = ({
  orgId,
  projectId,
}: GetProjectSharedScenariosInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/shared_scenarios/`);

const completeOnboarding = ({ orgId, projectId }: CompleteCreateProjectInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/complete_onboarding/`
  );

const getProjectOutputSchema = ({
  orgId,
  projectId,
}: GetProjectOutputSchemaInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/output_schema/`);

export {
  completeOnboarding,
  createProjectMember,
  createProject,
  createProjectInvitation,
  deleteProject,
  deleteProjectInvitation,
  deleteProjectMember,
  getProjectById,
  getProjectInvitationById,
  getProjectInvitations,
  getProjectMemberById,
  getProjectMembers,
  getProjectOutputSchema,
  getProjects,
  getProjectSharedScenarios,
  joinProject,
  leaveProject,
  resendProjectInvitation,
  updateProject,
  updateProjectInvitation,
  updateProjectMember,
  verifyProjectInvitation,
};
