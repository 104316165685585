import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Avatar } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import { OrganizationRolesOptions } from '../common/utils';
import { useGetProjects } from '../hooks';
import {
  useGetOrgInvitations,
  useGetOrgMembers,
} from '../hooks/useOrganizationHook';
import { useGetUser } from '../hooks/useUserHook';
import {
  isOrgAdmin,
  useGetRouteParams,
  useGetSearchResults,
} from '../hooks/useUtilsHook';
import {
  type OrganizationRole,
  type OrganizationStatus,
  type UserStatus,
} from '../types';
import cn from '../utils/cn';
import { useCurrentMembership, useCurrentOrganization } from '../utils/helpers';

import CustomMenuDropdown from './model/CustomMenuDropdown';
import InviteOrgFormDialog from './organizations/InviteOrgFormDialog';
import ConfirmDeleteUserDialog from './userManagement/ConfirmDeleteUserDialog';
import ResendOrgInvitation from './userManagement/ResendOrgInvitation';
import SearchInput from './SearchInput';
import Spinner from './Spinner';

export type DataRow = {
  id: number;
  user: {
    id: number;
    name?: string | null;
    avatarImage: string;
    email: string;
    role: OrganizationRole;
  };
  projects:
    | {
        id: number;
        name: string;
      }[]
    | null;
  type: OrganizationStatus;
  lastActive: string | null;
  status: UserStatus;
};

const roles = [
  {
    value: 'organization_member',
    label: 'Organization Member',
  },
  {
    value: 'organization_admin',
    label: 'Organization Admin',
  },
  {
    value: 'organization_owner',
    label: 'Organization Owner',
  },
] as {
  value: OrganizationRole;
  label: string;
}[];

const columnHelper = createColumnHelper<DataRow>();

const columns = ({ handleSelect }: { handleSelect: (id: number) => void }) => [
  columnHelper.accessor('user', {
    cell: (info) => {
      const { email, role, name, avatarImage } = info.getValue();

      return (
        <div className="group flex items-center gap-1 py-2 text-start">
          <div
            className="flex items-center gap-2"
            onClick={() => handleSelect(info.row.original.id)}
          >
            <Avatar alt="User avatar" src={avatarImage} />
            <div className="flex flex-col">
              <div className="flex w-full justify-between gap-3">
                <p
                  className={cn('font-semibold', {
                    'opacity-70': !name,
                  })}
                >
                  {name ?? 'Unknown'}
                </p>
                {isOrgAdmin(role) && (
                  <p
                    className={cn(
                      'flex items-center rounded-full px-3 py-0 text-xs font-bold uppercase',
                      {
                        'bg-[#FFE8B9]': role === 'organization_admin',
                        'bg-[#E8F2FF]': role === 'organization_owner',
                      }
                    )}
                  >
                    {
                      OrganizationRolesOptions.find((r) => r.value === role)
                        ?.label
                    }
                  </p>
                )}
              </div>
              <p className="text-sm text-[#666]">{email}</p>
            </div>
          </div>
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-1">
        <p>User</p>
      </div>
    ),
  }),
  columnHelper.accessor('lastActive', {
    id: 'lastActive',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div className="w-full" onClick={() => handleSelect(id)}>
          {format(info.getValue() as string, 'Y/MM/dd h:mm a')}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="text-gray-400 w-full text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Last active</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const status = info.getValue();
      const id = info.row.original.id;

      const { orgId } = useGetRouteParams();

      const membership = useCurrentMembership();

      const { data: org_invitations } = useGetOrgInvitations(
        { orgId },
        {
          enabled: !!orgId && isOrgAdmin(membership.organization?.role),
        }
      );

      const { data: org_members } = useGetOrgMembers(
        { orgId },
        {
          enabled: !!orgId && isOrgAdmin(membership.organization?.role),
        }
      );

      const { data: currentUser } = useGetUser();

      const row = info.row.original;

      const user = {
        ...row,
        email: row.user.email,
        role: row.user.role,
        type:
          status === 'pending'
            ? 'invitation'
            : ('member' as 'invitation' | 'member'),
      };

      const member = org_members?.find((m) => m.user.id === user.id);
      const orgMember = currentUser?.organizations.find((o) => o.id === orgId);

      const invitation = org_invitations?.find(
        (i: { email: string }) => i.email === user.email
      );

      if (member) {
        user['id'] = member.id;
      } else if (invitation) {
        user['id'] = invitation.id;
      }

      const StatusLabel = ({
        label,
        value,
      }: {
        value: 'active' | 'pending';
        label: string;
      }) => (
        <div className="flex justify-start py-2">
          <p
            className={cn(
              'w-fit rounded-full bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#EEF6FF] text-[#117DD3]': value === 'active',
                'text-[#C38D04] bg-[#FFF8E0]': value === 'pending',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      return (
        <div className="flex items-center justify-between">
          <div
            className="w-full"
            onClick={() => {
              handleSelect(id);
            }}
          >
            {status === 'active' ? (
              <StatusLabel value="active" label="Active" />
            ) : null}
            {status === 'pending' ? (
              <StatusLabel value="pending" label="Pending" />
            ) : null}
          </div>
          <div className="flex gap-4">
            {invitation && user.type === 'invitation' && (
              <ResendOrgInvitation
                invitationId={invitation.id}
                orgId={invitation.organization.id}
              />
            )}
            {user.role !== 'organization_owner' &&
              user.email !== currentUser?.email &&
              orgMember?.role !== 'organization_member' && (
                <ConfirmDeleteUserDialog
                  user={user}
                  trigger={{
                    className: 'text-[#666666]',
                  }}
                  level="organization"
                />
              )}
          </div>
        </div>
      );
    },
    header: () => <div>Status</div>,
  }),
];

function getSelectedKeys<T>(filter: { [key: string]: boolean }): T[] {
  return Object.keys(filter).filter(
    (key) => filter[key as keyof typeof filter]
  ) as T[];
}

const UsersTable = () => {
  const [data, setData] = useState<DataRow[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openInviteFormDialog, setOpenInviteFormDialog] = useState(false);

  const currentOrg = useCurrentOrganization();

  const { orgId } = useGetRouteParams();

  const { data: projects } = useGetProjects(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const form = useForm({
    defaultValues: {
      search: '',
      roles: {},
      projects: {},
      status: {},
    },
  });

  const { watch } = form;

  const filterRoles = watch('roles') as Record<OrganizationRole, boolean>;
  const filterProjects = watch('projects') as Record<number, boolean>;
  const filterStatus = watch('status') as Record<UserStatus, boolean>;

  const selectedRoles = getSelectedKeys<OrganizationRole>(filterRoles);
  const selectedProjects = getSelectedKeys<number>(filterProjects);
  const selectedStatus = getSelectedKeys<UserStatus>(filterStatus);

  const {
    data: org_invitations,
    isLoading: isFetchingOrgInvitations,
    refetch,
  } = useGetOrgInvitations(
    {
      orgId,
      projects: selectedProjects.join(',') || undefined,
      roles: selectedRoles.join(',') || undefined,
    },
    {
      enabled: !!orgId,
    }
  );

  const { data: org_members, isLoading: isFetchingOrgMembers } =
    useGetOrgMembers(
      {
        orgId,
        projects: selectedProjects.join(',') || undefined,
        roles: selectedRoles.join(',') || undefined,
      },
      {
        enabled: !!orgId && selectedStatus.includes('active'),
      }
    );

  useEffect(() => {
    const dataRows: DataRow[] = [];

    if (org_invitations && org_invitations?.length > 0) {
      org_invitations.forEach((i) =>
        dataRows.push({
          id: i.id,
          user: {
            id: i.id,
            name: null,
            avatarImage: '',
            email: i.email,
            role: i.role,
          },
          projects: null,
          lastActive: null,
          status: 'pending',
          type: 'invitation',
        })
      );
    }

    if (org_members && org_members?.length > 0) {
      org_members.forEach((m) =>
        dataRows.push({
          id: m.id,
          lastActive: m.date_joined,
          projects: m.project_memberships
            .map((p) => p.project)
            .filter((p) => projects?.map((pr) => pr.id).includes(p.id)),
          user: {
            id: m.user.id,
            name: `${m.user.first_name} ${m.user.last_name}`,
            avatarImage: m.user.profile_pic,
            role: m.role,
            email: m.user.email,
          },
          type: 'member',
          status: 'active',
        })
      );
    }

    setData(dataRows);
  }, [org_invitations, org_members, projects]);

  const searchResults = useGetSearchResults<DataRow>({
    searchValue: watch('search'),
    data,
    filterFields: ['user.name', 'user.email'],
  });

  // useEffect(() => {
  //   refetch();
  //   if (
  //     selectedStatus.includes('active') &&
  //     !selectedStatus.includes('pending')
  //   ) {
  //     setData(data.filter((d) => d.type === 'member'));
  //   } else if (
  //     selectedStatus.includes('pending') &&
  //     !selectedStatus.includes('active')
  //   ) {
  //     setData(data.filter((d) => d.type === 'invitation'));
  //   } else {
  //     setData(
  //       data.filter((d) => d.type === 'member' || d.type === 'invitation')
  //     );
  //   }
  // }, [selectedStatus]);

  const navigate = useNavigate();

  const handleSelect = (id: number) => {
    const record = data.find((d) => d.id === id);

    const type = record?.type;

    if (type === 'invitation') {
      return;
    }

    navigate({
      pathname: `${id}`,
      search: searchParams.toString(),
    });
  };

  const table = useReactTable({
    data: searchResults ?? data,
    columns: columns({ handleSelect }),
    getCoreRowModel: getCoreRowModel(),
  });

  const isAdmin = currentOrg && isOrgAdmin(currentOrg.role);

  const handleOpenInviteFormDialog = () => {
    setOpenInviteFormDialog(true);
  };

  const handleCloseInviteFormDialog = () => {
    setOpenInviteFormDialog(false);
  };

  const projectOptions =
    projects?.map((p) => ({
      value: p.id,
      label: p.name,
    })) ?? [];

  return (
    <div className="w-full">
      <FormProvider {...form}>
        <form className="flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex flex-wrap grow gap-2">
              <div className="w-full max-w-[350px]">
                <SearchInput />
              </div>
              {projectOptions.length > 0 && (
                <CustomMenuDropdown
                  name="projects"
                  label="Project"
                  data={projectOptions}
                />
              )}
              <CustomMenuDropdown name="roles" label="Role" data={roles} />
              {/* <CustomMenuDropdown
                name="status"
                label="Status"
                data={
                  [
                    {
                      value: 'active',
                      label: 'Active',
                    },
                    {
                      value: 'pending',
                      label: 'Pending',
                    },
                  ] as {
                    value: UserStatus;
                    label: string;
                  }[]
                }
              /> */}
            </div>
            <div className="">
              {isAdmin && (
                <InviteOrgFormDialog
                  roles={roles}
                  open={openInviteFormDialog}
                  handleClose={handleCloseInviteFormDialog}
                  handleOpen={handleOpenInviteFormDialog}
                />
              )}
            </div>
          </div>
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="!rounded-md bg-[#F8F8F8]">
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={cn(
                        'first:border-l last:border-r border-t border-b px-6 py-2 text-start text-sm text-[#4D4D4D]',
                        {
                          'w-[45%]': index === 0,
                        }
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="relative">
              {(isFetchingOrgMembers || isFetchingOrgInvitations) && (
                <div className="absolute border-[#E4E7EC] h-full border flex w-full items-center justify-center opacity-50">
                  <Spinner />
                </div>
              )}
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="cursor-pointer px-6 py-3">
                  {row.getVisibleCells().map((cell, index) => (
                    <td
                      key={cell.id}
                      className={cn(
                        'first:border-l last:border-r border-b border-[#E4E7EC] px-6 text-[#333]',
                        {
                          'w-[45%]': index === 0,
                        }
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {!table.getRowModel().rows.length &&
            !isFetchingOrgMembers &&
            !isFetchingOrgInvitations && (
              <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
                <p className="text-lg text-[#999]">
                  No users available. Please invite a user.
                </p>
              </div>
            )}
        </form>
      </FormProvider>
      <div className="h-4" />
    </div>
  );
};

export default UsersTable;
