import React, { type CSSProperties, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle } from '@mui/icons-material';
import { MenuItem, Switch } from '@mui/material';
import { EllipsisVertical, Pencil, Trash2 } from 'lucide-react';

import { type TypeScenarioVector } from '../../common/dataTypes';
import { useUpdateVector } from '../../hooks';
import { useGetScenarioVectors } from '../../hooks/useScenariosHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { useDeleteVector } from '../../hooks/useVectorHook';
import cn from '../../utils/cn';
import LoadingDialog from '../LoadingDialog';
import MenuDropdown from '../MenuDropdown';
import SimpleConfirmDeleteDialog from '../SimpleConfirmDeleteDialog';
import CreateVectorFormDialog from '../vector/CreateVectorFormDialog';

const getVectorDirection = (vector: TypeScenarioVector) => {
  if (
    (vector.delta && vector.delta > 0) ||
    (vector.delta_percentage && vector.delta_percentage > 0)
  ) {
    return 'increase';
  }

  if (
    (vector.delta && vector.delta < 0) ||
    (vector.delta_percentage && vector.delta_percentage < 0)
  ) {
    return 'decrease';
  }

  if (vector.new_value) {
    return 'set';
  }
};

const DraggableVector = ({
  vector,
  handleOnActivate,
}: {
  vector: TypeScenarioVector;
  handleOnActivate?: () => void;
}) => {
  const [checked, setChecked] = useState(vector.is_active);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);

  const [openDialogCreateVector, setOpenDialogCreateVector] = useState(false);

  const open = Boolean(anchorEl);

  const {
    transform,
    transition,
    setNodeRef,
    isDragging,
    attributes,
    listeners,
  } = useSortable({
    id: vector.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative',
  };

  const [searchParams] = useSearchParams();
  const scenarioId = Number(searchParams.get('scenario_id')) ?? NaN;

  const { projectId, modelId, orgId } = useGetRouteParams();

  const { mutate: updateVector } = useUpdateVector({
    onSuccess() {
      handleOnActivate?.();
      setOpenLoadingDialog(false);
    },
  });

  const { mutate: deleteVector, isPending: isDeletingVector } =
    useDeleteVector();

  const direction = getVectorDirection(vector);

  const aircraftTypeValue = useMemo(
    () =>
      vector?.criterias?.find((c) => c.field_name === 'aircraft_type')?.value,
    [vector?.criterias]
  );

  const handleCloseDropdown = () => setAnchorEl(null);

  const handleOpenDialogCreateVector = () => setOpenDialogCreateVector(true);

  const handleToggleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;

    setChecked(value);

    setOpenLoadingDialog(true);

    if (value) {
      updateVector({
        orgId,
        vectorId: vector.id,
        projectId,
        scenarioId,
        modelId,
        data: {
          ...vector,
          scenario: scenarioId,
          is_active: true,
        },
      });
    } else {
      updateVector({
        orgId,
        vectorId: vector.id,
        projectId,
        scenarioId,
        modelId,
        data: {
          ...vector,
          scenario: scenarioId,
          is_active: false,
        },
      });
    }
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDelete = () => {
    if (orgId && vector && projectId) {
      deleteVector({
        orgId,
        projectId,
        modelId,
        scenarioId,
        vectorId: vector.id,
      });
    }
  };

  return (
    <div ref={setNodeRef} className="flex gap-4 items-center" style={style}>
      <button type="button" {...attributes} {...listeners}>
        <DragHandle
          style={{
            color: '#666',
          }}
        />
      </button>
      <div className="flex flex-col w-full gap-3 bg-white rounded-[4px] border border-[#E4E7EC] p-5">
        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <p className="font-bold">{vector.name}</p>
          </div>
          <div className="flex items-center gap-3">
            <Switch
              checked={checked}
              onChange={(e) => handleToggleCheck(e)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <button
              type="button"
              onClick={() => {
                handleCloseDropdown();
                handleOpenDialogCreateVector();
              }}
            >
              <Pencil size={20} color="#666666" />
            </button>
            <MenuDropdown
              trigger={
                <button className="w-fit" type="button">
                  <EllipsisVertical color="#666666" width={20} height={20} />
                </button>
              }
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              style={{
                marginTop: '5px',
                marginLeft: '-5px',
              }}
              open={open}
            >
              <div className="!border-t !border-[#E4E7EC]" />
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setOpenConfirmDelete(true);
                }}
              >
                <div className="flex items-center space-x-2 text-primary-red">
                  <Trash2 size={15} />
                  <p>Delete</p>
                </div>
              </MenuItem>
            </MenuDropdown>
          </div>
        </div>
        <div className="flex flex-col">
          <p>{vector.description}</p>
        </div>
        <div className="flex grow items-center gap-3 bg-[#FFF] !text-sm">
          <span
            className={cn('font-bold transition uppercase text-[#333]', {
              'text-[#B3B3B3]': !checked,
            })}
          >
            {direction}
          </span>
          {direction === 'set' && (
            <p
              className={cn('space-x-3 transition', {
                'text-[#B3B3B3]': !checked,
              })}
            >
              <span className="font-semibold uppercase text-[#333]">to</span>
              <span className="rounded-full bg-[#EFFCFF] px-3 py-1 font-semibold">
                {vector.new_value}%
              </span>
            </p>
          )}
          {(direction === 'increase' || direction === 'decrease') && (
            <p
              className={cn('space-x-3 transition', {
                '!text-[#B3B3B3]': !checked,
              })}
            >
              <span className="font-bold uppercase">by</span>
              <span className="rounded-full bg-[#EFFCFF] px-3 py-1 font-semibold">
                {vector.delta
                  ? vector.delta?.toString().replace('-', '')
                  : vector.delta_percentage?.toString().replace('-', '') + '%'}
              </span>
            </p>
          )}
          <p
            className={cn('space-x-3 transition', {
              'text-[#B3B3B3]': !checked,
            })}
          >
            <span className="font-bold uppercase">on</span>
            {aircraftTypeValue ? (
              <>
                <span>aircraft_type</span>
                <span>=</span>
                <span
                  className={cn(
                    'rounded-full bg-[#E8F2FF] transition px-3 py-1 font-semibold capitalize text-[#01285F]',
                    {
                      'text-[#B3B3B3] bg-[#F5F5F5]': !checked,
                    }
                  )}
                >
                  {
                    vector?.criterias?.find(
                      (c) => c.field_name === 'aircraft_type'
                    )?.value
                  }
                </span>
                <span className="font-bold uppercase">AND</span>
              </>
            ) : null}
            <span>timeline from</span>
            <span
              className={cn(
                'rounded-full bg-[#FFF4F2] px-3 py-1 transition font-semibold capitalize text-primary-red',
                {
                  'text-[#B3B3B3] bg-[#F5F5F5]': !checked,
                }
              )}
            >
              {vector?.start_period}
            </span>
            <span>to</span>
            <span
              className={cn(
                'rounded-full bg-[#FFF4F2] px-3 transition py-1 font-semibold capitalize text-primary-red',
                {
                  'text-[#B3B3B3] bg-[#F5F5F5]': !checked,
                }
              )}
            >
              {vector?.end_period}
            </span>
          </p>
        </div>
        <SimpleConfirmDeleteDialog
          open={openConfirmDelete}
          isLoading={isDeletingVector}
          title={`Are you sure you want to delete ${vector.name}?`}
          handleOnClose={handleCloseConfirmDelete}
          handleOnSubmit={handleDelete}
        />
        <CreateVectorFormDialog
          open={openDialogCreateVector}
          handleCloseDialog={() => {
            setOpenDialogCreateVector(false);
          }}
          handleOpenDialog={() => {
            setOpenDialogCreateVector(true);
          }}
          handleSubmitDialog={handleOnActivate}
          title="Edit Vector"
          defaultValues={{
            id: vector.id,
            name: vector.name,
            description: vector.description,
            aircraft_type:
              vector.criterias.find((f) => f.field_name === 'aircraft_type')
                ?.value ?? '',
            crew_type:
              vector.criterias.find((f) => f.field_name === 'crew_type')
                ?.value ?? '',
            start_period: vector.start_period,
            end_period: vector.end_period,
            type: vector.delta ? 'delta' : 'percentage',
            action:
              (vector.delta
                ? (vector.delta as number)
                : (vector.delta_percentage as number)) > 0
                ? 'increase'
                : 'decrease',
            amount: vector.delta
              ? Math.abs(vector.delta)
              : Math.abs(vector.delta_percentage as number),
          }}
          showTrigger={false}
        />
        <LoadingDialog
          title={`Updating "${vector.name}" vector`}
          open={openLoadingDialog}
        />
      </div>
    </div>
  );
};

type VectorSectionProps = {
  handleOnActivate?: () => void;
};

const VectorsSection = ({ handleOnActivate }: VectorSectionProps) => {
  const { projectId, orgId, modelId, scenarioId } = useGetRouteParams();
  const [data, setData] = useState<TypeScenarioVector[]>([]);

  const { data: vectors } = useGetScenarioVectors(
    {
      scenarioId,
      orgId,
      projectId,
      modelId,
    },
    {
      enabled: !!scenarioId && !!orgId && !!projectId && !!modelId,
    }
  );

  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () => data.map(({ id }) => id),
    [data]
  );

  useEffect(() => {
    if (!vectors) {
      return;
    }
    setData(vectors);
  }, [vectors]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setData((data) => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);

        const newData = arrayMove(data, oldIndex, newIndex).map(
          (item, index) => ({
            ...item,
            hierarchy: index + 1,
          })
        );

        return newData;
      });
    }
  };

  return data.length ? (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className="flex flex-col gap-3 bg-[#FFF]">
        <SortableContext items={data} strategy={verticalListSortingStrategy}>
          {data.map((vector) => (
            <DraggableVector
              key={vector.id}
              vector={vector}
              handleOnActivate={handleOnActivate}
            />
          ))}
        </SortableContext>
      </div>
    </DndContext>
  ) : (
    <div className="w-full py-12 text-center text-gray-500 border rounded-[4px] border-[#E4E7EC]">
      No Vectors. Start by adding a new Vector.
    </div>
  );
};

export default VectorsSection;
