import React, { type SyntheticEvent, useRef, useState } from 'react';

import { Button } from '@mui/material';
import { FileUp, MousePointer2 } from 'lucide-react';

import { useGetDatasetById } from '../../hooks';
import { useGetIFS } from '../../hooks/useIFSHook';
import {
  isOrgAdmin,
  useGetRouteParams,
  useHandleImportIFS,
} from '../../hooks/useUtilsHook';
import { useCurrentOrganization } from '../../utils/helpers';
import LoadingDialog from '../LoadingDialog';
import {
  CustomTabPanel,
  Tab,
  Tabs,
} from '../projects/models/ProjectModelsManagement';
import SuccessDialog from '../SuccessDialog';
import ViewFilesDataTable from '../ViewFilesDataTable';

import AlertDialog from './AlertDialog';
import DownloadTemplatesDialog from './DownloadTemplatesDialog';
import { IFSTable } from './IFSTableSection';
import ImportExistingFilesDialog from './ImportExistingFilesDialog';

const TABS = ['Import', 'Data'];

const ProjectViewDataset = () => {
  const [index, setIndex] = useState(0);

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openImportFilesDialog, setOpenImportFilesDialog] = useState(false);

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const { orgId, datasetId, projectId } = useGetRouteParams();

  const { importIfs, error, isPending } = useHandleImportIFS({
    inputFileRef,
    handleError: () => {
      setOpenErrorDialog(true);
    },
  });

  const { data: ifs } = useGetIFS(
    {
      projectId,
      datasetId,
      orgId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId,
      projectId,
      orgId,
    },
    {
      enabled: !!datasetId && !!projectId && !!orgId,
    }
  );

  const currentOrgMember = useCurrentOrganization();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  const handleOpenImportFilesDialog = () => {
    setOpenImportFilesDialog(true);
  };

  const handleCloseImportFilesDialog = () => {
    setOpenImportFilesDialog(false);
  };

  const isDisabled = ifs?.files.find((file) => !file.is_imported);
  const isAdmin = currentOrgMember && isOrgAdmin(currentOrgMember.role);

  return (
    <div className="flex flex-col gap-4">
      <Tabs value={index} onChange={handleChange}>
        {TABS.map((report) => (
          <Tab
            id={report}
            key={report}
            label={report}
            disabled={report === 'Data' && !!isDisabled}
          />
        ))}
      </Tabs>
      <CustomTabPanel value={index.toString()} index="0">
        <div className="flex flex-col gap-3">
          <h1 className="text-2xl font-bold">Import</h1>
          <div className="flex justify-between">
            {dataset && ifs && (
              <DownloadTemplatesDialog
                dataset={dataset}
                ifs={ifs}
                trigger={{
                  style: {
                    color: '#666',
                    borderColor: '#CCC',
                  },
                }}
              />
            )}
            {isAdmin && (
              <div className="flex gap-4">
                <Button
                  variant="outlined"
                  style={{
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <input
                    ref={inputFileRef}
                    type="file"
                    accept=".xlsx"
                    id="import_ifs"
                    onChange={importIfs}
                    multiple
                    className="absolute w-[140px] cursor-pointer opacity-0"
                  />
                  <FileUp size={20} />
                  Upload File(s)
                </Button>
                <Button
                  onClick={handleOpenImportFilesDialog}
                  variant="contained"
                  style={{
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <MousePointer2 size={20} />
                  Import existing file(s)
                </Button>
              </div>
            )}
          </div>
          <IFSTable />
          <ImportExistingFilesDialog
            open={openImportFilesDialog}
            handleClose={handleCloseImportFilesDialog}
          />
          <AlertDialog
            title="Error Found: Please Review"
            open={openErrorDialog}
            content={error?.content}
            handleOnClose={() => {
              setOpenErrorDialog(false);
            }}
          />
          <SuccessDialog
            content="Input File Set imported successfully."
            open={openSuccessDialog}
            handleOnClose={() => {
              setOpenSuccessDialog(false);
            }}
          />
          <LoadingDialog title="Uploading" open={isPending} />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="1">
        <div className="font-bold space-y-3">
          <h1 className="text-2xl font-bold">Data</h1>
          <ViewFilesDataTable />
        </div>
      </CustomTabPanel>
    </div>
  );
};

export default ProjectViewDataset;
