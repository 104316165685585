import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import { type TypeSharedModel } from '../../common/dataTypes';
import { useGetSharedModelSharingInvitations } from '../../hooks/useModelsHook';
import {
  useGetDistinguishModel,
  useGetRouteParams,
  useGetSearchResults,
} from '../../hooks/useUtilsHook';
import { type AcceptStatus } from '../../types';
import cn from '../../utils/cn';
import SearchInput from '../SearchInput';
import Spinner from '../Spinner';

export type DataRow = {
  id: number;
  sharedBy: {
    email: string;
    full_name: string;
  };
  sharedTo: {
    email: string;
  };
  status: AcceptStatus;
  createdAt: string;
  acceptedAt: string;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('sharedBy', {
    id: 'sharedBy',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue().full_name}</div>
      ) : (
        <div className="text-gray-400 w-full text-start">--</div>
      ),
    header: () => <div>Shared By</div>,
  }),
  columnHelper.accessor('sharedTo', {
    id: 'sharedTo',
    cell: (info) => {
      const receiver = info.getValue();

      return receiver ? (
        <div className="w-full">{receiver.email}</div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      );
    },
    header: () => <div>Shared To</div>,
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    cell: (info) => {
      const value = info.getValue();

      return value ? (
        <div className="w-full">
          {format(value as string, 'Y/MM/dd h:mm a')}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      );
    },
    header: () => <div>Date Sent</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const status = info.getValue();

      const StatusLabel = ({
        label,
        value,
      }: {
        value: string;
        label: string;
      }) => (
        <div className="flex justify-start py-2">
          <p
            className={cn(
              'w-fit rounded-full bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#EEF6FF] text-[#117DD3]': value === 'active',
                'text-[#C38D04] bg-[#FFF8E0]': value === 'pending',
                'bg-[#FFF4F2] text-primary-red': value === 'reject',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      return status ? (
        <div className="w-full">
          {status === 'Accepted' ? (
            <StatusLabel value="active" label="Active" />
          ) : null}
          {status === 'Pending' ? (
            <StatusLabel value="pending" label="Pending" />
          ) : null}
          {status === 'Rejected' ? (
            <StatusLabel value="reject" label="Reject" />
          ) : null}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      );
    },
    header: () => <div>Status</div>,
  }),
  columnHelper.accessor('acceptedAt', {
    id: 'acceptedAt',
    cell: (info) => {
      const value = info.getValue();

      return value ? (
        <div className="w-full">
          {format(value as string, 'Y/MM/dd h:mm a')}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      );
    },
    header: () => <div>Date Accepted</div>,
  }),
];

const SharedModelSharingInvitationsView = () => {
  const [data, setData] = useState<DataRow[]>([]);

  const { modelId, orgId, projectId } = useGetRouteParams();

  const form = useForm();

  const { watch } = form;

  const {
    data: sharedModelSharingInvitations,
    isLoading: isFetchingSharedModelSharingInvitations,
  } = useGetSharedModelSharingInvitations(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!modelId && !!orgId && !!projectId,
    }
  );

  const { model, isLoading } = useGetDistinguishModel();

  const searchResults = useGetSearchResults<DataRow>({
    searchValue: watch('search'),
    data,
    filterFields: [
      'sharedTo.email',
      'sharedBy.email',
      'sharedBy.full_name',
      'status',
    ],
  });

  useEffect(() => {
    if (sharedModelSharingInvitations && model) {
      setData(
        sharedModelSharingInvitations.map((r) => ({
          id: r.id,
          sharedBy: {
            email: (model as TypeSharedModel).shared_by.email,
            full_name: (model as TypeSharedModel).shared_by.full_name,
          },
          sharedTo: {
            email: r.email,
          },
          status: r.accept_status,
          createdAt: r.created_at,
          acceptedAt: r.accepted_at,
        })) as DataRow[]
      );
    }
  }, [sharedModelSharingInvitations, model]);

  const table = useReactTable({
    data: searchResults ?? data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-2xl font-bold">Shared</h1>
      <FormProvider {...form}>
        <form className="flex justify-between">
          <div className="flex w-[300px] gap-2">
            <SearchInput />
          </div>
        </form>
      </FormProvider>
      <div className="w-full">
        <table className="w-full font-normal">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={cn(
                      'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="relative h-full">
            {(isFetchingSharedModelSharingInvitations || isLoading) && (
              <div className="absolute border-[#E4E7EC] h-full border flex w-full items-center justify-center opacity-50">
                <Spinner />
              </div>
            )}
            {table.getRowModel().rows ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="cursor-pointer px-6 py-3 transition"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="border-b border-[#E4E7EC] px-6 text-[#333] first:border-l last:border-r"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
                <p className="text-lg text-[#999]">
                  No sharing requests available
                </p>
              </div>
            )}
          </tbody>
        </table>
        <div className="h-4" />
      </div>
    </div>
  );
};

export default SharedModelSharingInvitationsView;
