import React, { useMemo, useState } from 'react';

import { format } from 'date-fns';
import { Pencil } from 'lucide-react';

import { useGetProjectById } from '../../hooks';
import { useGetIFSConfig } from '../../hooks/useDatasetsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import {
  generateMonthList,
  generateWeekList,
  transformToLabel,
} from '../../utils/helpers';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';

import CreateProjectDialog from './CreateProjectDialog';

type ProjectReviewProps = {
  editable?: boolean;
  deletable?: boolean;
};

const ProjectReview = ({
  editable = true,
  deletable = true,
}: ProjectReviewProps) => {
  const [openCreateProjectDialog, setOpenCreateProjectDialog] = useState(false);

  const { orgId, projectId } = useGetRouteParams();

  const monthList = useMemo(() => generateMonthList(), []);
  const weekList = useMemo(() => generateWeekList(), []);

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const { data: ifsConfig } = useGetIFSConfig(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { data: project } = useGetProjectById(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const aircrafts = useMemo(() => ifsConfig?.aircrafts, [ifsConfig?.aircrafts]);

  const startMonthOrWeek = ifsConfig?.start_period.split('-')[0];
  const endMonthOrWeek = ifsConfig?.end_period.split('-')[0];

  const startMonthOrWeekLabel =
    ifsConfig?.granularity === 'monthly'
      ? monthList.find((m) => m.value === startMonthOrWeek)?.label
      : weekList.find((w) => w.value === startMonthOrWeek)?.label;

  const endMonthOrWeekLabel =
    ifsConfig?.granularity === 'monthly'
      ? monthList.find((m) => m.value === endMonthOrWeek)?.label
      : weekList.find((w) => w.value === endMonthOrWeek)?.label;

  const handleOpenCreateProjectDialog = () => {
    setOpenCreateProjectDialog(true);
  };

  const handleCloseCreateProjectDialog = () => {
    setOpenCreateProjectDialog(false);
  };

  const handleOpenConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleCloseConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <div className="flex gap-2 items-center">
          <h1 className="text-xl font-bold">General</h1>
          {editable && (
            <Pencil
              className="cursor-pointer"
              size={18}
              onClick={handleOpenCreateProjectDialog}
            />
          )}
          <CreateProjectDialog
            open={openCreateProjectDialog}
            handleClose={handleCloseCreateProjectDialog}
            primaryActionLabel="Update"
            defaultValues={project}
          />
        </div>
        <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
          <div className="flex">
            <p className="w-[15%]">Project Name:</p>
            <p className="w-full font-bold">{project?.name}</p>
          </div>
          <div className="flex">
            <p className="w-[15%]">Description:</p>
            <p className="w-full font-bold">{project?.description}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <h2 className="text-nowrap text-xl font-bold">IFS Configuration</h2>
        <div className="border border-[#E4E7EC] rounded-[4px] p-6 space-y-8">
          <div className="flex flex-col gap-3 bg-[#FFF]">
            <div className="flex">
              <p className="w-[15%]">Last modified:</p>
              <p className="w-full font-bold">
                {ifsConfig?.updated_at
                  ? format(ifsConfig?.updated_at as string, 'Y/MM/dd hh:mm a')
                  : ''}
              </p>
            </div>
            <div className="flex">
              <p className="w-[15%]">Created by:</p>
              <p className="w-full font-bold">
                {ifsConfig?.created_by?.full_name}
              </p>
            </div>
          </div>
          <div className="bg-white flex flex-col gap-3">
            <h2 className="text-xl font-bold">Reporting Period</h2>
            <div className="flex gap-6">
              <p className="capitalize">
                Granularity:{' '}
                <span className="font-bold">{ifsConfig?.granularity}</span>
              </p>
              <p>
                Duration:{' '}
                <span className="font-bold">{ifsConfig?.duration}</span>
              </p>
              <p>
                Start:{' '}
                <span className="font-bold">
                  {(startMonthOrWeekLabel ? `${startMonthOrWeekLabel}-` : '') +
                    ifsConfig?.start_period.split('-')[
                      ifsConfig?.start_period.split('-').length - 1
                    ]}
                </span>
              </p>
              <p>
                End:{' '}
                <span className="font-bold">
                  {(endMonthOrWeekLabel ? `${endMonthOrWeekLabel}-` : '') +
                    ifsConfig?.end_period.split('-')[
                      ifsConfig?.end_period.split('-').length - 1
                    ]}
                </span>
              </p>
            </div>
          </div>
          <div className="bg-white flex flex-col gap-3">
            <h2 className="text-xl font-bold">Aircraft and Airports</h2>
            <table className="border-separate border-spacing-0 rounded-[4px] border  border-[#E4E7EC]">
              {aircrafts?.map((a: { name: string; airports: string[] }) => (
                <tr key={a.name}>
                  <td className="w-[5%] border-b border-r px-6 py-2 last:border-b-0">
                    {a.name}
                  </td>
                  <td className="border-b px-6 py-2">
                    {(a.airports as string[])?.join(', ')}
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div className="bg-white flex flex-col gap-3">
            <h2 className="text-xl font-bold">Labor Group and Positions</h2>
            <table className="border-separate border-spacing-0 rounded-[4px] border  border-[#E4E7EC]">
              <tr>
                <td className="w-[5%] border-r px-6 py-2 capitalize">
                  {ifsConfig?.labor_group}
                </td>
                <td className="px-6 py-2">
                  {ifsConfig?.positions
                    .map((p: { name: string }) => transformToLabel(p.name))
                    .join(', ')}
                </td>
              </tr>
            </table>
            <p>
              Retirement Age:{' '}
              <span className="font-bold">{ifsConfig?.retirement_age}</span>
            </p>
          </div>
          <div className="bg-white flex flex-col gap-3">
            <h2 className="text-xl font-bold">Other Configuration</h2>
            <div className="flex gap-6">
              <p>
                Years of Service:{' '}
                <span className="font-bold">{ifsConfig?.years_of_service}</span>
              </p>
              <p>
                Seat lock period:{' '}
                <span className="font-bold">{ifsConfig?.seat_lock_period}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {deletable && (
        <div className="flex justify-start">
          <button
            className="text-[#B8341B] font-semibold"
            onClick={handleOpenConfirmDeleteDialog}
          >
            Delete
          </button>
        </div>
      )}
      {project && (
        <ConfirmDeleteDialog
          type="project"
          row={{
            id: project.id,
            name: project.name,
            description: project.description,
            modified: project.updated_at,
          }}
          open={openConfirmDeleteDialog}
          handleClose={handleCloseConfirmDeleteDialog}
        />
      )}
    </div>
  );
};

export default ProjectReview;
