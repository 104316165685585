import React from 'react';

type VerificationFailedProps = {
  title?: string;
  description?: string;
  subDescription?: string;
};

const VerificationFailed = ({
  title,
  description,
  subDescription,
}: VerificationFailedProps) => (
  <div className="flex items-center h-svh justify-center py-20">
    <div className="text-white flex flex-col items-center gap-4 text-center">
      <h1 className="text-[48px] font-bold ">Oops!!!</h1>
      <img src="/images/time.png" width={132} height={132} />
      <p className="text-2xl font-bold">
        {title ?? 'Email Verification Failed!'}
      </p>
      <p>
        {description ??
          "We're sorry, but it seems the verification link has expired or invalid."}
      </p>
      <p>{subDescription ?? 'Please register again.'}</p>
    </div>
  </div>
);

export default VerificationFailed;
