import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  autocompleteClasses,
  type AutocompleteGetTagProps,
  Avatar,
  MenuItem,
  Select,
  styled,
  useAutocomplete,
} from '@mui/material';
import { Check, X } from 'lucide-react';

import { ProjectRolesOptions } from '../common/utils';

const Root = styled('div')(
  ({ theme }) => `
  color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
  width: 100%;
  font-size: 14px;
  position: relative;
`
);

export const StyledSelect = styled(Select)({
  fontSize: '14px',
  padding: '0px',

  '& .MuiSelect-root': {
    padding: '0px',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },
});

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgb(24 144 255 / 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.65)'
        : 'rgba(0,0,0,.85)'
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

type TagProps = {
  label: string;
  disabled?: boolean;
} & ReturnType<AutocompleteGetTagProps>;

function Tag(props: TagProps) {
  const { label, onDelete, disabled, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      {!disabled && (
        <X
          className="cursor-pointer"
          size={18}
          color="#CCCCCC"
          onClick={onDelete}
        />
      )}
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 30px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 100%;
  margin: 5px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 300px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 0.15);
  z-index: 1;


  & li {
    padding: 5px 12px;
    display: flex;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`
);

type Option = {
  id: number;
  fullName: string;
  avatar: string;
  email: string;
};

const CustomFormAutoComplete = ({
  disabled,
  options,
  placeholder,
  defaultValue,
  selectFieldName,
}: {
  disabled: boolean;
  placeholder?: string;
  defaultValue?: Option;
  options: Option[];
  selectFieldName: string;
}) => {
  const { register, setValue } = useFormContext();

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'users',
    multiple: true,
    defaultValue: defaultValue ? [defaultValue] : [],
    options,
    getOptionLabel: (option) => option.fullName,
  });

  useEffect(() => {
    setValue('users', value);
  }, [value]);

  return (
    <Root>
      <div {...getRootProps()} className="w-full">
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map(
            (
              option: { fullName: string; avatar: string; email: string },
              index: number
            ) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <StyledTag
                  key={key}
                  {...tagProps}
                  label={option.fullName}
                  disabled={disabled}
                />
              );
            }
          )}
          <input
            {...getInputProps()}
            placeholder={!disabled ? placeholder : ''}
            disabled={disabled}
            className="!h-[36px]"
          />
          {!disabled && (
            <StyledSelect
              {...register(selectFieldName)}
              size="small"
              defaultValue={ProjectRolesOptions[0].value}
              className="bottom-0"
              placeholder="Please select"
            >
              {ProjectRolesOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <span className="capitalize text-sm">{option.label}</span>
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof options).map((option, index) => {
            const { key, ...optionProps } = getOptionProps({
              option,
              index,
            });

            return (
              <li
                key={key}
                {...optionProps}
                className="flex items-center justify-between"
              >
                <div className="flex gap-3">
                  <Avatar src={option.avatar} />
                  <div className="flex flex-col">
                    <span>{option.fullName}</span>
                    <span className="text-xs text-[#666]">{option.email}</span>
                  </div>
                </div>
                <Check size={20} />
              </li>
            );
          })}
        </Listbox>
      ) : null}
    </Root>
  );
};

export default CustomFormAutoComplete;
