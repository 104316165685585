import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import * as z from 'zod';

import { toastPromise } from '../../common/utils';
import { useGetDatasets, useUpdateModel } from '../../hooks';
import {
  useGetPreDefinedExpenses,
  useGetSharedUserDefinedExpenses,
  useGetUserDefinedExpenses,
} from '../../hooks/useExpenseHook';
import {
  useGetDistinguishModel,
  useGetRouteParams,
} from '../../hooks/useUtilsHook';
import {
  PredefinedExpensesForm,
  UserDefinedExpensesForm,
} from '../expense/CreateExpenseForm';
import ExpenseCollapse from '../expense/ExpenseCollapse';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

const DatasetConfigurationSchema = z.object({
  label: z.string(),
  value: z.string(),
});

const RetirementAgeConfigurationSchema = z.object({
  label: z.string(),
  value: z.string(),
});

type DatasetConfigurationSchemaType = z.infer<
  typeof DatasetConfigurationSchema
>;

type RetirementAgeConfigurationSchemaType = z.infer<
  typeof RetirementAgeConfigurationSchema
>;

type ExpenseOption = {
  label: string;
  value: number;
  type: 'default' | 'custom';
};

const DatasetConfiguration = () => {
  const form = useForm<DatasetConfigurationSchemaType>({
    resolver: zodResolver(DatasetConfigurationSchema),
    defaultValues: {
      label: '',
      value: '',
    },
  });

  const { handleSubmit, reset, watch, formState, setValue } = form;

  const { isLoading } = formState;

  const [openSelectDatasetDialog, setOpenSelectDatasetDialog] = useState(false);

  const { projectId, orgId, modelId } = useGetRouteParams();

  const { model, isDefault } = useGetDistinguishModel();

  const { data: datasets } = useGetDatasets(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const dataset = datasets?.find((d) => d.id === model?.dataset);

  const { mutateAsync: updateModel } = useUpdateModel();

  useEffect(() => {
    if (dataset) {
      reset({
        label: dataset.name,
        value: dataset.id.toString(),
      });
    }
  }, [dataset]);

  const handleCloseDatasetDialog = () => {
    setOpenSelectDatasetDialog(false);
  };

  const handleOpenDatasetDialog = () => {
    setOpenSelectDatasetDialog(true);
  };

  const handleOnSubmit = async () => {
    const { value } = watch();

    if (!Number(value)) {
      return;
    }

    const res = updateModel({
      projectId,
      orgId,
      modelId,
      data: {
        dataset: Number(value),
      },
    });

    const resData = await res;

    toastPromise({
      promise: res,
      content: "Model's dataset updated successfully",
    });

    const newDataset = datasets?.find((d) => d.id === Number(resData.dataset));

    if (newDataset) {
      setValue('label', newDataset.name);
    }

    handleCloseDatasetDialog();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Dataset</h1>
            {!model?.is_base && isDefault && (
              <Button
                variant="outlined"
                style={{
                  color: '#B8341B',
                  borderColor: '#B8341B',
                }}
                onClick={handleOpenDatasetDialog}
              >
                Edit
              </Button>
            )}
          </div>
          <div className="w-[30%]">
            <FormInput disabled name={'label'} />
          </div>
        </div>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openSelectDatasetDialog}
          onClose={handleCloseDatasetDialog}
        >
          <DialogTitle style={{ cursor: 'move' }}>
            <h2 className="font-bold">Select Dataset</h2>
          </DialogTitle>
          <DialogContent>
            <div className="w-full">
              {!!datasets && (
                <FormSelect
                  name="value"
                  options={datasets.map((dataset) => ({
                    label: dataset.name,
                    value: dataset.id,
                  }))}
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-3 px-4 pb-3">
              <Button
                autoFocus
                variant="outlined"
                type="button"
                onClick={handleCloseDatasetDialog}
                tabIndex={3}
                style={{
                  color: '#666666',
                  borderColor: '#CCC',
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                autoFocus
                loading={isLoading}
                onClick={handleOnSubmit}
                tabIndex={3}
                style={{
                  backgroundColor: '#2196F3',
                  color: '#FFF',
                }}
              >
                Save
              </LoadingButton>
            </div>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};

const RetirementAgeConfiguration = () => {
  const { modelId, orgId, projectId } = useGetRouteParams();

  const [openSelectDatasetDialog, setOpenSelectDatasetDialog] = useState(false);

  const { model, isDefault } = useGetDistinguishModel();

  const { mutateAsync: updateModel } = useUpdateModel({
    onSuccess: () => {
      handleCloseDatasetDialog();
    },
  });

  const form = useForm<RetirementAgeConfigurationSchemaType>({
    resolver: zodResolver(RetirementAgeConfigurationSchema),
    defaultValues: {
      value: '',
      label: '',
    },
  });

  const { handleSubmit, setValue, watch, formState, reset, setError } = form;

  const { isLoading } = formState;

  useEffect(() => {
    if (model) {
      const value = model.retirement_age.toString();

      reset({
        label: value,
        value,
      });
    }
  }, [model]);

  const handleCloseDatasetDialog = () => {
    setOpenSelectDatasetDialog(false);
  };

  const handleOpenDatasetDialog = () => {
    setOpenSelectDatasetDialog(true);
  };

  const handleOnSubmit = () => {
    const { value } = watch();

    if (!model) {
      return;
    }

    if (!value) {
      setError('value', {
        message: 'This field is required',
      });
      return;
    } else if (Number(value) < 0) {
      setError('value', {
        message: 'Value should be greater than 0',
      });
      return;
    }

    const res = updateModel({
      orgId,
      projectId,
      modelId,
      data: {
        retirement_age: Number(value),
        dataset: model.dataset,
      },
    });

    setValue('label', value);

    toastPromise({
      promise: res,
      content: 'Retirement age updated successfully',
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Retirement Age</h1>
            {!model?.is_base && isDefault && (
              <Button
                variant="outlined"
                style={{
                  color: '#B8341B',
                  borderColor: '#B8341B',
                }}
                onClick={handleOpenDatasetDialog}
              >
                Edit
              </Button>
            )}
          </div>
          <div className="w-[30%]">
            <FormInput disabled name={'label'} />
          </div>
        </div>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openSelectDatasetDialog}
          onClose={handleCloseDatasetDialog}
        >
          <DialogTitle style={{ cursor: 'move' }}>
            <h2 className="font-bold">Edit retirement age</h2>
          </DialogTitle>
          <DialogContent>
            <div className="w-full">
              <FormInput
                placeholder="Enter retirement age"
                type="number"
                name="value"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-3 px-4 pb-3">
              <Button
                autoFocus
                variant="outlined"
                type="button"
                onClick={handleCloseDatasetDialog}
                tabIndex={3}
                style={{
                  color: '#666666',
                  borderColor: '#CCC',
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                autoFocus
                loading={isLoading}
                onClick={handleOnSubmit}
                tabIndex={3}
                style={{
                  backgroundColor: '#2196F3',
                  color: '#FFF',
                }}
              >
                Save
              </LoadingButton>
            </div>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};

const SeatLockPeriodConfiguration = () => {
  const { modelId, orgId, projectId } = useGetRouteParams();

  const [openSelectUpdateModelDialog, setOpenUpdateModelDialog] =
    useState(false);

  const { model, isDefault } = useGetDistinguishModel();

  const { mutateAsync: updateModel } = useUpdateModel({
    onSuccess: () => {
      handleCloseUpdateModelDialog();
    },
  });

  const form = useForm<RetirementAgeConfigurationSchemaType>({
    resolver: zodResolver(RetirementAgeConfigurationSchema),
    defaultValues: {
      value: '',
      label: '',
    },
  });

  const { handleSubmit, setValue, watch, formState, reset, setError } = form;

  const { isLoading } = formState;

  useEffect(() => {
    if (model) {
      const value = model.seat_lock_period.toString();

      reset({
        label: value,
        value,
      });
    }
  }, [model]);

  const handleCloseUpdateModelDialog = () => {
    setOpenUpdateModelDialog(false);
  };

  const handleOpenUpdateModelDialog = () => {
    setOpenUpdateModelDialog(true);
  };

  const handleOnSubmit = () => {
    const { value } = watch();

    if (!model) {
      return;
    }

    if (!value) {
      setError('value', {
        message: 'This field is required',
      });
      return;
    } else if (Number(value) < 0) {
      setError('value', {
        message: 'Value should be greater than 0',
      });
      return;
    }

    const res = updateModel({
      orgId,
      projectId,
      modelId,
      data: {
        seat_lock_period: Number(value),
        dataset: model.dataset,
      },
    });

    setValue('label', value);

    toastPromise({
      promise: res,
      content: 'Seat lock period updated successfully',
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Seat Lock Period</h1>
            {!model?.is_base && isDefault && (
              <Button
                variant="outlined"
                style={{
                  color: '#B8341B',
                  borderColor: '#B8341B',
                }}
                onClick={handleOpenUpdateModelDialog}
              >
                Edit
              </Button>
            )}
          </div>
          <div className="w-[30%]">
            <FormInput disabled name={'label'} />
          </div>
        </div>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openSelectUpdateModelDialog}
          onClose={handleCloseUpdateModelDialog}
        >
          <DialogTitle style={{ cursor: 'move' }}>
            <h2 className="font-bold">Edit Seat Lock Period</h2>
          </DialogTitle>
          <DialogContent>
            <div className="w-full">
              <FormInput
                placeholder="Enter retirement age"
                type="number"
                name="value"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-3 px-4 pb-3">
              <Button
                autoFocus
                variant="outlined"
                type="button"
                onClick={handleCloseUpdateModelDialog}
                tabIndex={3}
                style={{
                  color: '#666666',
                  borderColor: '#CCC',
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                autoFocus
                loading={isLoading}
                onClick={handleOnSubmit}
                tabIndex={3}
                style={{
                  backgroundColor: '#2196F3',
                  color: '#FFF',
                }}
              >
                Save
              </LoadingButton>
            </div>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};

const ExpenseConfiguration = () => {
  const form = useForm();

  const { handleSubmit, watch } = form;

  const [openEditExpenseDialog, setOpenEditExpenseDialog] = useState(false);

  const { projectId, orgId, modelId } = useGetRouteParams();

  const { model, isShared, isDefault } = useGetDistinguishModel();

  const { data: predefinedExpenseSets } = useGetPreDefinedExpenses(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const {
    data: defaultUserdefinedExpenses,
    refetch: refetchDefaultUserdefinedExpenses,
  } = useGetUserDefinedExpenses(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId && isDefault,
    }
  );

  const {
    data: sharedUserdefinedExpenses,
    refetch: refetchSharedUserdefinedExpenses,
  } = useGetSharedUserDefinedExpenses(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId && isShared,
    }
  );

  const userdefinedExpenseSets = isDefault
    ? defaultUserdefinedExpenses
    : sharedUserdefinedExpenses;

  const modelPreDefinedExpenses = model?.predefined_expenses;
  const modelDatasetId = model?.dataset;

  const handleCloseEditExpenseDialog = () => {
    setOpenEditExpenseDialog(false);
  };

  const handleOpenEditExpenseDialog = () => {
    setOpenEditExpenseDialog(true);
  };

  const allDefault = Object.values(watch('default') ?? {})
    .map((e: any) => Object.keys(e).filter((key) => e[key]))
    .flat();

  const allUser = Object.values(watch('user') ?? {})
    .map((e: any) => Object.keys(e).filter((key) => e[key]))
    .flat();

  const { mutateAsync: updateModel, isPending: isUpdatingModel } =
    useUpdateModel();

  const handleOnSubmit = () => {
    if (
      allDefault.length === 0 ||
      !orgId ||
      !projectId ||
      !model ||
      !modelDatasetId
    ) {
      return;
    }

    const res = updateModel({
      orgId,
      projectId,
      modelId,
      data: {
        name: model.name,
        predefined_expenses: allDefault.map(Number),
        userdefined_expenses: allUser.map(Number),
        dataset: modelDatasetId,
      },
    });

    toastPromise({
      promise: res,
      content: 'Expense set updated successfully',
    });
    handleCloseEditExpenseDialog();
  };

  const predefinedExpenses = predefinedExpenseSets?.reduce(
    (acc, cur) => {
      const group = cur.group.name;

      acc[group] = [
        ...(acc[group] ?? []),
        {
          label: cur.name,
          value: cur.id,
          type: 'default',
        },
      ];

      return acc;
    },
    {} as Record<string, ExpenseOption[]>
  );

  const customExpenses = useMemo(
    () =>
      userdefinedExpenseSets
        ?.filter((u) => model?.userdefined_expenses?.includes(u.id))
        ?.reduce(
          (acc, cur) => {
            const group = cur.group?.name;

            acc[group] = [
              ...(acc[group] ?? []),
              {
                label: cur.name,
                value: cur.id,
                type: 'custom',
              },
            ];

            return acc;
          },
          {} as Record<string, ExpenseOption[]>
        ),
    [userdefinedExpenseSets, model?.userdefined_expenses]
  );

  const expenses = useMemo(
    () =>
      predefinedExpenses && customExpenses
        ? Object.keys(predefinedExpenses).reduce(
            (acc, cur) => {
              acc[cur] = [
                ...predefinedExpenses[cur],
                ...(customExpenses[cur] ?? []),
              ];
              return acc;
            },
            {} as Record<string, ExpenseOption[]>
          )
        : [],
    [predefinedExpenses, customExpenses]
  );

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold">Expense</h1>
              {!model?.is_base && isDefault && (
                <Button
                  variant="outlined"
                  style={{
                    color: '#B8341B',
                    borderColor: '#B8341B',
                  }}
                  onClick={handleOpenEditExpenseDialog}
                >
                  Edit
                </Button>
              )}
            </div>
            <div className="rounded-[4px] border border-[#E4E7EC] p-6">
              {expenses &&
                modelPreDefinedExpenses &&
                Object.keys(expenses).map((group) => (
                  <ExpenseCollapse
                    title={group}
                    items={(expenses as Record<string, ExpenseOption[]>)[group]}
                    isSelectable={false}
                  />
                ))}
            </div>
          </div>
          <Dialog
            maxWidth="sm"
            fullWidth
            open={openEditExpenseDialog}
            onClose={handleCloseEditExpenseDialog}
          >
            <DialogContent>
              <div>
                <UserDefinedExpensesForm
                  handleAddOnclick={handleCloseEditExpenseDialog}
                />
                <PredefinedExpensesForm />
              </div>
            </DialogContent>
            <DialogActions>
              <div className="flex gap-3 px-4 pb-3">
                <Button
                  autoFocus
                  variant="outlined"
                  type="button"
                  onClick={handleCloseEditExpenseDialog}
                  disabled={isUpdatingModel}
                  tabIndex={4}
                  style={{
                    color: '#666666',
                    borderColor: '#CCC',
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  autoFocus
                  variant="contained"
                  disabled={isUpdatingModel}
                  loading={isUpdatingModel}
                  onClick={handleOnSubmit}
                  tabIndex={3}
                >
                  Save
                </LoadingButton>
              </div>
            </DialogActions>
          </Dialog>
        </form>
      </FormProvider>
    </div>
  );
};

const ModelConfigurationView = () => (
  <div className="flex flex-col gap-3 overflow-y-hidden">
    <DatasetConfiguration />
    <div className="my-3 rounded-full border border-[#CCC]" />
    <RetirementAgeConfiguration />
    <div className="my-3 rounded-full border border-[#CCC]" />
    <SeatLockPeriodConfiguration />
    <div className="my-3 rounded-full border border-[#CCC]" />
    <ExpenseConfiguration />
  </div>
);

export default ModelConfigurationView;
