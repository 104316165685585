import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import routes from '../routes';

function LoginByLinkFail() {
  const navigate = useNavigate();

  const handlePrimaryAction = () => {
    navigate('/login?type=login_by_link');
  };

  const handleSecondaryAction = () => {
    navigate(routes.login);
  };

  return (
    <div className="bg-blue-950 flex h-screen items-center justify-center py-20">
      <div className="text-white flex flex-col items-center gap-6 text-center">
        <h1 className="text-[48px] font-bold ">Oops!!!</h1>
        <img src="/images/time.png" width={132} height={132} />
        <p className="text-2xl font-bold">Email Verification Failed!</p>
        <div>
          <p>
            We're sorry, but it seems the verification link has expired or is
            invalid.
          </p>
          <p>
            Please ensure you clicked on the correct link provided in our email.
          </p>
        </div>
        <div className="flex w-full flex-col space-y-3">
          <Button
            onClick={handlePrimaryAction}
            fullWidth
            style={{
              color: 'white',
              backgroundColor: '#B8341B',
            }}
          >
            Resend link
          </Button>
          <button className="text-sm" onClick={handleSecondaryAction}>
            Back to Log in
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginByLinkFail;
