import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Divider, MenuItem } from '@mui/material';
import { Checkbox as MuiCheckbox, styled } from '@mui/material';
import { ChevronDown, ChevronUp } from 'lucide-react';

import MenuDropdown from '../MenuDropdown';

const Checkbox = styled(MuiCheckbox)({
  padding: 0,
});

type CustomMenuDropdownProps = {
  name: string;
  label: string;
  defaultSelectAll?: boolean;
  data: {
    label: string;
    value: string | number;
  }[];
};

const CustomMenuDropdown = ({
  name,
  label,
  data,
  defaultSelectAll,
}: CustomMenuDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const open = Boolean(anchorEl);

  const { register, resetField, setValue, watch } = useFormContext();

  const formData = watch(name);

  const selectedRecords = Object.keys(formData ?? {}).filter(
    (key) => formData[key] === true && key !== 'all'
  ).length;

  useEffect(() => {
    if (data && defaultSelectAll) {
      setIsSelectAll(true);
      data.forEach((d) => {
        setValue(`${name}.${d.value}`, true);
      });
    }
  }, [defaultSelectAll, name]);

  const handleClearFilters = () => {
    setIsSelectAll(false);
    resetField(name);
  };

  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll);
    data.forEach((d) => {
      setValue(`${name}.${d.value}`, !isSelectAll);
    });
  };

  return (
    <MenuDropdown
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      open={open}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      trigger={
        <div className="flex w-full items-center gap-2 px-3 text-[#666666]">
          <p className="font-semibold">{label}</p>
          {selectedRecords > 0 && (
            <div className="rounded-[4px] px-2 bg-[#F5F5F5] min-w-[100px]">
              {selectedRecords} selected
            </div>
          )}
          {open ? <ChevronUp width={15} /> : <ChevronDown width={15} />}
        </div>
      }
      style={{ width: '100%' }}
      triggerClassName="rounded-md border border-[#CCCCCC] pl-3 py-1.5"
    >
      <label
        htmlFor="select_all"
        className="flex transition hover:bg-slate-200 p-2 cursor-pointer w-full h-full gap-2"
      >
        <Checkbox
          checked={isSelectAll}
          onClick={handleSelectAll}
          id="select_all"
        />
        <p>All</p>
      </label>
      <Divider
        style={{
          margin: 0,
        }}
      />
      {data.map((d) => {
        const isSelected = !!watch(`${name}.${d.value}`);

        return (
          <label
            htmlFor={d.value.toString()}
            className="flex transition hover:bg-slate-200 p-2 cursor-pointer w-full h-full gap-2"
          >
            <Checkbox
              id={d.value.toString()}
              checked={isSelected}
              {...register(`${name}.${d.value}`)}
              onClick={() => {
                if (isSelected) {
                  setIsSelectAll(false);
                  return;
                }

                if (
                  Object.keys(formData ?? {}).filter((k) => formData[k])
                    .length +
                    1 ===
                  data.length
                ) {
                  setIsSelectAll(true);
                }
              }}
            />
            <p>{d.label}</p>
          </label>
        );
      })}
      <Divider
        style={{
          margin: 0,
        }}
      />
      <MenuItem onClick={handleClearFilters}>
        <p className="px-4 py-1 text-primary-red font-semibold flex items-center ">
          Clear filters
        </p>
      </MenuItem>
    </MenuDropdown>
  );
};

export default CustomMenuDropdown;
