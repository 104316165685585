import React from 'react';

import _ from 'lodash';

import {
  useGetOrgInvitations,
  useGetOrgMembers,
} from '../../hooks/useOrganizationHook';
import { useGetUser } from '../../hooks/useUserHook';
import { isOrgAdmin, useGetRouteParams } from '../../hooks/useUtilsHook';
import UsersTable from '../UsersTable';

const UserManagement = () => {
  const { orgId } = useGetRouteParams();

  const { data: currentUser } = useGetUser();

  const { data: org_invitations } = useGetOrgInvitations(
    { orgId },
    {
      enabled: !!orgId,
    }
  );

  const { data: org_members } = useGetOrgMembers(
    { orgId },
    {
      enabled: !!orgId,
    }
  );

  const totalUsersAmount =
    Number(org_invitations?.length) + Number(org_members?.length);

  const activeUsersAmount = Number(org_members?.length);

  const accountAdminsAmount = Number(
    org_members?.filter((member) => isOrgAdmin(member.role)).length
  );

  const currentOrgMember = currentUser?.organizations.find(
    (org) => org.id === orgId
  );

  const isUserOrgAdmin = currentOrgMember && isOrgAdmin(currentOrgMember.role);

  return (
    <div className="mx-auto max-w-lg 3xl:max-w-2xl space-y-6 py-6">
      <div className="flex w-full justify-between">
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-bold text-[#333]">User Management</h1>
          <p className="text-[#666]">
            Manage your team members and their account permissions here.
          </p>
        </div>
      </div>
      <div className="flex gap-6">
        {isUserOrgAdmin && (
          <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
            <p className="w-[118px] text-[#666666]">Total users</p>
            <p className="text-4xl font-semibold text-primary-red">
              {!_.isNaN(totalUsersAmount) ? totalUsersAmount : '-'}
            </p>
          </div>
        )}

        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Active users</p>
          <p className="text-4xl font-semibold text-primary-red">
            {!_.isNaN(activeUsersAmount) ? activeUsersAmount : '-'}
          </p>
        </div>

        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Account Admins</p>
          <p className="text-4xl font-semibold text-primary-red">
            {!_.isNaN(accountAdminsAmount) ? accountAdminsAmount : '-'}
          </p>
        </div>
      </div>
      <div className="w-full">
        <UsersTable />
      </div>
    </div>
  );
};

export default UserManagement;
