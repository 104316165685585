import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Avatar, Divider, MenuItem } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Check, Mail } from 'lucide-react';

import IfsNavigateErrorDialog from '../components/dataset/IfsNavigateErrorDialog';
import Link from '../components/Link';
import MenuDropdown from '../components/MenuDropdown';
import NotificationDropdown from '../components/NotificationDropdown';
import { useLogout } from '../hooks';
import { useGetUser } from '../hooks/useUserHook';
import {
  isOrgAdmin,
  isOrgGuest,
  useCustomNavigate,
  useGetRouteParams,
} from '../hooks/useUtilsHook';
import logo from '../logo.svg';
import routes from '../routes';
import cn from '../utils/cn';
import { useCurrentOrganization } from '../utils/helpers';

const NavButton = ({ label, value }: { label: string; value: string }) => {
  const { pathname } = useLocation();

  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const navigate = useCustomNavigate();

  const { orgId } = useGetRouteParams();

  const handleNavigate = () => {
    if (!orgId) {
      return;
    }

    navigate(`/${orgId}/${value}`);
  };

  return (
    <>
      <button
        type="button"
        onClick={handleNavigate}
        className={cn(
          'h-full py-[15px] border-b-[2px] box-border transition text-center w-fit px-[10px] border-[#F8F8F8] text-[#999]',
          {
            'border-primary-red text-primary-red': pathname
              .split('/')
              .includes(value),
          }
        )}
      >
        {label}
      </button>
      <IfsNavigateErrorDialog
        open={openErrorDialog}
        handleOnClose={() => {
          setOpenErrorDialog(false);
        }}
      />
    </>
  );
};

function TopBar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const navigate = useCustomNavigate();
  const { orgId } = useGetRouteParams();

  const { data: user } = useGetUser();
  const queryClient = useQueryClient();

  const { mutateAsync: logout } = useLogout({
    onSuccess: () => {
      navigate(routes.login);
    },
  });

  const handleLogout = async () => {
    await logout();
    await queryClient.resetQueries();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentOrgMember = useCurrentOrganization();

  const isAdmin = currentOrgMember && isOrgAdmin(currentOrgMember.role);
  const isGuest = currentOrgMember && isOrgGuest(currentOrgMember.role);

  const handleNavigateHome = () => {
    if (!orgId) {
      return;
    }

    navigate(routes.projects.index, { org_id: orgId.toString() });
  };

  return (
    <div
      color="default"
      className="bg-white z-50 flex w-full cursor-pointer border-b border-[#E6E6E6] "
    >
      <div
        onClick={handleNavigateHome}
        className="flex w-[15%] cursor-pointer items-center justify-start gap-3 p-4 pl-6"
      >
        <img
          src={logo}
          className="min-h-auto min-w-[38px]"
          alt="ISBE Group Logo"
          width={38}
        />
        <p className="w-full font-garamond font-bold text-[#01285F]">
          ISBE Group
        </p>
      </div>
      <div className="bg-white flex w-full justify-between pr-12">
        <div className="flex h-full">
          {!!orgId && (
            <>
              <NavButton value="projects" label="Projects" />
              {!isGuest && (
                <NavButton value="user-management" label="User Management" />
              )}
              {!isGuest && (
                <NavButton
                  value="shared-model-vault"
                  label="Shared Model Vault"
                />
              )}
              {isAdmin && <NavButton value="audit" label="Audit Log" />}
            </>
          )}
        </div>
        <div className="flex items-center gap-3 py-[9px]">
          <NotificationDropdown />
          <Mail
            size={20}
            strokeWidth={1.5}
            onClick={() => {
              navigate(routes.organization.invitations, {
                org_id: orgId.toString(),
              });
            }}
          />
          <MenuDropdown
            trigger={
              <div className="flex w-full items-center gap-3">
                <Avatar
                  alt={user?.first_name}
                  src={user?.profile_pic ? user.profile_pic : '#'}
                  style={{
                    width: '35px',
                    height: '35px',
                  }}
                />
                <div className="-space-y-[1px]">
                  <p className="flex items-center text-sm font-semibold">
                    <p className="w-[130px] truncate text-start">
                      {user?.first_name} {user?.last_name}
                    </p>
                    {open ? (
                      <ExpandLess
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    ) : (
                      <ExpandMore
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    )}
                  </p>
                  <p className="flex items-start text-xs font-light">
                    {user?.email}
                  </p>
                </div>
              </div>
            }
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            style={{
              marginTop: '12px',
              marginLeft: '-50px',
            }}
            open={open}
          >
            <MenuItem
              onClick={() => {
                navigate(routes.account, { org_id: orgId.toString() });
                handleClose();
              }}
            >
              Manage Account
            </MenuItem>
            <Divider
              style={{
                margin: 0,
              }}
            />
            <div className="flex flex-col pt-2">
              <p className="px-4 text-xs text-[#666]">Organizations</p>
            </div>
            <div className="pt-2">
              {user?.organizations?.map((org) => (
                <MenuItem
                  key={org.id}
                  onClick={() => {
                    navigate(routes.projects.index, {
                      org_id: org.id.toString(),
                    });
                    handleClose();
                  }}
                >
                  <div className="flex items-center justify-between gap-10 py-1">
                    <div className="flex gap-2">
                      <Avatar
                        src="#"
                        alt={`${org.name} avatar`}
                        style={{
                          width: '25px',
                          height: '25px',
                        }}
                      />
                      <p>{org.name}</p>
                    </div>
                    {org.id === orgId && <Check size={20} color="#333333" />}
                  </div>
                </MenuItem>
              ))}
            </div>
            <div className="px-4 py-2">
              <Link onClick={handleClose} href={`${orgId}/organizations/`}>
                View all organizations
              </Link>
            </div>
            <Divider
              style={{
                margin: 0,
              }}
            />
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </MenuDropdown>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
