import React from 'react';

import { useGetScenarioListDisplay } from '../../hooks/useScenariosHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import ScenarioGroupInputTable from '../scenario/ScenarioInputTable';

const ModelScenarioOverview = () => {
  const { orgId, projectId, modelId } = useGetRouteParams();

  const { data: listDisplay } = useGetScenarioListDisplay(
    {
      orgId,
      projectId,
      modelId,
    },
    {
      enabled: !!orgId && !!projectId && !!modelId,
    }
  );

  return (
    <div className="flex flex-col justify-between gap-4 pb-3">
      <h2 className="text-3xl font-bold">Scenario & Expense</h2>
      <div className="space-y-6">
        {listDisplay?.groups.map((group) => (
          <ScenarioGroupInputTable
            groupName={group.name}
            dataTableSchemas={group.data_table_schemas}
          />
        ))}
      </div>
    </div>
  );
};

export default ModelScenarioOverview;
