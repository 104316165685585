import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ArrowUpward } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { EllipsisVertical, Pencil, Plus, Trash2 } from 'lucide-react';

import { type TypeModel } from '../../../common/dataTypes';
import { useGetDatasetById } from '../../../hooks';
import { useGetModels } from '../../../hooks/useModelsHook';
import {
  useGetDistinguishModel,
  useGetRoles,
  useGetRouteParams,
  useGetSearchResults,
} from '../../../hooks/useUtilsHook';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog';
import MenuDropdown from '../../MenuDropdown';
import CreateModelFormDialog from '../../model/CreateModelFormDialog';
import SearchInput from '../../SearchInput';
import TanstackTable from '../../TanstackTable';

export type DataRow = {
  id: number;
  name: string;
  description: string;
  dataset: number;
  lastModified: string;
  createdBy: string;
  updatedBy: string;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = ({ handleSelect }: { handleSelect: (id: number) => void }) => [
  columnHelper.accessor('name', {
    cell: (info) => {
      const id = info.row.original.id;

      return (
        <div
          onClick={() => handleSelect(id)}
          className="group flex items-center gap-2 py-3 text-start"
        >
          <p className="w-full">{info.getValue()}</p>
        </div>
      );
    },
    header: () => (
      <div className="flex items-start gap-2">
        <p>Model</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('description', {
    id: 'description',
    cell: (info) => {
      const id = info.row.original.id;

      const handleOnclick = () => {
        handleSelect(id);
      };

      return info.getValue() ? (
        <div onClick={handleOnclick} className="w-full">
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={handleOnclick}
          className="text-gray-400 w-full text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Description</div>,
  }),
  columnHelper.accessor('dataset', {
    id: 'dataset',
    cell: (info) => {
      const id = info.row.original.id;
      const value = info.getValue();

      const { orgId, projectId } = useGetRouteParams();

      const { data: dataset } = useGetDatasetById(
        {
          orgId,
          datasetId: value,
          projectId,
        },
        {
          enabled: !!value && !!orgId && !!projectId,
        }
      );

      const handleOnclick = () => {
        handleSelect(id);
      };

      return dataset ? (
        <div onClick={handleOnclick} className="w-full">
          {dataset.name}
        </div>
      ) : (
        <div
          onClick={handleOnclick}
          className="text-gray-400 w-full text-center"
        >
          --
        </div>
      );
    },
    header: () => <div>Dataset</div>,
  }),
  columnHelper.accessor('lastModified', {
    id: 'lastModified',
    cell: (info) => {
      const id = info.row.original.id;
      const updatedBy = info.row.original.updatedBy;
      const value = info.getValue();

      const handleOnclick = () => {
        handleSelect(id);
      };

      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

      const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
        useState(false);

      const [openCreateModelDialog, setOpenCreateModelDialog] = useState(false);

      const open = Boolean(anchorEl);

      const { model } = useGetDistinguishModel({ modelId: id });

      const handleCloseDropdown = () => setAnchorEl(null);

      const handleOpenConfirmDeleteDialog = () => {
        setOpenConfirmDeleteDialog(true);
      };

      const handleCloseConfirmDeleteDialog = () => {
        setOpenConfirmDeleteDialog(false);
      };

      const handleOpenCreateModelDialog = () => {
        setOpenCreateModelDialog(true);
      };

      const handleCloseCreateModelDialog = () => {
        setOpenCreateModelDialog(false);
      };

      return (
        <div className="flex gap-6 min-w-[200px] justify-between">
          <p className="grow truncate" onClick={handleOnclick}>
            {value ? value + (updatedBy ? ' by ' + updatedBy : '') : '--'}
          </p>
          {model && !model.is_base && (
            <>
              <MenuDropdown
                trigger={
                  <button>
                    <EllipsisVertical width={20} height={20} />
                  </button>
                }
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                style={{
                  marginTop: '5px',
                }}
                open={open}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseDropdown();
                    handleOpenCreateModelDialog();
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <Pencil size={15} />
                    <p>Edit</p>
                  </div>
                </MenuItem>
                <div className="!border-t !border-[#E4E7EC]" />
                <MenuItem
                  onClick={() => {
                    handleCloseDropdown();
                    handleOpenConfirmDeleteDialog();
                  }}
                >
                  <div className="flex items-center space-x-2 text-primary-red">
                    <Trash2 size={15} />
                    <p>Delete</p>
                  </div>
                </MenuItem>
              </MenuDropdown>
              <CreateModelFormDialog
                open={openCreateModelDialog}
                title={'Edit model'}
                handleCloseDialog={handleCloseCreateModelDialog}
                defaultValues={{
                  id: model.id,
                  name: model.name,
                  description: model.description,
                  dataset: model.dataset,
                }}
              />
              <ConfirmDeleteDialog
                type="model"
                row={{
                  id: model.id,
                  name: model.name,
                  description: model.description,
                  modified: model.updated_at,
                }}
                open={openConfirmDeleteDialog}
                handleClose={handleCloseConfirmDeleteDialog}
              />
            </>
          )}
        </div>
      );
    },
    header: () => <div>Last Modified</div>,
  }),
];

const DefaultModelsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isAddModelOpen = searchParams.get('add') === 'true';

  const [openCreateModelDialog, setOpenCreateModelDialog] = useState(
    isAddModelOpen ?? false
  );

  const [data, setData] = useState<DataRow[]>([]);

  const { projectRole } = useGetRoles();

  const { projectId, orgId } = useGetRouteParams();

  const form = useForm();

  const { watch } = form;

  const navigate = useNavigate();

  const {
    data: models,
    isLoading: isFetchingModels,
    isRefetching: isRefetchingModels,
  } = useGetModels(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const searchResults = useGetSearchResults<TypeModel>({
    searchValue: watch('search'),
    data: models,
  });

  useEffect(() => {
    if (models) {
      setData(
        (searchResults ?? models).map((r) => ({
          id: r.id,
          name: r.name,
          description: r.description,
          dataset: r.dataset,
          lastModified: format(r.updated_at ?? '', 'Y/MM/dd hh:mm a'),
          createdBy: r.created_by?.full_name,
          updatedBy: r.updated_by?.full_name,
        })) as DataRow[]
      );
    }
  }, [models, searchResults]);

  const handleSelect = (id: number) => {
    navigate(`${id}`);
  };

  const table = useReactTable({
    data,
    columns: columns({ handleSelect }),
    getCoreRowModel: getCoreRowModel(),
  });

  const handleOpenCreateModelDialog = () => {
    setOpenCreateModelDialog(true);
  };

  const handleCloseCreateModelDialog = () => {
    searchParams.delete('add');
    setSearchParams(searchParams);

    setOpenCreateModelDialog(false);
  };

  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-2xl font-bold">Models</h1>
      <FormProvider {...form}>
        <form className="flex justify-between">
          <div className="flex w-[300px] gap-2">
            <SearchInput />
          </div>
          {projectRole === 'project_owner' && (
            <>
              <Button
                variant="contained"
                onClick={handleOpenCreateModelDialog}
                style={{
                  backgroundColor: '#B8341B',
                  textTransform: 'capitalize',
                }}
              >
                <div className="flex items-center gap-2">
                  <Plus width={20} height={20} color="#E8F2FF" />
                  New
                </div>
              </Button>
              <CreateModelFormDialog
                title="Create A New Model"
                open={openCreateModelDialog}
                handleCloseDialog={handleCloseCreateModelDialog}
              />
            </>
          )}
        </form>
      </FormProvider>
      <TanstackTable
        table={table}
        isLoading={isFetchingModels || isRefetchingModels}
        emptyText="No models available"
      />
    </div>
  );
};

export default DefaultModelsTable;
