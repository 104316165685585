import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { ArrowUpward } from '@mui/icons-material';
import { Menu as MuiMenu } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { Info } from 'lucide-react';
import { EllipsisVertical } from 'lucide-react';

import { type TypeProject } from '../../../common/dataTypes';
import {
  useAcceptReceivedSharingInvitation,
  useGetReceivedSharingInvitations,
  useRejectReceivedSharingInvitation,
} from '../../../hooks/useReceviedSharingInvitations';
import {
  useGetRouteParams,
  useGetSearchResults,
} from '../../../hooks/useUtilsHook';
import { type AcceptStatus } from '../../../types';
import cn from '../../../utils/cn';
import Spinner from '../../Spinner';
import SentInvitationDialog from '../SentInvitationDialog';

export type DataRow = {
  id: number;
  sender: {
    name?: string;
    email: string;
  };
  receiver: {
    name?: string;
    email: string;
  };
  description: string;
  project: TypeProject;
  model: string;
  acceptedAt?: string;
  status: AcceptStatus;
  createdAt: string;
};

const Menu = styled(MuiMenu)({
  '& .MuiMenu-list': {
    paddingTop: '0px',
    paddingBottom: '0px',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    gap: '8px',
  },
});

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('sender', {
    cell: (info) => {
      const { name, email } = info.getValue();

      return (
        <div className="group flex flex-col items-start py-3 text-start">
          <p className="w-full font-semibold">{name}</p>
          <p className="text-sm text-[#666]">{email}</p>
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-2">
        <p>Sender</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('project', {
    id: 'project',
    cell: (info) => {
      const project = info.getValue();

      return project ? (
        <div className="w-full">{project.name}</div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      );
    },
    header: () => <div>Project</div>,
  }),
  columnHelper.accessor('model', {
    id: 'model',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="text-gray-400 w-full text-start">--</div>
      ),
    header: () => <div>Model</div>,
  }),
  columnHelper.accessor('receiver', {
    cell: (info) => {
      const value = info.getValue();

      return (
        <div className="group flex flex-col items-start py-3 text-start">
          <p className="w-full font-semibold">{value?.name}</p>
          <p className="text-sm text-[#666]">{value?.email}</p>
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-2">
        <p>Receiver</p>
      </div>
    ),
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'Y/MM/dd')}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      ),
    header: () => <div>Date Received</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const status = info.getValue();

      const StatusLabel = ({
        label,
        value,
      }: {
        value: string;
        label: string;
      }) => (
        <div className="flex justify-start py-2">
          <p
            className={cn(
              'w-fit rounded-full bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#EEF6FF] text-[#117DD3]': value === 'active',
                'text-[#C38D04] bg-[#FFF8E0]': value === 'pending',
                'bg-[#FFF4F2] text-primary-red': value === 'reject',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      return status ? (
        <div className="w-full">
          {status === 'Accepted' ? (
            <StatusLabel value="active" label="Active" />
          ) : null}
          {status === 'Pending' ? (
            <StatusLabel value="pending" label="Pending" />
          ) : null}
          {status === 'Rejected' ? (
            <StatusLabel value="reject" label="Reject" />
          ) : null}
        </div>
      ) : (
        <div className="text-gray-400 w-full text-center">--</div>
      );
    },
    header: () => <div>Status</div>,
  }),
  columnHelper.accessor('acceptedAt', {
    id: 'acceptedAt',
    cell: (info) => {
      const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
      const [openDialog, setOpenDialog] = useState(false);

      const { orgId } = useGetRouteParams();

      const { mutate: acceptInvitation } = useAcceptReceivedSharingInvitation({
        onSuccess(data) {
          handleCloseDialog();
        },
      });

      const { mutate: rejectInvitation } = useRejectReceivedSharingInvitation({
        onSuccess() {
          handleCloseDialog();
        },
      });

      const openDropdown = Boolean(anchorEl);

      const invitationId = info.row.original.id;
      const modelName = info.row.original.model;
      const modelDescription = info.row.original.description;
      const projectName = info.row.original.project.name;
      const sender = info.row.original.sender;
      const receiver = info.row.original.receiver;
      const status = info.row.original.status;

      const handleAcceptInvitation = () => {
        if (!orgId) {
          return orgId;
        }

        acceptInvitation({
          orgId,
          invitationId,
        });
      };

      const handleRejectInvitation = () => {
        if (!orgId) {
          return orgId;
        }

        rejectInvitation({
          orgId,
          invitationId,
        });
      };

      const handleOpenDialog = () => {
        setOpenDialog(true);
      };

      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

      const handleOpenDropdown = (
        event: React.MouseEvent<HTMLButtonElement>
      ) => {
        setAnchorEl(event.currentTarget);
      };

      const handleCloseDropdown = () => {
        setAnchorEl(null);
      };

      return (
        <div className="flex min-w-[200px] justify-between">
          {info.getValue() ? (
            <p>{format(info.getValue() as string, 'Y/MM/dd')}</p>
          ) : (
            <p>--</p>
          )}
          <div className="flex gap-2">
            <Info color="#2196F3" onClick={handleOpenDialog} />
            {
              <button onClick={handleOpenDropdown}>
                <EllipsisVertical size={20} color="#666666" />
              </button>
            }
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openDropdown}
              onClose={handleCloseDropdown}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <button
                onClick={handleAcceptInvitation}
                className="w-[100px] rounded-[4px] bg-[#E1FBE7] py-1 text-center font-semibold  text-[#0B9919]"
              >
                Accept
              </button>
              <button
                onClick={handleRejectInvitation}
                className="w-[100px] rounded-[4px] bg-[#FFF4F2] py-1 text-center font-semibold text-primary-red"
              >
                Reject
              </button>
            </Menu>
          </div>
          <SentInvitationDialog
            open={openDialog}
            handleOnClose={handleCloseDialog}
            data={{
              name: modelName,
              description: modelDescription,
              from: sender.name ?? '',
              to: receiver.name ?? '',
              projectName: projectName ?? '',
              organization: '',
            }}
          />
        </div>
      );
    },
    header: () => <div>Date Accepted</div>,
    size: 200,
  }),
];

const ReceivedInvitationsTable = ({ searchKey }: { searchKey: string }) => {
  const [data, setData] = useState<DataRow[]>([]);

  const { orgId } = useGetRouteParams();

  const {
    data: receiveSharingInvitations,
    isLoading: isFetchingReceivedSharingInvitations,
  } = useGetReceivedSharingInvitations(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const searchResults = useGetSearchResults<DataRow>({
    searchValue: searchKey,
    data,
    filterFields: [
      'project.name',
      'project.description',
      'model',
      'sender.email',
      'sender.name',
      'receiver.name',
      'receiver.email',
      'shared_by.full_name',
    ],
  });

  useEffect(() => {
    if (receiveSharingInvitations) {
      setData(
        receiveSharingInvitations.map((r) => ({
          id: r.id,
          sender: {
            name: r.shared_by.first_name + ' ' + r.shared_by.last_name,
            email: r.shared_by.email,
          },
          receiver: {
            name: r.receiver.full_name,
            email: r.receiver.email,
          },
          project: r.project,
          model: r.model.name,
          description: r.model.description,
          acceptedAt: r.created_at,
          status: r.accept_status,
          createdAt: r.created_at,
        })) as DataRow[]
      );
    }
  }, [receiveSharingInvitations]);

  const table = useReactTable({
    data: searchResults ?? data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full">
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
            >
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={cn(
                    'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="relative">
          {isFetchingReceivedSharingInvitations && (
            <div className="absolute border-[#E4E7EC] h-full border flex w-full items-center justify-center opacity-50">
              <Spinner />
            </div>
          )}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-pointer px-6 py-3 transition">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border-b border-[#E4E7EC] px-6 text-[#333] first:border-l last:border-r"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!table.getRowModel().rows.length && (
        <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
          <p className="text-lg text-[#999]">No invitations available</p>
        </div>
      )}
      <div className="h-4" />
    </div>
  );
};

export default ReceivedInvitationsTable;
