import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { format } from 'date-fns';
import { ChevronLeft, LockKeyhole, Pencil } from 'lucide-react';

import { type TypeSharedModel } from '../../common/dataTypes';
import { ProjectRolesOptions, toastPromise } from '../../common/utils';
import { useGetProjectById } from '../../hooks';
import {
  useImportReceivedModel,
  useImportSharedModel,
  useRunModelOutput,
} from '../../hooks/useModelsHook';
import {
  useGetDistinguishModel,
  useGetRouteParams,
} from '../../hooks/useUtilsHook';
import routes from '../../routes';
import { type ModelType } from '../../types';

import CreateModelFormDialog from './CreateModelFormDialog';
import ModelOutputTabs from './ModelOutputTabs';
import ShareModelFormDialog from './ShareModelFormDialog';

const ModelOverview = () => {
  const [searchParams] = useSearchParams();

  const { modelId, projectId, orgId } = useGetRouteParams();

  const navigate = useNavigate();

  const [openCreateModelDialog, setOpenCreateModelDialog] = useState(false);

  const form = useForm();

  const { handleSubmit } = form;

  const type = searchParams.get('type') as ModelType;

  const { model, isDefault, isShared, isReceived } = useGetDistinguishModel();

  const { data: project } = useGetProjectById(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const { mutateAsync: runModelOutput } = useRunModelOutput();

  const {
    mutateAsync: importReceiveModel,
    isPending: isImportingReceivedModel,
  } = useImportReceivedModel();

  const { mutateAsync: importSharedModel, isPending: isImportingSharedModel } =
    useImportSharedModel();

  const handleOpenCreateModelDialog = () => {
    setOpenCreateModelDialog(true);
  };

  const handleCloseCreateModelDialog = () => {
    setOpenCreateModelDialog(false);
  };

  const handleGoBack = () => {
    navigate(
      `${routes.projects.model.index
        .replace(':org_id', orgId.toString())
        .replace(
          ':project_id',
          projectId.toString()
        )}${type === 'shared' ? '?type=shared' : type === 'received' ? '?type=received' : ''}`
    );
  };

  const handleImportModel = () => {
    let res;

    if (isShared) {
      res = importSharedModel({
        orgId,
        projectId,
        modelId,
      });
    } else if (isReceived) {
      res = importReceiveModel({
        orgId,
        projectId,
        modelId,
      });
    }

    if (res) {
      toastPromise({
        promise: res,
        content: 'Model imported successfully',
      });
    }
  };

  const onSubmit = async (data: any) => {
    const { aircrafts, airports } = data;

    const selectedAircrafts = Object.keys(aircrafts)
      .filter((a) => aircrafts[a])
      .flat();

    const selectedAirports = Object.keys(airports)
      .filter((a) => airports[a])
      .flat();

    await runModelOutput({
      orgId,
      projectId,
      modelId,
      data: {
        filters: {
          aircrafts:
            selectedAircrafts.length > 0 ? selectedAircrafts : undefined,
          airports: selectedAirports.length > 0 ? selectedAirports : undefined,
        },
      },
    });
  };

  const isEditable = model && !model.is_base && isDefault;

  return (
    <div className="flex flex-col gap-6 pb-6">
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          <div className="flex items-center gap-4">
            <div
              onClick={handleGoBack}
              className="flex items-center gap-4 cursor-pointer"
            >
              <ChevronLeft size={25} color="#333333" />
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  {!isEditable && <LockKeyhole size={24} color="#333333" />}
                  <h1 className="text-2xl font-bold">{model?.name}</h1>
                </div>
                {type === 'shared' && model && (
                  <div className="flex items-center text-[#666] gap-2">
                    <p>from {(model as TypeSharedModel).shared_by.full_name}</p>
                    <p>|</p>
                    <p>
                      {model.created_at
                        ? format(model.created_at, 'Y/MM/dd')
                        : ''}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {isEditable && (
              <>
                <Pencil
                  size={20}
                  color="#333333"
                  className="cursor-pointer"
                  onClick={handleOpenCreateModelDialog}
                />
                <CreateModelFormDialog
                  open={openCreateModelDialog}
                  title={'Edit model'}
                  handleCloseDialog={handleCloseCreateModelDialog}
                  defaultValues={{
                    id: model.id,
                    name: model.name,
                    description: model.description,
                    dataset: model.dataset,
                  }}
                />
              </>
            )}
          </div>
          {project?.role === 'project_owner' &&
            !model?.is_base &&
            isEditable && <ShareModelFormDialog roles={ProjectRolesOptions} />}
          {(isShared || isReceived) && (
            <div>
              <LoadingButton
                disabled={isImportingReceivedModel || isImportingSharedModel}
                loading={isImportingReceivedModel || isImportingSharedModel}
                variant="contained"
                onClick={handleImportModel}
              >
                Import this model
              </LoadingButton>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2 border border-[#E4E7EC] px-6 py-4">
          <p className="flex-grow font-bold">Description</p>
          {model?.description.length ? (
            <p>{model?.description}</p>
          ) : (
            <p className="text-gray-500">No description provided</p>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center justify-between">
              <h2 className="text-3xl font-bold">Overview</h2>
              {isDefault && (
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    color: '#fff',
                    height: '35px',
                    backgroundColor: '#B8341B',
                  }}
                >
                  Run
                </Button>
              )}
            </div>
            <ModelOutputTabs />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ModelOverview;
