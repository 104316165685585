import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { AxiosError, type AxiosResponse } from 'axios';
import _ from 'lodash';

import { type CalculateEndPeriod, calculateEndPeriod } from '../api/utils';
import { type TypeModel, type TypeSharedModel } from '../common/dataTypes';
import { ErrorContent } from '../components/dataset/IFSTableSection';
import { type ModelType, type OrganizationRole } from '../types';

import {
  type DefaultMutationError,
  useGetModelById,
  useGetProjectById,
} from './index';
import { useGetIFS, useImportIFS } from './useIFSHook';
import {
  useGetModelOutput,
  useGetReceivedModelById,
  useGetReceivedModelOutput,
  useGetSharedModelById,
  useGetSharedModelOutput,
} from './useModelsHook';
import { useGetUser } from './useUserHook';

const calculateEndPeriodFn = async (input: CalculateEndPeriod) => {
  const { data }: AxiosResponse<any> = await calculateEndPeriod(input);

  return data;
};

export const useCalculateEndPeriod = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof calculateEndPeriodFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['calculateEndPeriod'],
    mutationFn: (input: CalculateEndPeriod) => calculateEndPeriodFn(input),
    ...opts,
  });

type UseImportIFSProps = {
  inputFileRef: React.MutableRefObject<HTMLInputElement | null>;
  handleError?: () => void;
};

export const useHandleImportIFS = ({
  inputFileRef,
  handleError,
}: UseImportIFSProps) => {
  const [error, setError] = useState<{
    content: React.ReactNode;
  } | null>(null);

  const { projectId, orgId, datasetId } = useGetRouteParams();

  const { mutate: importIFS, isPending } = useImportIFS({
    onSuccess: () => {},
    onError: (error) => {
      if (error instanceof AxiosError) {
        setError({
          content: ErrorContent(error.response.data as string[]),
        });
      }
      handleError?.();
    },
  });

  const { data: ifs } = useGetIFS(
    {
      projectId,
      datasetId,
      orgId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const importIfs = async (e: { target: { value: any; files: any } }) => {
    if (!projectId || !ifs) {
      return;
    }

    const formData = new FormData();

    Array.from(e.target.files as File[]).forEach((file: File) => {
      formData.append('ifs', file);
    });

    importIFS({
      datasetId,
      projectId,
      orgId,
      data: formData,
    });

    e.target.value = '';

    if (inputFileRef?.current?.value) {
      inputFileRef.current = null;
    }
  };

  return {
    importIfs,
    isPending,
    error,
  };
};

export const useGetRouteParams = () => {
  const [searchParams] = useSearchParams();

  const {
    org_id,
    project_id,
    dataset_id,
    expense_id,
    model_id,
    request_id,
    user_id,
    input_table_name,
    scenario_id,
    token,
    invitation_id,
  } = useParams();

  const orgId = Number(org_id || (searchParams.get('org_id') ?? undefined));

  const projectId = Number(
    project_id || (searchParams.get('project_id') ?? undefined)
  );

  const datasetId = Number(
    dataset_id || (searchParams.get('dataset_id') ?? undefined)
  );

  const expenseId = Number(
    expense_id || (searchParams.get('expense_id') ?? undefined)
  );

  const modelId = Number(
    model_id || (searchParams.get('model_id') ?? undefined)
  );

  const requestId = Number(
    request_id || (searchParams.get('request_id') ?? undefined)
  );

  const userId = Number(user_id || (searchParams.get('user_id') ?? undefined));

  const scenarioId = Number(
    scenario_id || (searchParams.get('scenario_id') ?? undefined)
  );

  const invitationId = Number(
    invitation_id || searchParams.get('invitation_id')
  );

  const inputTableName =
    input_table_name || searchParams.get('input_table_name');

  return {
    datasetId,
    expenseId,
    modelId,
    orgId,
    projectId,
    requestId,
    userId,
    invitationId,
    inputTableName,
    scenarioId,
    token: token || searchParams.get('token'),
  };
};

export const useGetRoles = (props?: {
  iProjectId?: number;
  iOrgId?: number;
}) => {
  const { orgId: rOrgId, projectId: rProjectId } = useGetRouteParams();

  const orgId = props?.iOrgId ?? rOrgId;
  const projectId = props?.iProjectId ?? rProjectId;

  const { data: currentUser } = useGetUser();

  const { data: project } = useGetProjectById(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  return {
    projectRole: project?.role,
    orgRole: currentUser?.organizations.find((org) => org.id === orgId)?.role,
  };
};

export const useCustomNavigate = () => {
  const navigate = useNavigate();

  return (path: string, params?: Record<string, string | number>) => {
    let formattedPath = path;

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        formattedPath = formattedPath.replace(`:${key}`, value.toString());
      });
    }

    navigate(formattedPath);
  };
};

export const isOrgAdmin = (role: OrganizationRole) =>
  role === 'organization_admin' || role === 'organization_owner';

export const isOrgSuperAdmin = (role: OrganizationRole) =>
  role === 'organization_owner';

export const isOrgMember = (role: OrganizationRole) =>
  role === 'organization_member';

export const isOrgGuest = (role: OrganizationRole) =>
  role === 'guest_organization_member';

export const useGetSearchResults = <T>({
  searchValue,
  data,
  filterFields = ['name', 'description'],
}: {
  searchValue: string;
  data: T[] | undefined;
  filterFields?: string[];
}) => {
  const [searchResults, setSearchResults] = useState<T[] | undefined>();

  const debouncedSearchTerm = useDebounce(searchValue, 300);

  useEffect(() => {
    if (!!debouncedSearchTerm?.length && data) {
      const filteredDatasets = data.filter((i) =>
        filterFields.some((field) =>
          _.includes(
            field
              .split('.')
              .reduce((acc: any, curr) => acc[curr], i)
              ?.trim()
              .toLowerCase(),
            debouncedSearchTerm.trim().toLowerCase()
          )
        )
      );

      setSearchResults(filteredDatasets);
      return;
    }

    setSearchResults(data);
  }, [debouncedSearchTerm, data]);

  return searchResults;
};

export const useGetDistinguishModel = (data?: { modelId?: number }) => {
  const { modelId: routeModelId, orgId, projectId } = useGetRouteParams();

  const [searchParams] = useSearchParams();

  const type = searchParams.get('type') as ModelType;

  const isShared = type === 'shared';
  const isReceived = type === 'received';
  const isDefault = (!isShared && !isReceived) || !type;

  const modelId = data?.modelId ?? routeModelId;

  const { data: defaultModel, isLoading: isFetchingDefaultModel } =
    useGetModelById(
      {
        modelId,
        orgId,
        projectId,
      },
      {
        enabled: !!modelId && !!orgId && !!projectId && isDefault,
      }
    );

  const { data: sharedModel, isLoading: isFetchingSharedModel } =
    useGetSharedModelById(
      {
        modelId,
        orgId,
        projectId,
      },
      {
        enabled: !!modelId && !!orgId && !!projectId && isShared,
      }
    );

  const { data: receivedModel, isLoading: isFetchingReceivedModel } =
    useGetReceivedModelById(
      {
        modelId,
        orgId,
        projectId,
      },
      {
        enabled: !!modelId && !!orgId && !!projectId && isReceived,
      }
    );

  const { data: defaultModelOutput, isLoading: isFetchingDefaultModelOutput } =
    useGetModelOutput(
      {
        modelId,
        orgId,
        projectId,
      },
      {
        enabled: !!modelId && !!orgId && !!projectId && isDefault,
      }
    );

  const { data: sharedModelOutput, isLoading: isFetchingSharedModelOutput } =
    useGetSharedModelOutput(
      {
        modelId,
        orgId,
        projectId,
      },
      {
        enabled: !!modelId && !!orgId && !!projectId && isShared,
      }
    );

  const {
    data: receivedModelOutput,
    isLoading: isFetchingReceivedModelOutput,
  } = useGetReceivedModelOutput(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!modelId && !!orgId && !!projectId && isReceived,
    }
  );

  let model = defaultModel as TypeModel;
  let modelOutput = defaultModelOutput;

  if (isShared) {
    model = sharedModel as TypeSharedModel;
    modelOutput = sharedModelOutput;
  } else if (isReceived) {
    model = receivedModel as TypeSharedModel;
    modelOutput = receivedModelOutput;
  }

  return {
    model,
    isShared,
    isDefault,
    isReceived,
    modelOutput,
    type,
    isLoading:
      isFetchingDefaultModel ||
      isFetchingSharedModel ||
      isFetchingDefaultModelOutput ||
      isFetchingReceivedModel ||
      isFetchingReceivedModelOutput ||
      isFetchingSharedModelOutput,
  };
};
