import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { type TypeScenarioListDisplay } from '../../common/dataTypes';
import { useGetDistinguishModel } from '../../hooks/useUtilsHook';
import cn from '../../utils/cn';
import Spinner from '../Spinner';

import ScenarioMenuDropdown from './ScenarioMenuDropdown';

type DataRow = {
  id: string;
  inputTable: string;
  supportVectors: boolean;
  scenario: TypeScenarioListDisplay['groups'][0]['data_table_schemas'][0]['active_scenario'];
  vectors?: string[];
};

type ScenarioGroupInputTableProps = {
  groupName: string;
  dataTableSchemas: TypeScenarioListDisplay['groups'][0]['data_table_schemas'];
};

const columnHelper = createColumnHelper<DataRow>();

const columns = ({
  header,
  handleSetIsLoading,
}: {
  header: string;
  handleSetIsLoading: (loading: boolean) => void;
}) => [
  columnHelper.accessor('inputTable', {
    cell: (info) => {
      const dataTableName = info.row.original.id;
      const scenario = info.row.original.scenario;

      const navigate = useNavigate();

      const scenarioId = scenario ? scenario.id : 0;

      const { type } = useGetDistinguishModel();

      const handleOpenEdit = () => {
        navigate(
          `input-table/${dataTableName}?scenario_id=${scenarioId}&type=${type}`
        );
      };

      return (
        <div className="py-2 text-start max-w-[150px]" onClick={handleOpenEdit}>
          <p className="w-full whitespace-nowrap">{info.getValue() ?? '--'}</p>
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-2">
        <p>{header}</p>
      </div>
    ),
  }),
  columnHelper.accessor('scenario', {
    id: 'scenario',
    cell: (info) => {
      const scenario = info.row.original.scenario;
      const dataTableName = info.row.original.id;
      const supportVectors = info.row.original.supportVectors;

      const { model, isDefault } = useGetDistinguishModel();

      const scenarioLabel = scenario ? scenario.name : 'BASE';

      return (
        <div>
          {supportVectors && !model?.is_base && isDefault ? (
            <ScenarioMenuDropdown
              dataTableName={dataTableName}
              label={scenarioLabel}
              handleSetIsLoading={handleSetIsLoading}
            />
          ) : (
            <p className="whitespace-normal">{scenarioLabel}</p>
          )}
        </div>
      );
    },
    header: () => <div>Scenario</div>,
  }),
  columnHelper.accessor('vectors', {
    id: 'vectors',
    cell: (info) => {
      const scenario = info.row.original.scenario;
      const dataTableName = info.row.original.id;
      const supportVectors = info.row.original.supportVectors;

      const { model, type } = useGetDistinguishModel();

      const activeScenarioId = scenario?.id ?? 0;
      const scenarioVectors = scenario?.vectors;

      const navigate = useNavigate();

      const handleOpenEdit = () => {
        navigate(
          `input-table/${dataTableName}?scenario_id=${activeScenarioId}&type=${type}`
        );
      };

      return (
        <div onClick={handleOpenEdit}>
          {supportVectors && !model?.is_base ? (
            <div className="flex justify-between">
              <ul className="list-disc">
                {scenarioVectors?.length
                  ? scenarioVectors.map((vector) => <li>{vector.name}</li>)
                  : '--'}
              </ul>
            </div>
          ) : (
            <div className="w-full h-full opacity-0" aria-hidden="true">
              " "
            </div>
          )}
        </div>
      );
    },
    header: () => <div>Vectors</div>,
  }),
];

const ScenarioGroupInputTable = ({
  groupName,
  dataTableSchemas,
}: ScenarioGroupInputTableProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const data = useMemo(
    () =>
      dataTableSchemas
        ? dataTableSchemas.map((dataTable) => {
            const { name, display_name, active_scenario, support_vectors } =
              dataTable;

            return {
              id: name,
              supportVectors: support_vectors,
              inputTable: display_name ?? display_name ?? 'Unknown',
              scenario: active_scenario,
            };
          })
        : [],
    [dataTableSchemas]
  );

  const handleSetIsLoading = (loading: boolean) => {
    setIsLoading(loading);
  };

  const table = useReactTable({
    data,
    columns: columns({
      header: groupName,
      handleSetIsLoading,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="!rounded-md border border-[#E4E7EC] bg-[#F8F8F8]"
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn(
                    'border border-[#E4E7EC] px-6 py-2 text-start text-sm text-[#4D4D4D]'
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="relative">
          {isLoading && (
            <div className="bg-gray-200 absolute flex h-full w-full items-center justify-center opacity-50">
              <Spinner />
            </div>
          )}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-pointer py-2">
              {row.getVisibleCells().map((cell, index) => (
                <td
                  key={cell.id}
                  className={cn('border border-[#E4E7EC] px-6 text-[#333]', {
                    'w-[300px]': index === 0,
                    'w-[200px]': index === 1,
                    'w-[150px]': index === 2,
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScenarioGroupInputTable;
