import React, { useEffect } from 'react';

import ProjectsView from '../components/projects/ProjectsView';

function Home() {
  useEffect(() => {
    document.title = 'Home | ISBE Group';
  }, []);

  return <ProjectsView />;
}

export default Home;
