import React, { type SyntheticEvent, useMemo, useState } from 'react';

import { Box, Button } from '@mui/material';
import {
  type ColumnsStylesInterface,
  type GridExcelExportOptions,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ArrowDownToLine } from 'lucide-react';

import { useGetModelById, useGetModelOutput } from '../../hooks/useModelsHook';
import { useGetProjectOutputSchema } from '../../hooks/useProjectsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import DataGridPremiumTable from '../DataGridPremiumTable';
import { CustomTabPanel, Tab, Tabs } from '../mui-custom';

import { type Report } from './ModelOutputTabs';

type ModelComparisonOutputTabsProps = {
  baseId: number;
  targetId: number;
};

const ModelComparisonOutputTabs = ({
  baseId,
  targetId,
}: ModelComparisonOutputTabsProps) => {
  const [index, setIndex] = useState(0);
  const [report, setReport] = useState<Report>('financials');

  const { orgId, projectId } = useGetRouteParams();

  const { data: targetModel } = useGetModelById(
    {
      modelId: targetId,
      orgId,
      projectId,
    },
    {
      enabled: !!targetId && !!orgId && !!projectId,
    }
  );

  const { data: baseModel } = useGetModelById(
    {
      modelId: baseId,
      orgId,
      projectId,
    },
    {
      enabled: !!baseId && !!orgId && !!projectId,
    }
  );

  const { data: baseModelOutput, isLoading: isFetchingModelOutput } =
    useGetModelOutput(
      {
        modelId: baseId,
        orgId,
        projectId,
      },
      {
        enabled: !!baseId && !!orgId && !!projectId,
      }
    );

  const { data: modelOutput, isLoading: isFetchingBaseModelOutput } =
    useGetModelOutput(
      {
        modelId: targetId,
        orgId,
        projectId,
      },
      {
        enabled: !!orgId && !!projectId && !!targetId,
      }
    );

  const { data: outputSchema } = useGetProjectOutputSchema(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const reports = Object.keys(modelOutput ?? {}) as Report[];

  const apiRef = useGridApiRef();

  let treeData;

  if (report === 'financials') {
    treeData = {
      headerName: 'Expense',
      groupColumns: ['expense_group_name', 'expense_name', 'crew_type'],
    };
  } else if (report === 'kpis') {
    treeData = {
      headerName: 'KPIs',
      groupColumns: ['stats_name', 'crew_type'],
    };
  } else if (report === 'average_expense') {
    treeData = {
      headerName: 'Average Expense',
      groupColumns: ['expense_name', 'crew_type'],
    };
  }

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const value = event.currentTarget.id as Report;

    setIndex(newValue);
    setReport(value);
  };

  const dataTable = useMemo(
    () =>
      outputSchema?.sections
        ?.find((s) => s.name === report)
        ?.data_tables.find((d) => d.name === report),
    [outputSchema, report]
  );

  const handleExportToExcel = () => {
    const colStyles: ColumnsStylesInterface = {};

    dataTable?.columns.forEach((colDef) => {
      colStyles[colDef.name] = {
        numFmt: '#,###',
        font: {},
        alignment: {},
        protection: {},
        border: {},
        fill: {
          type: 'pattern',
          pattern: 'none',
        },
      };
    });

    const options: GridExcelExportOptions = {
      fileName: `Compare_${baseModel?.name}_and_${targetModel?.name}_${report}`,
      columnsStyles: { ...colStyles },
    };

    apiRef.current.exportDataAsExcel(options);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {reports && (
        <div className="flex items-center justify-between">
          <Tabs value={index} onChange={handleChange}>
            {outputSchema?.sections?.map((s) => (
              <Tab id={s.name} key={s.name} label={s.display_name} />
            ))}
          </Tabs>
          <div className="flex items-center gap-1">
            <Button
              size="small"
              onClick={handleExportToExcel}
              variant="outlined"
              className="space-x-1 font-semibold"
            >
              <ArrowDownToLine />
              <label>Export to Excel</label>
            </Button>
          </div>
        </div>
      )}
      {report && (
        <div className="py-6">
          <CustomTabPanel value={report} index={report}>
            <DataGridPremiumTable
              treeData={treeData ?? undefined}
              apiRef={apiRef}
              columns={
                dataTable?.columns.map((c) => ({
                  label: c.display_name,
                  name: c.name,
                  is_pinned: c.is_pinned,
                  data_type: c.data_type,
                  styles: c.styles,
                })) ?? []
              }
              isLoading={isFetchingBaseModelOutput || isFetchingModelOutput}
              base={{
                data: baseModelOutput?.[report],
                mode: 'compare',
              }}
              data={modelOutput?.[report]}
            />
          </CustomTabPanel>
        </div>
      )}
    </Box>
  );
};

export default ModelComparisonOutputTabs;
