import _ from 'lodash';

import { type TypeProject } from '../common/dataTypes';
import { type Role } from '../types';
import { mainApi, unprotectedMainApi } from '../utils/api';

export type CreateOrganizationInput = {
  data: {
    name: string;
    description: string;
  };
};

export type CreateOrganizationMemberInput = {
  orgId: number;
  data: {
    token: string;
    email: string;
    first_name: string;
    last_name: string;
    password: string;
  };
};

export type CreateOrganizationProjectInput = {
  orgId: number;
  data: {
    name: string;
    description: string;
  };
};

export type CreateOrgInvitationInput = {
  orgId: number;
  data: {
    email: string;
    role: Role;
  };
};

export type DeleteMemberByIdInput = { orgId: number; userId: number };

export type VerifyInvitationInput = {
  orgId: number;
  invitationId: number;
  data: {
    token: string;
  };
};

export type ResendInvitationInput = {
  orgId: number;
  invitationId: number;
};

export type GetOrgInvitationByIdInput = {
  orgId: number;
  invitationId: number;
};

export type GetOrgMemberByIdInput = {
  orgId: number;
  memberId: number;
};

export type DeleteOrgInvitationByIdInput = {
  orgId: number;
  invitationId: number;
};

export type UpdateOrganizationMemberInput = {
  orgId: number;
  memberId: number;
  data: {
    first_name?: string;
    last_name?: string;
    email?: string;
    role: Role;
    organization: number;
    projects?: TypeProject[];
  };
};

export type UpdateOrganizationInvitationInput = {
  id: number;
  invitationId: number;
  data: {
    is_new_user?: number;
    role?: Role;
    email?: string;
    organization?: number;
    last_name?: string;
    projects?: number[];
  };
};

export type AcceptOrgReceivedInvitationInput = {
  orgId: number;
  invitationId: number;
};

export type RejectOrgReceivedInvitationInput = {
  orgId: number;
  invitationId: number;
};

export type GetSharedRequestByIdInput = {
  orgId: number;
  requestId: number;
};

export type ApproveSharedRequestInput = {
  orgId: number;
  reqId: number;
};

export type DisapproveSharedRequestInput = {
  orgId: number;
  reqId: number;
};

export type GetOrgReceivedInvitationsInput = {
  orgId: number;
};

export type GetOrganizationAuditLogsInput = {
  orgId: number;
  params?: Record<string, string>;
};
export type GetOrganizationInvitationsInput = {
  orgId: number;
  projects?: string;
  roles?: string;
};

export type GetOrganizationByIdInput = {
  orgId: number;
};

export type GetOrgMembersInput = {
  orgId: number;
  projects?: string;
  roles?: string;
};

export type LeaveOrganizationInput = {
  orgId: number;
};

export type TransferOrgOwnershipInput = {
  orgId: number;
  data: {
    user: number;
  };
};

export type VerifySharingInvitationInput = {
  orgId: number;
  projectId: number;
  data: {
    token: string;
  };
};

export type InviteGuestOrgMemberInput = {
  orgId: number;
  projectId: number;
  data: {
    email: string;
    role: string;
  };
};

const getOrganizations = () => mainApi.get('organizations/');

const getOrganizationAuditLogs = ({
  orgId,
  params,
}: GetOrganizationAuditLogsInput) => {
  if (_.isEmpty(params) || !params) {
    return mainApi.get(`organizations/${orgId}/audit_logs/`);
  }

  return mainApi.get(
    `organizations/${orgId}/audit_logs/?${Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&')}`
  );
};

const getOrganizationInvitations = ({
  orgId,
  projects,
  roles,
}: GetOrganizationInvitationsInput) => {
  let params;

  if (projects && roles) {
    params = `projects=${projects}&roles=${roles}`;
  } else if (projects) {
    params = `projects=${projects}`;
  } else if (roles) {
    params = `roles=${roles}`;
  }

  return mainApi.get(
    `organizations/${orgId}/invitations/${params ? `?${params}` : ''}`
  );
};

const getOrgReceivedInvitations = ({ orgId }: GetOrgReceivedInvitationsInput) =>
  mainApi.get(`organizations/${orgId}/received_invitations/`);

const acceptOrgReceivedInvitation = ({
  orgId,
  invitationId,
}: AcceptOrgReceivedInvitationInput) =>
  mainApi.post(
    `organizations/${orgId}/received_invitations/${invitationId}/accept/`
  );

const rejectOrgReceivedInvitation = ({
  orgId,
  invitationId,
}: RejectOrgReceivedInvitationInput) =>
  mainApi.post(
    `organizations/${orgId}/received_invitations/${invitationId}/reject/`
  );

const createOrganization = ({ data }: CreateOrganizationInput) =>
  mainApi.post('organizations/', data);

const getOrganizationById = ({ orgId }: GetOrganizationByIdInput) =>
  unprotectedMainApi.get(`organizations/${orgId}/`);

const verifyInvitation = ({
  orgId,
  invitationId,
  data,
}: VerifyInvitationInput) =>
  unprotectedMainApi.post(
    `organizations/${orgId}/invitations/${invitationId}/verify/`,
    data
  );

const resendInvitation = ({ orgId, invitationId }: ResendInvitationInput) =>
  mainApi.post(`organizations/${orgId}/invitations/${invitationId}/resend/`);

const getOrgInvitationById = ({
  orgId,
  invitationId,
}: GetOrgInvitationByIdInput) =>
  unprotectedMainApi.get(`organizations/${orgId}/invitations/${invitationId}/`);

const deleteOrganizationById = (id: string) =>
  mainApi.delete(`organizations/${id}/`);

const leaveOrganization = ({ orgId }: LeaveOrganizationInput) =>
  mainApi.post(`organizations/${orgId}/leave/`);

const transferOrganization = ({ orgId, data }: TransferOrgOwnershipInput) =>
  mainApi.post(`organizations/${orgId}/transfer/`, data);

const deleteOrgInvitationById = ({
  orgId,
  invitationId,
}: DeleteOrgInvitationByIdInput) =>
  mainApi.delete(`organizations/${orgId}/invitations/${invitationId}/`);

const getOrgMembers = ({ orgId, projects, roles }: GetOrgMembersInput) => {
  let params;

  if (projects && roles) {
    params = `projects=${projects}&roles=${roles}`;
  } else if (projects) {
    params = `projects=${projects}`;
  } else if (roles) {
    params = `roles=${roles}`;
  }

  return mainApi.get(
    `organizations/${orgId}/members/${params ? `?${params}` : ''}`
  );
};

const getOrgMemberById = ({ orgId, memberId }: GetOrgMemberByIdInput) =>
  mainApi.get(`organizations/${orgId}/members/${memberId}`);

const createMember = ({ orgId, data }: CreateOrganizationMemberInput) =>
  unprotectedMainApi.post(`organizations/${orgId}/members/register/`, data);

const createOrgInvitation = ({ orgId, data }: CreateOrgInvitationInput) =>
  mainApi.post(`organizations/${orgId}/invitations/`, data);

const updateMember = ({
  orgId,
  memberId,
  data,
}: UpdateOrganizationMemberInput) =>
  mainApi.patch(`organizations/${orgId}/members/${memberId}/`, data);

const updateInvitation = ({
  id,
  invitationId,
  data,
}: UpdateOrganizationInvitationInput) =>
  mainApi.patch(`organizations/${id}/invitations/${invitationId}/`, data);

const deleteMemberById = ({ orgId, userId }: DeleteMemberByIdInput) =>
  mainApi.delete(`organizations/${orgId}/members/${userId}/`);

const getSharedRequests = (orgId: number) =>
  mainApi.get(`organizations/${orgId}/sharing_requests/`);

const getSharedRequestById = ({
  orgId,
  requestId,
}: GetSharedRequestByIdInput) =>
  mainApi.get(`organizations/${orgId}/sharing_requests/${requestId}`);

const approveSharedInvitation = ({ orgId, reqId }: ApproveSharedRequestInput) =>
  mainApi.post(
    `organizations/${orgId}/sent_sharing_invitations/${reqId}/approve/`
  );

const disapproveSharedInvitation = ({
  orgId,
  reqId,
}: DisapproveSharedRequestInput) =>
  mainApi.post(
    `organizations/${orgId}/sent_sharing_invitations/${reqId}/disapprove/`
  );

const verifySharingInvitation = ({
  orgId,
  projectId,
  data,
}: VerifySharingInvitationInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/members/verify/`,
    data
  );

const inviteGuestOrgMember = ({
  orgId,
  projectId,
  data,
}: InviteGuestOrgMemberInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/members/invite_guest/`,
    data
  );

export {
  acceptOrgReceivedInvitation,
  approveSharedInvitation,
  createMember,
  createOrganization,
  createOrgInvitation,
  deleteMemberById,
  deleteOrganizationById,
  deleteOrgInvitationById,
  disapproveSharedInvitation,
  getOrganizationById,
  getOrganizationInvitations,
  getOrganizations,
  getOrgInvitationById,
  getOrganizationAuditLogs,
  getOrgMemberById,
  getOrgMembers,
  getOrgReceivedInvitations,
  getSharedRequestById,
  getSharedRequests,
  leaveOrganization,
  rejectOrgReceivedInvitation,
  resendInvitation,
  transferOrganization,
  updateInvitation,
  updateMember,
  verifyInvitation,
  verifySharingInvitation,
  inviteGuestOrgMember,
};
