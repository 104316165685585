import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Alert, Button } from '@mui/material';
import * as z from 'zod';

import { extractValue } from '../../common/utils';
import {
  useCreateDataset,
  useUpdateDataset,
} from '../../hooks/useDatasetsHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { handleQueryError } from '../../utils/api';
import FormInput from '../FormInput';
import WarningDialog from '../WarningDialog';

const CreateDatasetSchema = z.object({
  name: z.string().trim().trim().min(1, 'Dataset name is required'),
  description: z.string().trim().optional(),
});

type CreateProjectSchemaType = z.infer<typeof CreateDatasetSchema>;

type CreateDatasetFormProps = {
  handleOnCancel?: () => void;
  handleOnSubmit?: () => void;
  defaultValues?: CreateProjectSchemaType & {
    id: number;
  };
};

const CreateDatasetForm = ({
  defaultValues,
  handleOnCancel,
  handleOnSubmit,
}: CreateDatasetFormProps) => {
  const [error, setError] = useState(Object);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);

  const { orgId, projectId } = useGetRouteParams();

  const errorMessage = extractValue(error);

  const form = useForm<CreateProjectSchemaType>({
    resolver: zodResolver(CreateDatasetSchema),
    defaultValues,
  });

  const { handleSubmit, reset, formState } = form;

  const { isDirty } = formState;

  const { mutateAsync: createDataset, isPending: isCreatingDataset } =
    useCreateDataset({
      onSuccess: () => {
        handleOnSubmit?.();
      },
      onError: (error) => {
        handleQueryError(error, setError);
      },
    });

  const { mutateAsync: updateDataset, isPending: isUpdatingDataset } =
    useUpdateDataset({
      onSuccess: () => {
        handleOnSubmit?.();
      },
      onError: (error) => {
        handleQueryError(error, setError);
        handleCloseWarningDialog();
      },
    });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const handleCreateDataset: SubmitHandler<CreateProjectSchemaType> = async (
    data
  ) => {
    if (!orgId) {
      return;
    }

    await createDataset({
      orgId,
      projectId,
      data: {
        ...data,
        project: projectId,
      },
    });
  };

  const handleUpdateDataset = async () => {
    if (!orgId || !defaultValues) {
      return;
    }

    const formData = form.getValues();

    await updateDataset({
      datasetId: defaultValues.id,
      projectId,
      orgId,
      data: {
        ...formData,
        project: projectId,
      },
    });

    return;
  };

  const handleOpenWarningDialog = () => setOpenWarningDialog(true);

  const handleCloseWarningDialog = () => setOpenWarningDialog(false);

  return (
    <>
      <FormProvider {...form}>
        <form className="space-y-4 ">
          <div className="space-y-4">
            <FormInput
              label="Name"
              inputProps={{
                maxLength: 100,
              }}
              placeholder="Name of dataset"
              name="name"
            />
            <FormInput
              label="Description"
              multiline
              rows={4}
              placeholder="(Optional)"
              name="description"
            />
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <div className="flex justify-end gap-3 pb-4">
              <Button
                onClick={handleOnCancel}
                variant="outlined"
                type="button"
                style={{
                  color: '#666',
                  borderColor: '#B3B3B3',
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isCreatingDataset || isUpdatingDataset}
                type="button"
                onClick={() => {
                  if (defaultValues) {
                    if (isDirty) {
                      handleOpenWarningDialog();
                    } else {
                      handleUpdateDataset();
                    }
                  } else {
                    handleSubmit(handleCreateDataset)();
                  }
                }}
                disabled={!isDirty && !!defaultValues}
                variant="contained"
              >
                {defaultValues ? 'Save' : 'Create Dataset'}
              </LoadingButton>
            </div>
          </div>
        </form>
      </FormProvider>
      <WarningDialog
        open={openWarningDialog}
        primaryActionText="Confirm"
        isPending={isUpdatingDataset || isCreatingDataset}
        handlePrimaryAction={() => {
          handleUpdateDataset();
        }}
        secondaryActionText="Cancel"
        handleSecondaryAction={handleCloseWarningDialog}
        content={
          <div className="space-y-2">
            <p>
              Updating this dataset will create a new Input File Set and remove
              all previously imported files. This action is irreversible.
            </p>
            <p>Confirm to proceed or cancel to keep your current files.</p>
          </div>
        }
      />
    </>
  );
};

export default CreateDatasetForm;
