import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  type InputBaseComponentProps,
  OutlinedInput as MuiOutlinedInput,
  styled,
} from '@mui/material';
import { ChevronDown, ChevronUp, Eye, EyeOff } from 'lucide-react';

import cn from '../utils/cn';

const OutlinedInput = styled(MuiOutlinedInput)(({ error }) => ({
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
  },
}));

type FormInputProps = {
  label?: string;
  name: string;
  placeholder?: string;
  cta?: {
    label: string;
    onClick?: () => void;
  };
  helperText?: string;
  helperTextStyle?: React.CSSProperties;
  type?: 'text' | 'number' | 'password';
  endAdornment?: React.ReactNode;
  fullWidth?: boolean;
  defaultValue?: string;
  value?: string | number;
  rows?: number;
  multiline?: boolean;
  required?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  labelClassName?: string;
  minRows?: number;
  inputProps?: InputBaseComponentProps;
};

const FormInput = ({
  label,
  name,
  type = 'text',
  cta,
  value,
  placeholder,
  endAdornment,
  multiline,
  rows,
  labelClassName,
  helperText,
  fullWidth = true,
  required = false,
  style,
  ...props
}: FormInputProps) => {
  const { register, formState, setValue, watch } = useFormContext();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const currentValue = watch(name);

  const handleIncrease = () => {
    setValue(name, Number(currentValue) + 1);
  };

  const handleDecrease = () => {
    setValue(name, Number(currentValue) - 1);
  };

  const { errors } = formState;

  return (
    <FormControl {...props} fullWidth={fullWidth}>
      {label && (
        <label
          htmlFor={name}
          className={cn(
            'min-h-[28px] font-semibold pb-2 text-sm text-[#666]',
            labelClassName
          )}
        >
          {label}
        </label>
      )}
      <OutlinedInput
        size="small"
        required={required}
        value={value}
        id={name}
        type={type === 'password' && showPassword ? 'text' : type}
        rows={rows}
        multiline={(rows && rows > 1) || multiline}
        placeholder={placeholder}
        error={!!errors[name]}
        style={{
          backgroundColor: props.disabled ? '#F8F8F8' : '',
          borderRadius: 4,
          border: '1px #FF816A !important',
          ...style,
        }}
        inputProps={{
          min: 0,
          ...props.inputProps,
        }}
        {...register(name, {
          min: 0,
        })}
        endAdornment={
          type === 'password' ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <EyeOff width={20} height={20} />
                ) : (
                  <Eye width={20} height={20} />
                )}
              </IconButton>
            </InputAdornment>
          ) : type === 'number' ? (
            <div className="flex flex-col text-[#666]">
              <ChevronUp
                onClick={handleIncrease}
                size={15}
                className="cursor-pointer p-0"
              />
              <ChevronDown
                onClick={handleDecrease}
                size={15}
                className="cursor-pointer p-0"
              />
            </div>
          ) : (
            endAdornment || null
          )
        }
        disabled={props.disabled}
      />
      {!errors[name] && (
        <FormHelperText
          style={{
            fontSize: '14px',
            marginLeft: 0,
            ...props.helperTextStyle,
          }}
        >
          {helperText}
        </FormHelperText>
      )}
      <div className="flex justify-between">
        {errors[name] && (
          <p className="w-full pt-2 text-start text-[14px] text-primary-red">
            {errors[name]?.message as string}
          </p>
        )}
        {cta && (
          <button
            type="button"
            onClick={cta.onClick}
            className="w-full pt-2 text-end text-[14px] font-light text-[#9CD3FF]"
          >
            {cta.label}
          </button>
        )}
      </div>
    </FormControl>
  );
};

export default FormInput;
