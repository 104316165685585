import { X } from 'lucide-react';

import cn from '../utils/cn';

const EmailInputChips = ({
  emails,
  onRemoveEmail,
}: {
  emails: { value: string; isError?: boolean }[];
  onRemoveEmail: (email: string) => void;
}) => (
  <>
    {emails.map((email) => (
      <div
        className={cn(
          'flex w-fit items-center gap-2 rounded-[4px] border-2 border-[#CCCCCC] p-1',
          {
            'border-primary-red text-primary-red': email.isError,
          }
        )}
      >
        <p className="text-xs">{email.value}</p>
        <X
          className="cursor-pointer"
          width={20}
          height={20}
          color={email.isError ? '#B8341B' : '#CCCCCC'}
          onClick={() => {
            onRemoveEmail(email.value);
          }}
        />
      </div>
    ))}
  </>
);

export default EmailInputChips;
