import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import * as z from 'zod';

import CreateDatasetForm from './CreateDatasetForm';

const CreateDatasetSchema = z.object({
  name: z.string().trim().trim().min(1, 'Dataset name is required'),
  description: z.string().trim().optional(),
});

type CreateDatasetSchemaType = z.infer<typeof CreateDatasetSchema>;

type CreateDatasetDialogProps = {
  open: boolean;
  handleCloseDialog: () => void;
  handleSubmitDialog: () => void;
  trigger?: {
    className?: React.HTMLAttributes<HTMLButtonElement>['className'];
    label: React.ReactNode;
  };
  defaultValues?: CreateDatasetSchemaType & {
    id: number;
  };
};

const CreateDatasetDialog = ({
  defaultValues,
  trigger,
  open,
  handleSubmitDialog,
  handleCloseDialog,
}: CreateDatasetDialogProps) => (
  <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
    <DialogTitle>
      {defaultValues ? `Edit ${defaultValues.name}` : 'Create A New Dataset'}
    </DialogTitle>
    <div className="px-6">
      <CreateDatasetForm
        handleOnCancel={handleCloseDialog}
        handleOnSubmit={handleSubmitDialog}
        defaultValues={defaultValues}
      />
    </div>
  </Dialog>
);

export default CreateDatasetDialog;
