import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import { ChevronDown, ChevronUp } from 'lucide-react';

import { isOrgAdmin, useGetRouteParams } from '../../hooks/useUtilsHook';
import { useCurrentOrganization } from '../../utils/helpers';
import MenuDropdown from '../MenuDropdown';
import SearchInput from '../SearchInput';

import InviteProjectFormDialog from './InviteProjectFormDialog';
import ProjectMembersTable from './ProjectMembersTable';

const RoleFilterDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleFilterByRole = (role: 'owner' | 'member') => {
    searchParams.set('role', role);

    setSearchParams(searchParams);
  };

  return (
    <MenuDropdown
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      open={open}
      trigger={
        <div className="flex w-full gap-2 px-3 text-[#666666]">
          <p>Roles</p>
          {open ? <ChevronUp width={15} /> : <ChevronDown width={15} />}
        </div>
      }
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      triggerClassName="rounded-md border border-[#CCCCCC] pl-3 py-1.5"
    >
      <MenuItem
        onClick={() => {
          handleFilterByRole('owner');
        }}
        style={{
          width: '100px',
        }}
      >
        Owner
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleFilterByRole('member');
        }}
      >
        Member
      </MenuItem>
    </MenuDropdown>
  );
};

const ProjectMembersManagement = () => {
  const [openInviteFormDialog, setOpenInviteFormDialog] = useState(false);

  const currentOrg = useCurrentOrganization();

  const isAdmin = currentOrg && isOrgAdmin(currentOrg.role);

  const { projectId } = useGetRouteParams();

  const form = useForm();

  const { watch } = form;

  const searchKey = watch('search');

  const handleOpenInviteFormDialog = () => {
    setOpenInviteFormDialog(true);
  };

  const handleCloseInviteFormDialog = () => {
    setOpenInviteFormDialog(false);
  };

  return (
    <div className="mx-auto flex flex-col gap-6 px-12 py-6">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          <h1 className="text-3xl font-bold text-[#333]">Members</h1>
        </div>
        <div>
          {isAdmin && (
            <InviteProjectFormDialog
              iProjectId={projectId}
              inviteUserMode="multiple"
              open={openInviteFormDialog}
              handleClose={handleCloseInviteFormDialog}
              handleOpen={handleOpenInviteFormDialog}
            />
          )}
        </div>
      </div>
      <FormProvider {...form}>
        <form className="flex w-full gap-2">
          <div className="max-w-[300px] w-full">
            <SearchInput />
          </div>
          <RoleFilterDropdown />
        </form>
      </FormProvider>
      <div>
        <ProjectMembersTable searchKey={searchKey} />
      </div>
    </div>
  );
};

export default ProjectMembersManagement;
