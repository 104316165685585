import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  acceptReceivedSharingInvitation,
  getReceivedSharingInvitations,
  rejectReceivedSharingInvitation,
} from '../api';
import {
  type AcceptReceivedSharingInvitationInput,
  type GetReceivedSharingInvitationsInput,
  type RejectReceivedSharingInvitationInput,
} from '../api/receivedSharingInvitations';
import {
  type TypeReceivedSharedInvitation,
  type TypeSharedInvitation,
} from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

export const useGetReceivedSharingInvitations = (
  input: GetReceivedSharingInvitationsInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeReceivedSharedInvitation[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getReceivedSharingInvitations'],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeReceivedSharedInvitation[]> =
        await getReceivedSharingInvitations(input);

      return data;
    },
    ...opts,
  });

export const useAcceptReceivedSharingInvitation = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<TypeSharedInvitation>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['acceptReceivedSharingInvitation'],
    mutationFn: async (input: AcceptReceivedSharingInvitationInput) => {
      const { data }: AxiosResponse<TypeSharedInvitation> =
        await acceptReceivedSharingInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getReceivedSharingInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};
export const useRejectReceivedSharingInvitation = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<TypeSharedInvitation>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['rejectReceivedSharingInvitation'],
    mutationFn: async (input: RejectReceivedSharingInvitationInput) => {
      const { data }: AxiosResponse<TypeSharedInvitation> =
        await rejectReceivedSharingInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getReceivedSharingInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};
