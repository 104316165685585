import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

export type StyledTabProps = {
  label: string;
  id: string;
};

export type StyledTabsProps = {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};

export type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#B8341B',
  },
});

const Tab = styled(
  (
    props: StyledTabProps & {
      disabled?: boolean;
    }
  ) => <MuiTab disableRipple {...props} />
)({
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'none',
  '&.MuiButtonBase-root': {
    padding: '10px 10px !important',
  },
  '&.Mui-selected': {
    color: '#B8341B',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="space-y-6"
      role="tabpanel"
      hidden={value !== index}
      id={value}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export { Tab, Tabs, CustomTabPanel };
