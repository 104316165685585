import React, { useMemo, useRef, useState } from 'react';
import { FormProvider, type SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem as MuiMenuItem,
  styled,
} from '@mui/material';
import { AxiosError } from 'axios';
import { UserPlus } from 'lucide-react';
import * as z from 'zod';

import EmailInputChips from '../../common/EmailInputChips';
import { ProjectRolesOptions } from '../../common/utils';
import { useShareModel } from '../../hooks/useModelsHook';
import { useGetUser } from '../../hooks/useUserHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import cn from '../../utils/cn';
import { StyledSelect } from '../CustomFormAutoComplete';
import SuccessDialog from '../SuccessDialog';

const EmailSchema = z.string().email('Invalid email address');

type EmailSchemaType = z.infer<typeof EmailSchema>;

const MenuItem = styled(MuiMenuItem)({
  fontSize: '14px',
});

type ShareModelFormDialogProps = {
  roles: {
    label: string;
    value: string;
  }[];
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
  };
};

const ShareModelFormDialog = ({ trigger }: ShareModelFormDialogProps) => {
  const inputId = 'emails';
  const [open, setOpen] = useState(false);

  const { projectId, modelId, orgId } = useGetRouteParams();

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const [emails, setEmails] = useState<{ value: string; isError?: boolean }[]>(
    []
  );

  const ref = useRef<HTMLInputElement>(null);

  const form = useForm({
    defaultValues: {
      email: '',
      emails: [],
      separate_guests: false,
      role: ProjectRolesOptions[0].value,
    },
  });

  const {
    handleSubmit,
    formState,
    setError,
    reset,
    register,
    clearErrors,
    watch,
  } = form;

  const { isSubmitting, errors } = formState;

  const { data: currentUser } = useGetUser();

  const role = watch('role');
  const separate_guests = watch('separate_guests');

  const { mutateAsync: shareModel } = useShareModel({
    onSuccess: () => {
      handleOpenSuccessDialog();
    },
    onError(error) {
      if (error instanceof AxiosError && error.response) {
        setError('email', { message: error.response.data });
        return;
      }
    },
  });

  const handleOpenSuccessDialog = () => {
    setOpenSuccessDialog(true);
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    reset();
    setEmails([]);
    setOpen(false);
  };

  const handleError = ({
    message,
    email,
    ref,
  }: {
    email: string;
    message: string;
    ref: React.RefObject<HTMLInputElement>;
  }) => {
    setError(`${inputId}.${email.replaceAll('.', '')}` as any, { message });
    setEmails((prev) => [
      ...prev,
      {
        value: email,
        isError: true,
      },
    ]);
    if (ref.current) {
      ref.current.value = '';
    }
  };

  const handleAddEmail = (email: EmailSchemaType) => {
    const { error } = EmailSchema.safeParse(email);

    if (error) {
      handleError({
        email,
        message: error.errors[0].message,
        ref,
      });
      return;
    }

    if (currentUser?.email === email) {
      handleError({
        email,
        message: "You're not able to share to yourself.",
        ref,
      });
      return;
    }

    setEmails((prev) => [...prev, { value: email }]);

    if (ref.current) {
      ref.current.value = '';
    }
  };

  const handleRemoveEmail = (email: string) => {
    setEmails((prev) => prev.filter((e) => e.value !== email));
    clearErrors(`${inputId}.${email.replaceAll('.', '')}` as any);
  };

  const onSubmit: SubmitHandler<any> = async () => {
    if (!modelId || !role) {
      return;
    }

    const currentEmail = ref.current?.value;

    if (currentEmail === currentUser?.email) {
      setError(inputId as any, {
        message: "You're not able to share to yourself.",
      });
      return;
    }

    if (currentEmail) {
      await shareModel({
        projectId: Number(projectId),
        modelId: Number(modelId),
        orgId,
        data: {
          separate_guests,
          guests: [
            {
              email: currentEmail,
              role,
            },
          ],
        },
      });
    } else {
      await shareModel({
        projectId: Number(projectId),
        modelId: Number(modelId),
        orgId,
        data: {
          separate_guests,
          guests: emails.map((email) => ({
            email: email.value,
            role,
          })),
        },
      });
    }

    setEmails([]);
    reset();
    setOpen(false);
    return;
  };

  const isDisabled = isSubmitting || !!emails.find((e) => e.isError) || false;

  const errorsKeys = Object.keys(errors[inputId] ?? {});

  const errorMessage = useMemo(
    () =>
      errors[inputId]
        ? (errors[inputId] as unknown as Record<string, { message: string }>)[
            errorsKeys[errorsKeys.length - 1]
          ].message
        : null,
    [errorsKeys]
  );

  return (
    <div>
      <Button
        type="button"
        variant="outlined"
        onClick={handleOpenDialog}
        style={{
          color: '#B8341B',
          borderColor: '#B8341B',
          display: 'flex',
          gap: 4,
          alignItems: 'center',
          borderRadius: 4,
          padding: '6px 12px',
          ...trigger?.style,
        }}
      >
        {trigger?.label ?? (
          <>
            <UserPlus width={20} height={20} />
            <p>Share Model</p>
          </>
        )}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          if (isSubmitting) {
            return;
          }

          handleCloseDialog();
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="w-full">
          <h2 className="pt-2 text-xl font-bold capitalize">
            Share this model
          </h2>
        </DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form>
              <div className="mb-2 space-y-6">
                <div className="flex flex-col gap-2">
                  <div
                    className={cn(
                      'flex items-center justify-between rounded-[4px] border-2 border-[#2196F3] px-2',
                      {
                        'border-primary-red': errorMessage,
                      }
                    )}
                  >
                    <div className="flex w-full flex-wrap items-center gap-1 py-2">
                      <EmailInputChips
                        emails={emails}
                        onRemoveEmail={handleRemoveEmail}
                      />
                      <input
                        ref={ref}
                        name="email"
                        type="email"
                        onKeyDown={(e) => {
                          const keyCode = e.key;

                          const isEmpty =
                            e.currentTarget.value.replace(',', '').length === 0;

                          if (isEmpty && ref.current) {
                            ref.current.value = '';
                            return;
                          }

                          if (keyCode === 'Enter' || keyCode === ',') {
                            handleAddEmail(
                              e.currentTarget.value.replace(',', '')
                            );
                          }
                        }}
                        className="grow outline-none"
                      />
                    </div>
                    <StyledSelect
                      {...register('role')}
                      size="small"
                      defaultValue={ProjectRolesOptions[0].value}
                      className="bottom-0"
                      placeholder="Please select"
                    >
                      {ProjectRolesOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <span className="capitalize text-sm">
                            {option.label}
                          </span>
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="separate_guests"
                      className="group flex cursor-pointer items-center py-3"
                    >
                      <Checkbox
                        id="separate_guests"
                        {...register('separate_guests')}
                      />
                      <p>
                        Send individual invitation to each guest separately.
                      </p>
                    </label>
                  </div>
                  {errorMessage && (
                    <p className="px-2 text-sm text-primary-red">
                      {errorMessage}
                    </p>
                  )}
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialog}
                    variant="outlined"
                    type="button"
                    disabled={isSubmitting}
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={isSubmitting}
                    disabled={isDisabled}
                    type="button"
                    onClick={handleSubmit((event) => onSubmit(event))}
                    variant="contained"
                  >
                    Send
                  </LoadingButton>
                </div>
              </div>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      <SuccessDialog
        open={openSuccessDialog}
        handleOnClose={handleCloseSuccessDialog}
        content="We've sent the invitation to approved users."
      />
    </div>
  );
};

export default ShareModelFormDialog;
