import React, { useEffect, useMemo } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { isNaN } from 'lodash';
import { Plus } from 'lucide-react';
import { z } from 'zod';

import { type TypeScenarioVectorCriteria } from '../../common/dataTypes';
import { useUpdateVector } from '../../hooks';
import { useGetIFSConfig } from '../../hooks/useDatasetsHook';
import { useGetDataTableByName } from '../../hooks/useDataTablesHook';
import { useGetScenarioData } from '../../hooks/useScenariosHook';
import { useGetRouteParams } from '../../hooks/useUtilsHook';
import { useCreateVector } from '../../hooks/useVectorHook';
import cn from '../../utils/cn';
import { transformToLabel, transformToValue } from '../../utils/helpers';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

type CreateScenarioFormDialogProps = {
  open: boolean;
  title: string;
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: () => void;
  };
  showTrigger?: boolean;
  defaultValues?: CreateVectorSchemaType;
  handleOpenDialog: () => void;
  handleCloseDialog: () => void;
  handleSubmitDialog?: () => void;
  dataset?: string;
  dataTable?: string;
};

const CreateVectorSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1, { message: 'Vector name is required' }),
  description: z.string().optional(),
  aircraft_type: z
    .string({
      required_error: 'Select an aircraft type',
    })
    .optional(),
  crew_type: z
    .string({
      required_error: 'Select a crew type',
    })
    .optional(),
  years_of_services: z.string().or(z.number()).optional(),
  airport_code: z.string().or(z.number()).optional(),
  start_period: z
    .string({
      required_error: 'Select a start period',
    })
    .optional()
    .nullable(),
  end_period: z
    .string({
      required_error: 'Select an end period',
    })
    .optional()
    .nullable(),
  action: z.string({
    required_error: 'Select an action',
  }),
  type: z.string({
    required_error: 'Select a type',
  }),
  amount: z.coerce.number().gt(0, 'Enter a positive number'),
});

type CreateVectorSchemaType = z.infer<typeof CreateVectorSchema>;

const VECTOR_DIRECTION_INCREASE = 'increase';
const VECTOR_DIRECTION_DECREASE = 'decrease';
const VECTOR_DIRECTION_SET = 'set';
const VECTOR_TYPE_DELTA = 'delta';
const VECTOR_TYPE_PERCENTAGE = 'percentage';

const CreateVectorFormDialog = ({
  open,
  title,
  trigger,
  showTrigger = true,
  defaultValues,
  handleOpenDialog,
  handleCloseDialog,
  handleSubmitDialog,
}: CreateScenarioFormDialogProps) => {
  const form = useForm<CreateVectorSchemaType>({
    resolver: zodResolver(CreateVectorSchema),
    defaultValues,
  });

  const { handleSubmit, watch, reset, setValue } = form;

  const [searchParams] = useSearchParams();

  const { projectId, orgId, inputTableName, modelId } = useGetRouteParams();

  const scenarioId = Number(searchParams.get('scenario_id'));

  const { data: dataTable } = useGetDataTableByName(
    {
      dataTableName: inputTableName ?? '',
      orgId,
      projectId,
      modelId,
    },
    {
      enabled: !!inputTableName && !!orgId && !!projectId && !!modelId,
    }
  );

  const { data: ifsConfig } = useGetIFSConfig(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { data: scenarioData, isRefetching: isRefetchingScenarioData } =
    useGetScenarioData(
      {
        scenarioId,
        orgId,
        modelId,
        projectId,
        dataName: '',
      },
      {
        enabled: !!scenarioId && !!orgId && !!projectId && !!modelId,
      }
    );

  const { mutate: createVector, isPending: isCreatingVector } = useCreateVector(
    {
      onError(error) {
        console.error(error);
      },
      onSuccess() {
        handleCloseDialogCreateVector();
      },
    }
  );

  const { mutate: updateVector, isPending: isUpdatingVector } = useUpdateVector(
    {
      onSuccess() {
        handleSubmitDialog?.();
        handleCloseDialogCreateVector();
      },
    }
  );

  const filterableColumns = dataTable?.filterable_columns ?? [];

  const periods =
    ifsConfig?.periods.map((p) => ({
      label: p,
      value: p,
    })) ?? [];

  const filterableFormFields = useMemo(() => {
    const data = scenarioData;

    if (!data) {
      return;
    }

    return filterableColumns.reduce(
      (acc, cur) => {
        const curData = Array.from(
          new Set(Object.values(data[cur] ?? {}))
        ) as string[];

        if (cur === 'period') {
          acc['from'] = Object.keys(data).filter((d) => !isNaN(Number(d)));
          acc['to'] = Object.keys(data).filter((d) => !isNaN(Number(d)));

          return acc;
        }

        acc[cur] = ['All'].concat(curData);

        return acc;
      },
      {} as Record<string, string[]>
    );
  }, [scenarioData, filterableColumns]);

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (filterableFormFields) {
      Object.entries(filterableFormFields).forEach(([key, value]) => {
        if (key === 'to') {
          setValue(
            key as keyof CreateVectorSchemaType,
            value[value.length - 1]
          );
          return;
        }
        setValue(key as keyof CreateVectorSchemaType, value[0]);
      });
    }
  }, [filterableFormFields]);

  const handleOpenDialogCreateVector = () => {
    trigger?.onClick?.();
    handleOpenDialog();
  };

  const handleCloseDialogCreateVector = () => {
    reset();
    handleCloseDialog();
  };

  const onSubmit: SubmitHandler<CreateVectorSchemaType> = () => {
    const {
      start_period,
      end_period,
      action,
      type,
      amount,
      name,
      description,
    } = watch();

    const tempCriterias = filterableColumns.reduce((acc, cur) => {
      const tempValue = {
        field_name: '',
        value: '',
        value_from: '',
        value_to: '',
      } as TypeScenarioVectorCriteria;

      tempValue.field_name = cur;
      tempValue.value = watch()[cur as keyof CreateVectorSchemaType];
      tempValue.operator = 'eq';

      acc.push(tempValue);

      return acc;
    }, [] as TypeScenarioVectorCriteria[]);

    const tempVector: any = {
      name,
      description,
      scenario: Number(scenarioId),
      delta: undefined,
      delta_percentage: undefined,
      start_period,
      end_period,
      new_value: undefined,
      criterias: tempCriterias,
    };

    if (action === VECTOR_DIRECTION_INCREASE) {
      if (type === VECTOR_TYPE_DELTA) {
        tempVector.delta = amount;
      } else if (type === VECTOR_TYPE_PERCENTAGE) {
        tempVector.delta_percentage = amount;
      }
    } else if (action === VECTOR_DIRECTION_DECREASE) {
      if (type === VECTOR_TYPE_DELTA) {
        tempVector.delta = -amount;
      } else if (type === VECTOR_TYPE_PERCENTAGE) {
        tempVector.delta_percentage = -amount;
      }
    } else if (action === VECTOR_DIRECTION_SET) {
      tempVector.new_value = amount;
    }

    if (defaultValues) {
      updateVector({
        orgId,
        projectId,
        modelId,
        scenarioId,
        vectorId: Number(defaultValues.id),
        data: tempVector,
      });
      return;
    }

    createVector({
      orgId,
      projectId,
      scenarioId,
      modelId,
      data: tempVector,
    });
  };

  const isLoading =
    isCreatingVector || isUpdatingVector || isRefetchingScenarioData;

  return (
    <>
      {showTrigger && (
        <button
          onClick={handleOpenDialogCreateVector}
          className="text-start"
          type="button"
          style={trigger?.style}
        >
          {trigger ? (
            trigger.label
          ) : (
            <Button
              variant="contained"
              style={{
                height: '35px',
                color: 'white',
                padding: '2px 12px',
                display: 'flex',
                gap: '4px',
                fontSize: '16px',
                backgroundColor: '#B8341B',
                alignItems: 'center',
              }}
            >
              <Plus width={20} height={20} />
              <p>New</p>
            </Button>
          )}
        </button>
      )}
      <Dialog
        open={!!open}
        onClose={handleCloseDialogCreateVector}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className="w-full">
          <h2 className="pt-2 text-xl font-bold">{title}</h2>
        </DialogTitle>
        <DialogContent>
          <div className="z-50">
            <FormProvider {...form}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mb-2 space-y-6"
              >
                <div className="space-y-3">
                  <div className="grid grid-cols-2 gap-3">
                    <div className="col-span-2 space-y-2">
                      <FormInput name="name" label="Name" />
                      <FormInput
                        name="description"
                        label="Description"
                        multiline
                        rows={3}
                        placeholder="(Optional)"
                      />
                    </div>
                    <div className="flex gap-2 col-span-2">
                      <FormSelect
                        name="start_period"
                        label="From"
                        fullWidth
                        defaultValue={periods[0]?.value}
                        options={periods}
                      />
                      <FormSelect
                        name="end_period"
                        label="To"
                        fullWidth
                        defaultValue={periods[periods.length - 1]?.value}
                        options={periods}
                      />
                    </div>
                    {filterableFormFields &&
                      Object.entries(filterableFormFields).map(
                        ([key, value]) => (
                          <div
                            className={cn('col-span-2', {
                              'col-span-1': key === 'from' || key === 'to',
                            })}
                          >
                            <FormSelect
                              defaultValue={
                                key === 'to'
                                  ? value[value.length - 1]
                                  : transformToValue(value[0])
                              }
                              label={transformToLabel(key)}
                              name={key}
                              options={value
                                .filter((v) => v)
                                ?.map((v) => ({
                                  label: v,
                                  value: v,
                                }))}
                            />
                          </div>
                        )
                      )}
                  </div>
                  <div className="flex gap-3">
                    <FormSelect
                      name="action"
                      label="Action"
                      defaultValue="increase"
                      options={[
                        {
                          label: 'INCREASE',
                          value: 'increase',
                        },
                        {
                          label: 'DECREASE',
                          value: 'decrease',
                        },
                        {
                          label: 'SET',
                          value: 'set',
                        },
                      ]}
                    />
                  </div>
                  <div className="flex gap-3">
                    <FormSelect
                      name="type"
                      label="Type"
                      disabled={watch('action') === VECTOR_DIRECTION_SET}
                      defaultValue="percentage"
                      options={[
                        {
                          label: 'Percentage',
                          value: 'percentage',
                        },
                        {
                          label: 'Fixed amount',
                          value: 'delta',
                        },
                      ]}
                    />
                    <FormInput name="amount" label="Amount" type="number" />
                  </div>
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialogCreateVector}
                    variant="outlined"
                    disabled={isLoading}
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    Save
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateVectorFormDialog;
