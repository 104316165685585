import React from 'react';

import { flexRender, type Table } from '@tanstack/react-table';

import cn from '../utils/cn';

import Spinner from './Spinner';

type TanstackTableProps<T> = {
  table: Table<T>;
  isLoading: boolean;
  emptyText: string;
};

const TanstackTable = <T,>({
  table,
  isLoading,
  emptyText,
}: TanstackTableProps<T>) => (
  <div className="w-full">
    <table className="w-full font-normal">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
          >
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className={cn(
                  'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                )}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {table.getRowModel().rows.length > 0 ? (
        <tbody className="relative h-full">
          {isLoading && (
            <div className="absolute border-[#E4E7EC] h-full border flex w-full items-center justify-center opacity-50">
              <Spinner />
            </div>
          )}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-pointer px-6 py-3 transition">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border-b border-[#E4E7EC] px-6 text-[#333] first:border-l last:border-r"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      ) : null}
    </table>
    {!table.getRowModel().rows.length && !isLoading && (
      <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
        <p className="text-lg text-[#999] font-normal">{emptyText}</p>
      </div>
    )}
  </div>
);

export default TanstackTable;
